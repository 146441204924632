import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'

import { Basic as Layout } from '../../../layouts'

import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { TextField } from 'mui-rff'
import { ITag } from '@il-postino/types'
import FormDialog from '../../../components/FormDialog'
import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from '../components/SettingsSubdrawer'

import useOrganization from '../../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  table: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableHead: {
    backgroundColor: 'lightgray'
  },
  teamAvatars: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const [dialogOpened, setDialogOpened] = React.useState(false)
  const [editableRow, setEditableRow] = React.useState<ITag | undefined>()
  const organization = useOrganization()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.quick-replies'

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organization?.id,
      subcollections: [
        {
          collection: 'tags',
          where: [['softRemoved', '==', false]]
        }
      ],
      storeAs: 'tags',
      orderBy: ['name', 'asc']
    }
  ])

  const tags: ITag[] = useSelector((state: any) => state.firestore.ordered.tags)

  const handleEdit = useCallback(
    (id: ITag['id']) => {
      if (isLoaded(tags) && !isEmpty(tags)) {
        setEditableRow(tags?.find((t) => t.id === id))
        setDialogOpened(true)
      }
    },
    [tags, setEditableRow]
  )

  async function onSubmitNewTag(values: ITag) {
    // loader.start()

    const tagSubcollection = firestore
      .collection('organizations')
      .doc(organization?.id)
      .collection('tags')

    if (values.id) {
      tagSubcollection
        .doc(values.id)
        .update(values)
        .then(() => {
          setEditableRow(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    } else {
      setEditableRow(values)
      tagSubcollection
        .add(values)
        .then(() => {
          setEditableRow(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    }
  }

  const handleClickOpenDialog = useCallback(() => {
    setDialogOpened(true)
  }, [setDialogOpened])

  const handleCloseDialog = useCallback(() => {
    setEditableRow(undefined)
    setDialogOpened(false)
  }, [setDialogOpened])

  async function validate(values: ITag) {
    const validation: any = {}
    if (!values.name) {
      validation['name'] = 'A field name is required'
    }
    return validation
  }

  const initialValues: Partial<ITag> = useMemo(() => {
    return (
      editableRow || {
        softRemoved: false
      }
    )
  }, [editableRow])

  const formFields: any[] = [
    <TextField
      key='name'
      name='name'
      label={i18n({ id: `${i18nNs}.form.fields.name.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.name.helper-text` })}
      required={true}
    />,
    <TextField
      key='color'
      name='color'
      label={i18n({ id: `${i18nNs}.form.fields.color.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.color.helper-text` })}
      required={true}
    />
  ]

  return (
    <Layout
      subDrawer={<SettingsSubdrawer />}
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      topBarActions={
        <IconButton onClick={handleClickOpenDialog}>
          <AddIcon />
        </IconButton>
      }
    >
      <FormDialog
        open={dialogOpened}
        onClose={handleCloseDialog}
        onSubmit={onSubmitNewTag}
        formFields={formFields}
        initialValues={initialValues}
        validate={validate}
        headline={i18n({ id: `${i18nNs}.form.headline.create` })}
      />
      <Container className={classes.root}>
        <Grid container spacing={0}>
          {/* Users list */}
          <Grid item xs={12}>
            {!isLoaded(tags) && 'Loading...'}
            {isLoaded(tags) && isEmpty(tags) && 'No tags found'}
            {isLoaded(tags) &&
              !isEmpty(tags) &&
              tags.map((t, i) => {
                return (
                  <Chip
                    deleteIcon={<EditIcon />}
                    onDelete={() => handleEdit(t.id)}
                    key={i}
                    label={t.name}
                    style={{
                      backgroundColor: `#${t.color}`
                    }}
                  />
                )
              })}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
