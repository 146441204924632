import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { IPortDefaultProps } from '@lucho_1312/react-flow-chart'
import StatsCounter from '../StatsCounter'

const useStyles = makeStyles((theme) => ({
  portDefaultOuter: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover > div': {
      background: theme.palette.secondary.main
    }
  },
  portDefaultInner: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    cursor: 'pointer',
    background: 'grey'
  },
  active: {
    background: theme.palette.secondary.main
  }
}))

export default ({
  isLinkSelected,
  isLinkHovered,
  port,
  node,
  config
}: IPortDefaultProps) => {
  const classes = useStyles()

  const isActive = !config.readonly && (isLinkSelected || isLinkHovered)
  return (
    <div className={classes.portDefaultOuter}>
      {config.showStats && (
        <StatsCounter config={config} node={node} port={port} />
      )}
      <div
        className={clsx(classes.portDefaultInner, {
          [classes.active]: isActive
        })}
      />
    </div>
  )
}
