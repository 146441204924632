import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import useOrganization from '../../../../hooks/useOrganization'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { IApp } from '@il-postino/types'
import { Typography, Divider, Avatar, Button } from '@material-ui/core'
import PowerIcon from '@material-ui/icons/Power'
import verifiedBadge from './verifiedBadge.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginLeft: theme.spacing(5),
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  verifiedBadge: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(3)
  }
}))

type Props = {
  menuItem?: any
  appId: IApp['id']
  action: 'add' | 'edit'
}
export default ({ menuItem, appId, action }: Props) => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.apps'

  const firestore = useFirestore()
  const history = useHistory()
  const organization = useOrganization()
  const { auth } = useSelector((state: any) => state.firebase)

  const app: IApp = useSelector((state: any) => {
    if (appId) {
      return state.firestore.data?.apps?.[appId]
    }
  })

  useFirestoreConnect([
    {
      collection: 'apps',
      doc: appId
    }
  ])

  const onAddApp = useCallback(
    (app) => {
      if (organization && auth) {
        // firestore.collection('apps').doc('hubSpot').set(app).finally(console.log)
        firestore
          .collection('integrations')
          .add({
            name: app.name,
            organization: firestore.doc(`organizations/${organization.id}`),
            creator: firestore.doc(`users/${auth.uid}`),
            enabled: true,
            connectionStatus: 'pending',
            app: {
              key: app.key,
              name: app.name,
              shortDescription: app.shortDescription,
              description: app.description,
              organization: app.organization,
              organizationName: app.organizationName,
              avatarUrl: app.avatarUrl,
              i18n: app.i18n,
              official: app.official
            },
            params: {},
            softRemoved: false
          })
          .then((integrationRef) => {
            history.push(
              `/${organization.index}${menuItem.path}/edit/${integrationRef.id}`
            )
          })
          .catch(console.error)
          .finally(() => {})
      }
    },
    [auth, firestore, history, menuItem.path, organization]
  )

  return (
    <Grid container spacing={3} className={classes.root}>
      {!isLoaded(app) && <div>Loading...</div>}
      {isLoaded(app) && isEmpty(app) && (
        <div>{i18n({ id: `${i18nNs}.others.appNotFound` })}</div>
      )}
      {isLoaded(app) && !isEmpty(app) && (
        <React.Fragment>
          <Grid item xs={3}>
            <Avatar
              variant='square'
              className={classes.avatar}
              src={app.avatarUrl}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h5'>
              {app.name}
              <img
                className={classes.verifiedBadge}
                src={verifiedBadge}
                alt={i18n({ id: `${i18nNs}.others.verified` })}
              />
              <Button
                variant='contained'
                color='primary'
                size='small'
                className={classes.button}
                startIcon={<PowerIcon />}
                onClick={() => onAddApp(app)}
              >
                {i18n({ id: `${i18nNs}.buttons.add` }, { appName: app.name })}
              </Button>
            </Typography>

            <Typography variant='caption'>{`by ${app.organizationName}`}</Typography>
            <Typography variant='body1'>{app.shortDescription}</Typography>
            <Typography variant='body2'>{app.description}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            App page extra details (maybe examples of usage, or something to
            convince people of installing this)
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}
