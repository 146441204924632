import React from 'react'
import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'
import {
  Theme,
  makeStyles,
  withStyles,
  createStyles
} from '@material-ui/core/styles'
import FaceRoundedIcon from '@material-ui/icons/FaceRounded'

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""'
      }
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0
      }
    }
  })
)(Badge)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  })
)

const isEmailRegex = /\S+@\S+\.\S+/

export default function BadgeAvatars(props: { src?: string; status?: string }) {
  const classes = useStyles()

  // If the src is an Email (due to be an Invitation), we should show the default Avatar image by passing an Empty String to the Component
  const imageSource =
    props.src && !isEmailRegex.test(props.src) ? props.src : ''

  const { status } = props
  const shouldDisplayBadge = true
  // const shouldDisplayBadge = status && status !== 'inactive'
  return (
    <div className={classes.root}>
      <StyledBadge
        overlap='circle'
        // color='green' // Color correspond to status
        invisible={!shouldDisplayBadge} // Visibility should correspond to Status
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        variant='dot'
      >
        <Avatar alt='user' src={imageSource} />
      </StyledBadge>
    </div>
  )
}
