import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Unauthenticated as LayoutUnauthenticated } from '../../layouts'

import LoginBox from './components/LoginBox'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

const LoginPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.login'

  return (
    <LayoutUnauthenticated
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <Container maxWidth='xs' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant='h5' component='h1' align='center'>
              {i18n({ id: `${i18nNs}.title-no-auth` })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <LoginBox />
          </Grid>
        </Grid>
      </Container>
    </LayoutUnauthenticated>
  )
}

export default LoginPage
