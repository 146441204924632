import React, { useMemo, useCallback } from 'react'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import {
  createStyles,
  lighten,
  makeStyles,
  Theme
} from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'

import { ICustomField } from '@il-postino/types'

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

function getComparator(order: any, orderBy: any) {
  return order === 'desc'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface HeadCell {
  disablePadding: boolean
  id: string
  label: string
  numeric: boolean
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  numSelected: number
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  order: Order
  orderBy: string
  rowCount: number
  tableType: 'archived' | 'unarchived'
}

function EnhancedTableHead({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  tableType
}: EnhancedTableProps) {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = `pages.custom-fields`

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property)
  }

  const headCells: HeadCell[] = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: i18n({ id: `${i18nNs}.tables.headers.name.title` })
      },
      {
        id: 'key',
        numeric: false,
        disablePadding: false,
        label: i18n({ id: `${i18nNs}.tables.headers.key.title` })
      },
      {
        id: 'type',
        numeric: false,
        disablePadding: false,
        label: i18n({ id: `${i18nNs}.tables.headers.type.title` })
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: i18n({ id: `${i18nNs}.tables.headers.description.title` })
      },
      {
        id: 'archived',
        numeric: false,
        disablePadding: false,
        label: i18n({
          id: `${i18nNs}.tables.headers.${
            tableType === 'unarchived' ? 'archive' : 'unarchive'
          }.title`
        })
      }
    ]
  }, [])

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: '1 1 100%'
    }
  })
)

interface EnhancedTableToolbarProps {
  numSelected: number
  headline: string
  idsSelected: ICustomField['id'][]
  tableType: 'archived' | 'unarchived'
  onDelete?: (id: ICustomField['id'][]) => void
  onUnarchive?: (id: ICustomField['id'][]) => void
  onArchive?: (id: ICustomField['id'][]) => void
  handleUnselectAll: () => void
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles()
  const {
    numSelected,
    headline,
    idsSelected,
    tableType,
    onDelete,
    onArchive,
    onUnarchive,
    handleUnselectAll
  } = props

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          {headline}
        </Typography>
      )}
      {numSelected > 0 ? (
        <React.Fragment>
          {tableType === 'archived' && onDelete && (
            <Tooltip title={`Delete ${numSelected} custom fields`}>
              <IconButton
                aria-label='delete'
                onClick={() => {
                  handleUnselectAll()
                  onDelete(idsSelected)
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          {tableType === 'unarchived' && onArchive && (
            <Tooltip title={`Archive ${numSelected} custom fields`}>
              <IconButton
                aria-label='archive'
                onClick={() => {
                  handleUnselectAll()
                  onArchive(idsSelected)
                }}
              >
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          )}
          {tableType === 'archived' && onUnarchive && (
            <Tooltip title={`Unarchive ${numSelected} custom fields`}>
              <IconButton
                aria-label='unarchive'
                onClick={() => {
                  handleUnselectAll()
                  onUnarchive(idsSelected)
                }}
              >
                <UnarchiveIcon />
              </IconButton>
            </Tooltip>
          )}
        </React.Fragment>
      ) : // <Tooltip title='Filter list'>
      //   <IconButton aria-label='filter list'>
      //     <FilterListIcon />
      //   </IconButton>
      // </Tooltip>
      null}
    </Toolbar>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1
    }
  })
)

type Props = {
  headline: string
  rows: ICustomField[]
  tableType: 'archived' | 'unarchived'
  onEdit?: (id: ICustomField['id']) => void
  onDelete?: (id: ICustomField['id'][]) => void
  onUnarchive?: (id: ICustomField['id'][]) => void
  onArchive?: (id: ICustomField['id'][]) => void
}
export default ({
  rows,
  headline,
  tableType,
  onEdit,
  onDelete,
  onUnarchive,
  onArchive
}: Props) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('name')
  const [selected, setSelected] = React.useState<string[]>([])
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }

    setSelected(newSelected)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          headline={headline}
          idsSelected={selected}
          onDelete={onDelete}
          onArchive={onArchive}
          onUnarchive={onUnarchive}
          handleUnselectAll={() => {
            setSelected([])
          }}
          tableType={tableType}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'medium'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              tableType={tableType}
            />
            <TableBody>
              {stableSort<ICustomField>(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: ICustomField, index) => {
                  const isItemSelected = isSelected(row.id)
                  const labelId = `enhanced-table-checkbox-${index}`

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {/* <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        padding='none'
                      >
                        {row.id}
                      </TableCell> */}
                      <TableCell align='left'>{row.name}</TableCell>
                      <TableCell align='center'>{row.key}</TableCell>
                      <TableCell align='center'>{row.type}</TableCell>
                      <TableCell align='left'>{row.description}</TableCell>
                      <TableCell align='right'>
                        <IconButton onClick={() => onEdit?.(row.id)}>
                          <EditIcon />
                        </IconButton>
                        {row.archived && (
                          <IconButton onClick={() => onDelete?.([row.id])}>
                            <DeleteIcon />
                          </IconButton>
                        )}
                        {row.archived ? (
                          <IconButton onClick={() => onUnarchive?.([row.id])}>
                            <UnarchiveIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => onArchive?.([row.id])}>
                            <ArchiveIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  )
}
