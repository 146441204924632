import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { Unauthenticated as LayoutUnauthenticated } from '../layouts'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

const NotFoundPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.404'

  return (
    <LayoutUnauthenticated
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <div className={classes.container}>
        <div>
          <pre>{i18n({ id: `${i18nNs}.content` })}</pre>
        </div>
      </div>
    </LayoutUnauthenticated>
  )
}

export default NotFoundPage
