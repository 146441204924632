import React, { memo, useCallback } from 'react'
import { useIntl } from 'react-intl'

import Input from '@material-ui/core/Input'
import { makeStyles } from '@material-ui/core/styles'

import SendRoundedIcon from '@material-ui/icons/SendRounded'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    backgroundColor: theme.palette.common.white
  },
  inputRoot: {
    marginLeft: theme.spacing(3)
  },
  inputUnderline: {
    borderBottom: 'none',
    '&:before': {
      borderBottom: 'none'
    }
  },
  sendIcon: {
    color: theme.palette.primary.main
  }
}))

type IProps = {
  disabled: boolean
  value: string
  onChange(value: string): void
  onSend(): void
}

function ConversationInput({ disabled, value, onChange, onSend }: IProps) {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()

  const handleChange = useCallback((event) => onChange(event.target.value), [
    onChange
  ])

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        onSend()
      }
    },
    [onSend]
  )

  return (
    <div className={classes.root}>
      <Input
        // margin={'dense'}
        autoFocus
        fullWidth
        // disableUnderline
        classes={{ root: classes.inputRoot, underline: classes.inputUnderline }}
        placeholder={i18n({ id: 'components.conversation.placeholder' })}
        multiline
        rowsMax={2}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <IconButton onClick={onSend}>
        <SendRoundedIcon classes={{ root: classes.sendIcon }} />
      </IconButton>
    </div>
  )
}

export default memo(ConversationInput)
