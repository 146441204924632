import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { IPortDefaultProps } from '@lucho_1312/react-flow-chart'
import StatsCounter from '../StatsCounter'

const useStyles = makeStyles((theme) => ({
  portDefaultOuter: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover > div': {
      borderColor: theme.palette.secondary.main
    }
  },
  portDefaultInner: {
    width: '10px',
    height: '10px',
    borderWidth: '0 3px 3px 0',
    borderStyle: 'solid',
    borderColor: 'grey',
    cursor: 'pointer'
  },
  right: {
    transform: 'rotate(-45deg)',
    marginRight: '-5px'
  },
  left: {
    transform: 'rotate(135deg)',
    marginRight: '5px'
  },
  top: {
    transform: 'rotate(-135deg)'
  },
  bottom: {
    transform: 'rotate(45deg)'
  },
  active: {
    borderColor: theme.palette.secondary.main
  }
}))

export default ({
  isLinkSelected,
  isLinkHovered,
  port,
  node,
  config
}: IPortDefaultProps) => {
  const classes = useStyles()

  const isActive = !config.readonly && (isLinkSelected || isLinkHovered)
  return (
    <div className={classes.portDefaultOuter}>
      {config.showStats && (
        <StatsCounter config={config} node={node} port={port} />
      )}
      <div
        className={clsx(classes.portDefaultInner, classes[port.type], {
          [classes.active]: isActive
        })}
      />
    </div>
  )
}
