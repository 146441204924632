import React, { ReactChildren, ReactChild } from 'react'

import Div100vh from 'react-div-100vh'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  }
}))

function MainWrapper({
  children
}: {
  children: ReactChildren | (ReactChild | null)[] | null
}) {
  const classes = useStyles()

  return <Div100vh className={classes.root}>{children}</Div100vh>
}

export default MainWrapper
