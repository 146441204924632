import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { IChannel } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}))

type Props = {
  channel: IChannel
  onEditChannel: any
  onReconnect: (channelId: IChannel['id']) => void
}
const ConnectionCard = ({ channel, onEditChannel, onReconnect }: Props) => {
  const classes = useStyles()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.channels'

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          onEditChannel(channel.id)
        }}
      >
        <CardHeader
          title={channel.key}
          subheader={`Connection status: ${channel.connectionStatus}`}
        />
        <CardMedia
          component='img'
          alt={channel.name}
          // height='160'
          image={channel.avatarUrl || `/channels/${channel.key}.png`}
          title={channel.name}
          style={{ maxWidth: 100, maxHeight: 100, margin: '0 auto' }}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {channel.name}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            @{channel.username}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size='medium' color='primary'>
            {i18n({ id: `${i18nNs}.buttons.settings` })}
          </Button>
          {channel.connectionStatus !== 'connected' && (
            <Button
              size='medium'
              color='primary'
              disabled={['pending', 'reconnecting'].includes(
                channel.connectionStatus
              )}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                onReconnect(channel.id)
              }}
            >
              {i18n({ id: `${i18nNs}.buttons.reconnect` })}
            </Button>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

export default ConnectionCard
