import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import { useParams, useHistory } from 'react-router-dom'

import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import FlowChart from './components/FlowChart'
import BuilderItemsSideBar from './components/BuilderItemsSideBar'
import NodeSettingsSideBar from './components/NodeSettingsSideBar'
import Toolbar from './components/ToolBar'
import _cloneDeep from 'lodash-es/cloneDeep'

import MainWrapper from '../../layouts/MainWrapper'
import MainMenu from '../../layouts/components/MainMenu'

import useOrganization from '../../hooks/useOrganization'

import { IAutomationFlow, TNode } from '@il-postino/types'
import { IState } from '../../types/state'

const useStyles = makeStyles((theme) => ({
  canvas: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden'
  },
  saveButton: {
    position: 'fixed',
    top: `${theme.spacing(2)}px`,
    right: `${theme.spacing(10)}px`
  },
  speedDial: {
    position: 'fixed',
    top: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`
  },
  speedDialAction: {
    position: 'fixed',
    top: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`,
    margin: 0,
    transition: ''.concat(
      theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter
      }),
      ', opacity 0.8s'
    ),
    opacity: 1
  },
  speedDialActionClosed: {
    opacity: 0,
    transform: 'scale(0)'
  },
  floatingButton: {
    position: 'fixed',
    top: `${theme.spacing(2)}px`,
    right: `${theme.spacing(2)}px`
  }
}))

type Params = {
  automationFlowId?: string
}

interface IAutomationFlowTemplate extends Omit<IAutomationFlow, 'id'> {
  id?: string
}

const defaultAutomationFlow: IAutomationFlowTemplate = {
  name: 'New automation flow #1',
  flow: {
    offset: { x: -200, y: -1000 },
    scale: 1,
    nodes: {},
    links: {},
    selected: {},
    hovered: {}
  }
}

const i18nNs = 'pages.dashboard'

const AutomationFlowsPage = () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const organization = useOrganization()

  const { formatMessage: i18n } = useIntl()
  const { automationFlowId }: Params = useParams()
  const history = useHistory()

  const [
    automationFlow,
    setAutomationFlow
  ] = useState<IAutomationFlowTemplate | null>(null)
  const [showStats, setShowStats] = useState(false)

  const [openSidebar, setOpenBuilderItemsSideBar] = React.useState(false)

  const { auth } = useSelector((state: IState) => state.firebase)

  const queries = useMemo(() => {
    const queries: any = []
    if (organization) {
      queries.push({
        collection: 'automationFlows',
        where: [
          [
            'organization',
            '==',
            firestore.doc(`organizations/${organization.id}`)
          ]
        ],
        orderBy: ['name', 'asc']
      })

      if (automationFlowId && showStats) {
        queries.push({
          collection: 'automationFlows',
          doc: automationFlowId,
          subcollections: [
            {
              collection: 'stats'
            }
          ],
          storeAs: `automationFlow_${automationFlowId}_stats`
        })
      }
    }
    return queries
  }, [automationFlowId, firestore, organization, showStats])

  useFirestoreConnect(queries)

  const automationFlows = useSelector(
    (state: IState) => state.firestore.data.automationFlows
  )

  const automationFlowStats = useSelector((state: any) => {
    if (automationFlowId) {
      return state.firestore.data?.[`automationFlow_${automationFlowId}_stats`]
    }
  })

  const orderedAutomationFlows = useSelector(
    (state: IState) => state.firestore.ordered.automationFlows
  )

  useEffect(() => {
    if (automationFlowId && automationFlows) {
      setAutomationFlow({
        ..._cloneDeep(automationFlows[automationFlowId]),
        id: automationFlowId
      })
    } else {
      setAutomationFlow(_cloneDeep(defaultAutomationFlow))
    }
  }, [automationFlowId, automationFlows])

  const handleChangeAutomationFlowId = useCallback(
    (newAutomationFlowId: string) => {
      if (organization) {
        setShowStats(false)
        history.push(
          `/org/${organization.id}/automation-flows/${newAutomationFlowId}`
        )
      }
    },
    [history, organization]
  )

  const handleEditNode = (newNode: TNode) => {
    if (automationFlow && newNode) {
      const newAutomationFlow = automationFlow
      newAutomationFlow.flow.nodes[newNode.id] = newNode
      setAutomationFlow({ ...newAutomationFlow })
    }
  }

  const handleCloseBuilderItemsSideBar = () => {
    setOpenBuilderItemsSideBar(false)
  }

  const handleOpenBuilderItemsSideBar = () => {
    setOpenBuilderItemsSideBar(true)
  }

  const handleToggleStats = useCallback(() => {
    setShowStats(!showStats)
  }, [showStats])

  const handleSave = useCallback(() => {
    if (organization) {
      if (automationFlow) {
        console.log('SAVE automationFlow.id', automationFlow)
        if (automationFlow.id) {
          firestore
            .collection('automationFlows')
            .doc(automationFlow.id)
            .update({
              name: automationFlow.name,
              enabled: true,
              flow: automationFlow.flow
            })
            .catch(console.error)
        } else {
          firestore
            .collection('automationFlows')
            .add({
              organization: firestore.doc(`organizations/${organization.id}`),
              creator: firestore.doc(`users/${auth.uid}`),
              createdAt: (firestore as any).FieldValue.serverTimestamp(),
              name: automationFlow.name,
              enabled: true,
              flow: automationFlow.flow
            })
            .then((data: any) => {
              handleChangeAutomationFlowId(data.id)
            })
            .catch(console.error)
        }
      }
    }
  }, [
    auth.uid,
    automationFlow,
    firestore,
    handleChangeAutomationFlowId,
    organization
  ])

  const selectedNode = useMemo(() => {
    if (automationFlow?.flow?.selected?.type === 'node') {
      return automationFlow.flow.nodes[automationFlow?.flow.selected.id]
    }
  }, [automationFlow])

  return (
    <MainWrapper>
      <MainMenu />
      {!automationFlow ? (
        <p>Loading...</p>
      ) : (
        <div className={classes.canvas}>
          <FlowChart
            onCloseBuilderItemsSideBar={handleCloseBuilderItemsSideBar}
            onChangeAutomationFlow={setAutomationFlow}
            // onNodeClick={setSelectedNodeId}
            automationFlow={automationFlow}
            config={{
              showStats: showStats,
              stats:
                isLoaded(automationFlowStats) && !isEmpty(automationFlowStats)
                  ? automationFlowStats
                  : {}
            }}
          />
          {isLoaded(orderedAutomationFlows) ? (
            <Toolbar
              automationFlowId={automationFlowId}
              orderedAutomationFlows={orderedAutomationFlows}
              onSave={handleSave}
              onToggleStats={handleToggleStats}
              onSelectAutomationFlowId={handleChangeAutomationFlowId}
            />
          ) : null}
          {selectedNode ? (
            <NodeSettingsSideBar
              onEditNode={handleEditNode}
              node={selectedNode}
            />
          ) : null}
          <Fab
            color='primary'
            aria-label='add'
            className={classes.floatingButton}
            onClick={handleOpenBuilderItemsSideBar}
          >
            <AddIcon />
          </Fab>
          <BuilderItemsSideBar
            open={!!openSidebar}
            onClose={handleCloseBuilderItemsSideBar}
          />
        </div>
      )}
    </MainWrapper>
  )
}

export default AutomationFlowsPage
