import React, { useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TNode } from '@il-postino/types'
import useTeams from 'src/hooks/useTeams'
import useChannels from 'src/hooks/useChannels'
import { useFirestore } from 'react-redux-firebase'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { set as _set } from 'lodash'

import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))
export default ({
  node,
  onEditNode
}: {
  node: TNode<'trigger.conversation:incomingMessage'>
  onEditNode: (newNode: TNode<'trigger.conversation:incomingMessage'>) => void
}) => {
  const classes = useStyles()
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>(
    node.properties.data?.teams?.map((a) => a.id) || []
  )
  const [selectedChannel, setSelectedChannel] = React.useState<string>(
    node.properties.data?.channel?.id || ''
  )
  const firestore = useFirestore()
  const teams = useTeams()
  const { channels } = useChannels()

  const handleChange = useCallback(
    (e: any) => {
      const newNode = _set(
        { ...node },
        `properties.data.${e.target.name}`,
        e.target.name === 'channel'
          ? firestore.doc(`channels/${e.target.value}`)
          : e.target.value.map((teamId: string) => {
              return firestore.doc(`teams/${teamId}`)
            })
      )
      onEditNode(newNode)
    },
    [node, onEditNode, firestore]
  )
  const handleChangeTeams = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTeams(event.target.value as string[])
    handleChange(event)
  }

  const handleChangeChannel = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedChannel(event.target.value as string)
    handleChange(event)
  }

  return (
    <div className={clsx(classes.root)}>
      <FormControl className={classes.formControl}>
        <InputLabel id='demo-mutiple-chip-label'>Teams</InputLabel>
        <Select
          name='teams'
          multiple
          value={selectedTeams}
          onChange={handleChangeTeams}
          input={<Input id={`teams-${node.id}`} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={teams && teams.find((a) => a.id === value)?.name}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
              }
            }
          }}
        >
          {teams.map((a) => (
            <MenuItem key={a.id} value={a.id}>
              {a.name} {a.id}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id={node.id}>Channel</InputLabel>
        <Select
          labelId={`channel-${node.id}`}
          className={classes.selectEmpty}
          name='channel'
          value={selectedChannel}
          onChange={handleChangeChannel}
        >
          {channels.map((c) => {
            return (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
