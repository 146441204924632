import React from 'react'

import { INode, REACT_FLOW_CHART } from '@lucho_1312/react-flow-chart'

export interface IDraggableItemProps {
  children: any
  nodeParams: {
    type: string
    ports: INode['ports']
    properties?: any
  }
}

export const DraggableItem = ({
  nodeParams,
  children,
  ...props
}: IDraggableItemProps) => {
  return (
    <div
      {...props}
      draggable={true}
      onDragStart={(event) => {
        event.dataTransfer.setData(REACT_FLOW_CHART, JSON.stringify(nodeParams))
      }}
    >
      {children}
    </div>
  )
}
