import React from 'react'
import { useIntl } from 'react-intl'
import CustomFieldsTable from './CustomFieldsTable'

import { ICustomField, TCustomFieldEntity } from '@il-postino/types'

type Props = {
  rows: ICustomField[]
  entity: TCustomFieldEntity
  onUnarchive: (id: ICustomField['id'][]) => void
  onEdit: (id: ICustomField['id']) => void
  onDelete: (id: ICustomField['id'][]) => void
}
export default ({ entity, rows, onUnarchive, onEdit, onDelete }: Props) => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = `pages.custom-fields`

  return (
    <CustomFieldsTable
      tableType='archived'
      headline={i18n({ id: `${i18nNs}.tables.headline.${entity}sArchived` })}
      rows={rows}
      onEdit={onEdit}
      onDelete={onDelete}
      onUnarchive={onUnarchive}
    />
  )
}
