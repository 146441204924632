import React from 'react'

import { FormattedMessage } from 'react-intl'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    fontSize: 10
  },
  red: {
    color: 'red'
  }
}))

type IProps = {
  message?: IMessage
}

function Retry({ message }: IProps) {
  const classes = useStyles()

  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <FormattedMessage
        id={'components.conversation.message.failed'}
        values={{
          retry: (
            <span className={classes.red}>
              <FormattedMessage id={'components.conversation.message.retry'} />
            </span>
          )
        }}
      />
    </div>
  )
}

export default Retry
