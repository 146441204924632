import React from 'react'

import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'

import Public from './Public'
import Private from './Private'

import { IState } from '../types'

function Router() {
  const { auth, profile } = useSelector((state: IState) => state.firebase)

  if (!isLoaded(auth)) {
    return null
  } else if (isEmpty(auth)) {
    return <Public />
  } else if (isLoaded(profile) && !isEmpty(profile)) {
    return <Private />
  } else {
    return null
  }
}

export default Router
