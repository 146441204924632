import React, { useCallback, useMemo } from 'react'
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css'
import { TNode } from '@il-postino/types'
import Input from '../../Input'

const useStyles = makeStyles((theme) => ({
  root: {},
  editor: {
    cursor: 'text',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#DFE1E5',
    margin: '0 auto',
    textAlign: 'left',
    width: '95%',
    minHeight: 300,
    maxHeight: 500,
    // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)',
    borderRadius: theme.spacing(0.2),
    overflowY: 'scroll'
  }
}))

export default ({
  node,
  onEditNode
}: {
  node: TNode<'note'>
  onEditNode: (newNode: TNode<'note'>) => void
}) => {
  const classes = useStyles()

  const initialValue = useMemo(() => {
    return (
      node.properties.data?.content &&
      convertFromRaw(node.properties.data?.content)
    )
  }, [node])

  const handleChange = useCallback(
    (contentState: ContentState) => {
      node.properties.data = { content: convertToRaw(contentState) }
      onEditNode({ ...node })
    },
    [node, onEditNode]
  )

  return (
    <div className={clsx(classes.root)}>
      <Input initialValue={initialValue} onChange={handleChange} />

      <Typography variant='subtitle2' color='textSecondary'>
        {JSON.stringify(node?.properties, null, 2)}
      </Typography>
    </div>
  )
}
