import React from 'react'
import clsx from 'clsx'

import Badge from '@material-ui/core/Badge'
import Avatar from '@material-ui/core/Avatar'

import TelegramIcon from '@material-ui/icons/Telegram'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import FacebookIcon from '@material-ui/icons/Facebook'
// import { Messenger as MessengerIcon } from '../Icons'

import { SvgIconComponent } from '@material-ui/icons'

import {
  Theme,
  withStyles,
  makeStyles,
  createStyles
} from '@material-ui/core/styles'

import { TChannelKey, IContact } from '@il-postino/types'

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`
    }
  })
)(Avatar)

const useStyles = makeStyles((theme) => ({
  telegram: {
    backgroundColor: '#0088cc'
  },
  whatsApp: {
    backgroundColor: '#25d366'
  },
  messenger: {
    backgroundColor: '#3b5998'
  },
  icon: {
    fontSize: 12,
    color: theme.palette.common.white
  },
  iconContainer: {
    borderRadius: '50%',
    width: 14,
    height: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const channelDataIconMap: { [key: string]: SvgIconComponent } = {
  telegram: TelegramIcon,
  whatsApp: WhatsAppIcon,
  messenger: FacebookIcon
}

interface IProps {
  contact: IContact
  channelName?: string
  channelUrl?: string
  channelDataKey?: TChannelKey
  className?: string
}

export default function AvatarWithChannel({
  contact,
  channelUrl,
  channelName,
  channelDataKey,
  className
}: IProps) {
  const classes = useStyles()

  let badgeContent

  const fullName = `${contact?.firstName || ''} ${contact?.lastName || ''}`

  const avatarUrl =
    contact?.avatarUrl ||
    'https://www.filo.news/__export/1550255450223/sites/claro/img/2019/02/15/image_1_crop1550255422627.jpg_1956802537.jpg'

  if (channelUrl) {
    badgeContent = <SmallAvatar alt={channelName} src={channelUrl} />
  } else if (channelDataKey) {
    const ChannelIcon = channelDataIconMap[channelDataKey]
    badgeContent = (
      <div className={clsx(classes.iconContainer, classes[channelDataKey])}>
        <ChannelIcon classes={{ root: classes.icon }} />
      </div>
    )
  }

  return (
    <Badge
      className={className}
      overlap='circle'
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      badgeContent={badgeContent}
    >
      <Avatar alt={fullName} src={avatarUrl} />
    </Badge>
  )
}
