import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { IApp } from '@il-postino/types'
import verifiedBadge from './verifiedBadge.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  verifiedBadge: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

type Props = {
  app: IApp
  onSelectApp: (app: IApp) => void
}
const ConnectionCard = ({ app, onSelectApp }: Props) => {
  const classes = useStyles()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.apps'

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardHeader
          title={
            <Typography variant='h5'>
              {app.name}
              <img
                className={classes.verifiedBadge}
                src={verifiedBadge}
                alt={i18n({ id: `${i18nNs}.others.verified` })}
              />
            </Typography>
          }
          subheader={`by ${app.organizationName}`}
        />
        <CardMedia
          component='img'
          alt={app.name}
          image={app.avatarUrl || `/apps/${app.key}.png`}
          title={app.name}
          style={{ maxWidth: 175, maxHeight: 175, margin: '0 auto' }}
        />
        <CardContent>
          <Typography gutterBottom variant='body1' component='p'>
            {app.shortDescription}
          </Typography>
          <Typography gutterBottom variant='caption' component='p'>
            {app.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size='medium'
            color='primary'
            onClick={() => onSelectApp(app)}
          >
            {i18n({ id: `${i18nNs}.buttons.viewMore` })}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

export default ConnectionCard
