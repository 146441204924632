import { useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'

import { IState } from '../types'
import { IOrganization } from '@il-postino/types'

export default (): IOrganization | null => {
  const { profile } = useSelector((state: IState) => state.firebase)

  const { organizationId } = useParams()

  return profile?.organizations?.[organizationId]
    ? (profile?.organizations?.[organizationId] as IOrganization)
    : null
}
