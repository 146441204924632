import React, { useMemo } from 'react'

import { IPortDefaultProps } from '@lucho_1312/react-flow-chart'

import InputPort from './Ports/Input'
import OutputPort from './Ports/Output'
import CrossPort from './Ports/Cross'
import TickPort from './Ports/Tick'
import GenericPort from './Ports/Generic'

export default (props: IPortDefaultProps) => {
  const NodeComponent = useMemo(() => {
    switch (props.port.properties?.key) {
      case 'input':
        return <InputPort {...props} />
      case 'output':
        return <OutputPort {...props} />
      case 'tick':
        return <TickPort {...props} />
      case 'cross':
        return <CrossPort {...props} />
      default:
        return <GenericPort {...props} />
    }
  }, [props])

  return NodeComponent
}
