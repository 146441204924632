import React, { ReactNode, Fragment } from 'react'

import { FormattedTime } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage } from '@il-postino/types'

import Wings from './Wings'

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: theme.palette.common.white
  },
  time: {
    display: 'flex',
    position: 'relative',
    float: 'right',
    zIndex: 10,
    margin: `-20px ${theme.spacing(1)}px -5px ${theme.spacing(0.5)}px`,
    fontSize: 12,
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

type IProps = {
  message: IMessage
  children: ReactNode
}

function BubbleMessage({ message, children }: IProps) {
  const classes = useStyles()

  const { sentAt } = message as any

  return (
    <div className={classes.root}>
      {children}
      <div className={classes.time}>
        {sentAt ? (
          <Fragment>
            <FormattedTime value={sentAt.toDate()} />
            &nbsp;
            <Wings message={message} />
          </Fragment>
        ) : null}
      </div>
    </div>
  )
}

export default BubbleMessage
