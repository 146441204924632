import React, { useMemo } from 'react'

import { INodeInnerDefaultProps } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'
import Filter from './Nodes/Filter'
import SplitAb from './Nodes/SplitAb'
import NewConversation from './Nodes/NewConversation'
import NewConversationMessage from './Nodes/NewConversationMessage'
import SendMessage from './Nodes/SendMessage'
import AssignConversation from './Nodes/AssignConversation'
import Note from './Nodes/Note'
import WebHook from './Nodes/WebHook'

interface CustomNodeProps extends INodeInnerDefaultProps {
  onNodeClick: (nodeId: string) => void
  children: any
}

export default ({ node, config, onNodeClick }: CustomNodeProps) => {
  const NodeComponent = useMemo(() => {
    switch (node.properties.key) {
      case 'action.conversation:assign':
        return (
          <AssignConversation
            node={node as TNode<'action.conversation:assign'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'action.conversation:sendMessage':
        return (
          <SendMessage
            node={node as TNode<'action.conversation:sendMessage'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'action.webHook:post':
        return (
          <WebHook
            node={node as TNode<'action.webHook:post'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'condition.filter':
        return (
          <Filter
            node={node as TNode<'condition.filter'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'condition.splitAb':
        return (
          <SplitAb
            node={node as TNode<'condition.splitAb'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'note':
        return (
          <Note
            node={node as TNode<'note'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'trigger.conversation:created':
        return (
          <NewConversation
            node={node as TNode<'trigger.conversation:created'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      case 'trigger.conversation:incomingMessage':
        return (
          <NewConversationMessage
            node={node as TNode<'trigger.conversation:incomingMessage'>}
            config={config}
            onNodeClick={onNodeClick}
          />
        )
      default:
        return <div>FALTA NODO PARA TIPO {node.type}</div>
    }
  }, [node, config, onNodeClick])

  return NodeComponent
}
