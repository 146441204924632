import * as React from 'react'
import { INodeDefaultProps } from '@lucho_1312/react-flow-chart'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    transition: '0.3s ease box-shadow, 0.3s ease margin-top'
    // minWidth: '200px'
  },
  isSelected: {
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.1)',
    marginTop: '-3px'
  }
})

export default React.forwardRef(
  ({ isSelected, ...props }: INodeDefaultProps, ref: React.Ref<any>) => {
    const classes = useStyles()

    return (
      <div
        {...props}
        ref={ref}
        className={clsx(classes.root, { [classes.isSelected]: isSelected })}
      />
    )
  }
)
