import React, { ElementType } from 'react'

import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import Badge from '@material-ui/core/Badge'

import Link from '../Link'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

type IProps = {
  Icon?: ElementType
  active?: boolean
  notificationCounter?: number
  to?: string
  exact?: boolean
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void
}

const useStyles = makeStyles((theme) => {
  console.log('Theme', theme)
  return {
    root: {
      display: 'flex',
      justifyContent: 'center',
      height: 46,
      width: 56
    },
    button: {
      width: 36,
      height: 36,
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        '& .MuiSvgIcon-root': {
          color: theme.palette.primary.main
        }
      }
    },
    buttonActive: {
      borderRadius: '50%',
      backgroundColor: theme.palette.common.white
    },
    icon: {
      color: theme.palette.common.white
    },
    iconActive: {
      color: theme.palette.primary.main
    }
  }
})

export default function MenuItem({
  active,
  notificationCounter = 0,
  Icon = DeleteIcon,
  to,
  exact,
  onClick
}: IProps) {
  const classes = useStyles()

  if (onClick) {
    return (
      <div className={classes.root}>
        <IconButton
          className={clsx({
            [classes.button]: true,
            [classes.buttonActive]: active
          })}
          size='medium'
          onClick={onClick}
        >
          <Badge badgeContent={notificationCounter} color='secondary'>
            <Icon
              classes={{
                root: clsx({
                  [classes.icon]: true,
                  [classes.iconActive]: active
                })
              }}
            />
          </Badge>
        </IconButton>
      </div>
    )
  }

  return (
    <Link to={to} exact={exact} className={classes.root}>
      <IconButton
        className={clsx({
          [classes.button]: true,
          [classes.buttonActive]: active
        })}
        size='medium'
      >
        <Badge badgeContent={notificationCounter} color='secondary'>
          <Icon
            classes={{
              root: clsx({
                [classes.icon]: true,
                [classes.iconActive]: active
              })
            }}
          />
        </Badge>
      </IconButton>
    </Link>
  )
}
