import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { INode, IPort, IConfig } from '@lucho_1312/react-flow-chart'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

type Props = {
  port: IPort
  node: INode
  config: IConfig
}
export default ({ port, node, config }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Chip
        icon={<EqualizerIcon />}
        label={
          config?.stats?.[`node_${node.id}_port_${port.id}`]?.counter || '-'
        }
        color='secondary'
      />
    </div>
  )
}
