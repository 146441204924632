import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { useFirestore, isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { TextField } from 'formik-material-ui'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  container: {
    display: 'Flex',
    justifyContent: 'center'
  },
  actions: {
    float: 'right'
  }
}))

const CreateOrganizationForm = ({ auth, handleCreatedOrganization }) => {
  const classes = useStyles()
  const firestore = useFirestore()
  const [newOrganizationId, setNewOrganizationId] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [errorCreating, setErrorCreating] = useState(null)

  const { profile } = useSelector((state) => state.firebase)

  useEffect(() => {
    if (isLoaded(profile)) {
      if (profile.organizations?.[newOrganizationId]) {
        setIsCreating(false)
        handleCreatedOrganization(
          newOrganizationId,
          Object.keys(profile.organizations).indexOf(newOrganizationId)
        )
      }
    }
  }, [handleCreatedOrganization, newOrganizationId, profile])

  return (
    <div className={classes.container}>
      {isCreating ? (
        'Creating account...'
      ) : (
        <Formik
          initialValues={{ name: '' }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required')
          })}
          onSubmit={(values, { setSubmitting }) => {
            setIsCreating(true)
            return firestore
              .collection('organizations')
              .add({
                name: values.name,
                status: 'enabled',
                softRemoved: false,
                creator: firestore.doc(`users/${auth.uid}`)
              })
              .then((data) => {
                setSubmitting(false) // TODO: This should be set to false once the "useEffect" to call "handleCreatedOrganization" is triggered, not now
                setNewOrganizationId(data.id)
              })
              .catch((error) => {
                setSubmitting(false) // TODO: This should be set to false once the "useEffect" to call "handleCreatedOrganization" is triggered, not now
                setErrorCreating(error)
              })
          }}
        >
          {({ errors, status, touched, submitForm, isSubmitting }) => (
            <Form>
              <Card className={classes.card}>
                <CardContent>
                  <Field
                    component={TextField}
                    name='name'
                    type='name'
                    label='Organization name'
                  />
                </CardContent>
                <CardActions className={classes.actions}>
                  <Button
                    color='primary'
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    Create
                  </Button>
                  {errorCreating ? (
                    <pre>JSON.stringify(errorCreating, null, 2)</pre>
                  ) : null}
                </CardActions>
              </Card>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default CreateOrganizationForm
