import React from 'react'
import startCase from 'lodash-es/startCase'

// TODO: This should be improved

const styles = {
  root: {
    fontWeight: 700
  },
  active: {
    color: 'green'
  },
  inactive: {
    color: 'red'
  },
  away: {
    color: 'yellow'
  },
  pending: {
    color: 'grey'
  }
}

const getColorLabel = (status: string) => {
  switch (status) {
    case 'active':
      return { color: 'green' }

    case 'inactive':
      return { color: 'red' }

    case 'away':
      return { color: 'yellow' }

    case 'pending':
    default:
      return { color: 'grey' }
  }
}

export default function StyledLabel(props: { status: string }) {
  return (
    <span style={{ ...styles.root, ...getColorLabel(props.status) }}>
      {startCase(props.status)}
    </span>
  )
}
