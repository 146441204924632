import React from 'react'

import MainMenu from './components/MainMenu'
import MainWrapper from './MainWrapper'

type Props = {
  children: any
}

export const Conversational = ({ children }: Props) => {
  return (
    <MainWrapper key={'mainLayout'}>
      <MainMenu />
      {children}
    </MainWrapper>
  )
}
