import React from 'react'
import getTheme from './styles/getTheme'
import IntlProvider from './intl/IntlProvider'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import DarkModeProvider from './providers/DarkModeProvider'
import ReactReduxFirebaseProvider from './providers/ReactReduxFirebaseProvider'

import Routes from './Routes'

const darkModeConfig = {
  useLocalStorage: true
}

const App = () => {
  return (
    <ReactReduxFirebaseProvider>
      <CssBaseline />
      <Router>
        <DarkModeProvider config={darkModeConfig}>
          {([colorScheme]) => (
            <MuiThemeProvider theme={getTheme(colorScheme)}>
              <IntlProvider>
                <Routes />
              </IntlProvider>
            </MuiThemeProvider>
          )}
        </DarkModeProvider>
      </Router>
    </ReactReduxFirebaseProvider>
  )
}

export default App
