import React, { useMemo, memo } from 'react'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'

import { useFirestoreConnect, useFirestore } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import useTeams from '../../hooks/useTeams'
import { IState } from '../../types'
import { ITeam } from '@il-postino/types'
import { IOrganization } from 'types/src/organization'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 40,
    width: '100%'
  },
  selectRoot: {
    color: theme.palette.primary.main,
    fontSize: 18,
    lineHeight: '22px',
    paddingTop: 9,
    paddingBottom: 9,
    paddingLeft: 16,
    paddingRight: `40px !important`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2)
  }
}))

interface IProps {
  teamId?: ITeam['id']
  onChangeTeamId(teamId: string): void
}

const i18nNs = 'components.teams-selector'

function ConversationsListTeamSelect({ teamId = '', onChangeTeamId }: IProps) {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const teams = useTeams()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeTeamId(event.target.value as string)
  }

  return (
    <FormControl className={classes.root}>
      <Select
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        disableUnderline
        displayEmpty
        classes={{ root: classes.selectRoot, icon: classes.icon }}
        IconComponent={ArrowDropDownRoundedIcon}
        value={teamId}
        onChange={handleChange}
      >
        <MenuItem value=''>{i18n({ id: `${i18nNs}.all` })}</MenuItem>
        {teams.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default memo(ConversationsListTeamSelect)
