import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import RegularNodeContainer from '../Containers/Regular'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import Typography from '@material-ui/core/Typography'
import TextBubble from 'src/components/_ConversationBox/MessageTypes/Text'
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300
    // background: 'yellow'
  },
  textBubble: {
    maxWidth: '100%',
    marginRight: theme.spacing(0)
  }
}))
export default ({ node, config, ...props }: any) => {
  const classes = useStyles()

  return (
    <RegularNodeContainer {...props} node={node} config={config}>
      <div className={clsx(classes.root)}>
        <Typography variant='subtitle2' color='textSecondary' component='p'>
          Type: <b>Text message</b>
        </Typography>
        <TextBubble
          key={0}
          className={classes.textBubble}
          message={{
            sender: { type: 'bot' },
            content: {
              text: (
                <Editor
                  editorState={
                    node.properties.data?.content
                      ? EditorState.createWithContent(
                          convertFromRaw(node.properties.data?.content)
                        )
                      : EditorState.createEmpty()
                  }
                  onChange={() => {}}
                  readOnly
                />
              )
            }
          }}
          seenByContactAt={null}
        />
      </div>
    </RegularNodeContainer>
  )
}
