import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import MainMenu from './MainMenu'

const useStyles = makeStyles((theme) => ({
  sidebar: {
    display: 'flex',
    flexDirection: 'row'
  },
  subDrawer: {
    width: 280,
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  }
}))

type Props = {
  subDrawer: ReactNode
}

export default function MainSideBar(props: Props) {
  const { subDrawer } = props
  const classes = useStyles()

  return (
    <div className={classes.sidebar}>
      <MainMenu />
      {/* Sub Drawer - Only shown when "subDrawer" has a component inside */}
      {subDrawer && (
        <div
          className={classes.subDrawer}
          // variant='permanent'
          // anchor='left'
          // open={true}
          // classes={{
          //   paper: classes.drawerPaper,
          // }}
        >
          {subDrawer}
        </div>
      )}
    </div>
  )
}
