import React from 'react'

// import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RegularNodeContainer from '../Containers/Regular'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'
// import useTeams from 'src/hooks/useTeams'
// import useChannels from 'src/hooks/useChannels'

// const useStyles = makeStyles((theme) => ({
//   chip: {
//     margin: 2
//   }
// }))

export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'condition.filter'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  // const classes = useStyles()
  // const teams = useTeams()
  // const { channels } = useChannels()

  return (
    <RegularNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <Typography variant='subtitle2' color='textSecondary' component='p'>
        When the team is any of:
      </Typography>
      {JSON.stringify(node.properties.data?.conditions, null, 2)}
    </RegularNodeContainer>
  )
}
