import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useOrganization from '../../../../hooks/useOrganization'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'
import ProviderCard from './ProviderCard'
import { useHistory } from 'react-router-dom'

import { IChannel } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const history = useHistory()
  const organization = useOrganization()
  const channels: IChannel[] = useSelector(
    (state: any) => state.firestore.ordered.channels
  )

  useFirestoreConnect(
    !organization
      ? []
      : [
          {
            collection: 'channels',
            where: [
              [
                'organization',
                '==',
                firestore.doc(`organizations/${organization.id}`)
              ],
              ['softRemoved', '==', false]
            ]
            // orderBy: ['name', 'asc']
          }
        ]
  )

  const handleReconnectChannel = useCallback(
    (channelId) => {
      firestore
        .collection('channels')
        .doc(channelId)
        .update({ connectionStatus: 'reconnecting' })
    },
    [firestore]
  )

  const handleEditChannel = useCallback(
    (channelId) => {
      if (organization) {
        history.push(`/org/${organization.id}/channels/${channelId}/edit`)
      }
    },
    [organization, history]
  )

  return (
    <Grid container spacing={3} className={classes.root}>
      {!isLoaded(channels) && <div>Loading...</div>}
      {isLoaded(channels) && isEmpty(channels) && <div>No channels found</div>}
      {isLoaded(channels) &&
        !isEmpty(channels) &&
        channels.map((channel, i) => {
          return (
            <Grid item xs={3} key={i}>
              <ProviderCard
                key={channel.id}
                channel={channel}
                onEditChannel={handleEditChannel}
                onReconnect={handleReconnectChannel}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}
