import React from 'react'
// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TimeAgo from 'timeago-react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: '45%',
    borderRadius: theme.spacing(2),
    textAlign: 'left'
  },
  positionLeft: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(6)
  },
  positionRight: {
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(6)
  }
}))

const MessageRow = ({ children, message }) => {
  const classes = useStyles()
  const { auth } = useSelector((state) => state.firebase)

  let delivered, read
  // Check if the message wasn't sent by the contact and the message was seen by it
  if (message.direction === 'outbound' && message.recipient) {
    delivered =
      message.deliveredAt && message.deliveredAt[message.recipient.externalId]
    read = message.readAt && message.readAt[message.recipient.externalId]
  } else {
    delivered = message.deliveredAt && message.deliveredAt[auth.uid]
    read = message.readAt && message.readAt[auth.uid]
  }

  return (
    <>
      {children}
      <p>
        {delivered
          ? `Delivered at: ${JSON.stringify(message.deliveredAt)}`
          : 'pending...'}
      </p>
      <p>{read ? `Read at: ${JSON.stringify(message.readAt)}` : 'Not read'}</p>
      <TimeAgo
        className={classes.timestamp}
        datetime={message.sentAt}
        locale='en_US'
      />
    </>
  )
}

export default MessageRow
