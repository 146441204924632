import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

function UnsupportedMessage() {
  const classes = useStyles()

  return <div className={classes.root}>{'No soportado'}</div>
}

export default UnsupportedMessage
