import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'

import SaveButton from './components/SaveButton'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import GridLayout from './components/GridLayout'
import { Basic as Layout } from '../../layouts'
import Select from '@material-ui/core/Select'

import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import { IDashboardLayout, TDashboardLayoutModule } from '@il-postino/types'

import useOrganization from '../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 'calc(100vh -20px)'
  }
}))

type Params = {
  dashboardLayoutId?: string
}

const DashboardPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const firestore = useFirestore()
  const i18nNs = 'pages.dashboard'
  const history = useHistory()
  const organization = useOrganization()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [modules, setModules] = useState<IDashboardLayout['modules']>([])
  const [newModules, setNewModules] = useState<IDashboardLayout['modules']>([])
  const [selectedLayout, setSelectedLayout] = useState<IDashboardLayout>()

  const { dashboardLayoutId }: Params = useParams()
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  useEffect(() => {
    console.log('Mount my profile')
    return () => {
      console.log('Unmount my profile')
    }
  }, [])

  useFirestoreConnect(
    organization
      ? [
          {
            collection: 'organizations',
            doc: organization.id,
            subcollections: [
              {
                collection: 'dashboardLayouts'
              }
            ],
            storeAs: 'dashboardLayouts',
            orderBy: ['name', 'asc']
          }
        ]
      : []
  )

  const dashboardLayouts: IDashboardLayout[] = useSelector(
    (state: any) => state.firestore.ordered?.dashboardLayouts
  )

  // useEffect(() => {
  //   if (
  //     isLoaded(dashboardLayouts) &&
  //     !isEmpty(dashboardLayouts) &&
  //     !selectedLayout
  //   ) {
  //     history.push(`/org/${organization.id}/my-profile/general`)
  //   }
  // }, [dashboardLayouts, history, selectedLayout, organization.id])

  // useEffect(() => {
  //   if (
  //     dashboardLayoutId &&
  //     isLoaded(dashboardLayouts) &&
  //     !isEmpty(dashboardLayouts)
  //   ) {
  //     const selected = dashboardLayouts.find(
  //       (dL) => dL.id === dashboardLayoutId
  //     )
  //     if (selected) {
  //       console.log('updated!')
  //       setSelectedLayout(selected)
  //       setModules(selected.modules)
  //       // setNewModules(selected.modules)
  //     }
  //   }
  // }, [dashboardLayoutId, dashboardLayouts])

  const handleOpenModulesMenu = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseModulesMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectLayout = useCallback(
    (e: any) => {
      if (isLoaded(dashboardLayouts) && !isEmpty(dashboardLayouts)) {
        const dashboardLayoutId = e.target.value
        history.push(`/org/${organization?.id}/my-profile/${dashboardLayoutId}`)
      }
    },
    [dashboardLayouts, history, organization]
  )

  const onAddModule = useCallback(
    (module) => {
      setModules(
        modules.concat({
          i: `n ${module} + ${modules.length}`,
          // x: (modules.length * 2) % (cols || 12),
          x: 0,
          y: Infinity, // puts it at the bottom
          w: 2,
          h: 2,
          moduleKey: module
        })
      )
      setNewModules(modules)
    },
    [modules]
  )

  // const onRemoveItem = useCallback(
  //   (id: any) => {
  //     setModules(_.reject(modules, { i: id }))
  //     setNewModules(_.reject(newModules, { i: id }))
  //   },
  //   [newModules, modules]
  // )

  const handleSaveDashboard = useCallback(() => {
    if (selectedLayout) {
      firestore
        .doc(
          `organizations/${organization?.id}/dashboardLayouts/${selectedLayout.id}`
        )
        .update({
          modules: newModules.map((m: TDashboardLayoutModule) => {
            // Merge the DB modules object with the modules of the layout change to keep the custom properties (such as moduleKey)
            return {
              ...modules.find((module) => module.i === m.i),
              ..._.omitBy(m, _.isUndefined)
            }
          })
        })
    }
  }, [firestore, modules, newModules, organization, selectedLayout])

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      topBarWithBackground={false}
      topBarActions={
        <React.Fragment>
          {isEditMode ? (
            <React.Fragment>
              <Button
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={handleOpenModulesMenu}
              >
                Add a module
              </Button>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseModulesMenu}
              >
                {['Counter', 'ConversationsByChannel'].map((module: any) => {
                  return (
                    <MenuItem
                      value={module}
                      onClick={() => {
                        onAddModule(module)
                        handleCloseModulesMenu()
                      }}
                      key={module}
                    >
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={module} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={module}
                          secondary={`@${module}`}
                        />
                      </ListItem>
                    </MenuItem>
                  )
                })}
              </Menu>
              <SaveButton
                onClick={() => {
                  setIsEditMode(false)
                  handleSaveDashboard()
                }}
                secondaryOptions={[
                  {
                    onClick: () => {
                      console.log('Change name...')
                    },
                    text: 'Change name'
                  },
                  {
                    onClick: () => {
                      console.log('Save as...')
                    },
                    text: 'Save as'
                  },
                  {
                    onClick: () => {
                      console.log('Remove...')
                    },
                    text: 'Remove'
                  }
                ]}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedLayout?.id || ''}
                onChange={handleSelectLayout}
              >
                {isLoaded(dashboardLayouts) &&
                  Array.isArray(dashboardLayouts) &&
                  dashboardLayouts.map((dL) => {
                    return (
                      <MenuItem key={dL.id} value={dL.id}>
                        {dL.name}
                      </MenuItem>
                    )
                  })}
              </Select>
              <Button onClick={() => setIsEditMode(true)}>
                Edit dashboard
              </Button>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={fixedHeightPaper}>
              {!isLoaded(dashboardLayouts) && 'Loading...'}
              {isLoaded(dashboardLayouts) && modules && (
                <GridLayout
                  layout={modules || []}
                  onLayoutChange={(modules: TDashboardLayoutModule[]) => {
                    setNewModules(modules)
                  }}
                  // onRemoveItem={onRemoveItem}
                  readOnly={!isEditMode}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default DashboardPage
