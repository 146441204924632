import { combineReducers } from 'redux'

// Firebase
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore' // <- needed if using firestore

// redux-persist
import { persistReducer } from 'redux-persist'
import localforage from 'localforage'

import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?(): any
  }
}

const rootReducer = combineReducers({
  firebase: persistReducer(
    { key: 'context', storage: localforage, stateReconciler: hardSet },
    firebaseReducer
  ),

  firestore: firestoreReducer,

  devTools:
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
})

export default rootReducer

export type IRootState = ReturnType<typeof rootReducer>
