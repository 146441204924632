import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'

import ChannelsList from './components/ChannelsList'
import ChannelDetails from './components/ChannelDetails'
import Container from '@material-ui/core/Container'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { omit as _omit } from 'lodash'
import { Form } from 'react-final-form'
import SettingsSubdrawer from '../components/SettingsSubdrawer'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IChannel } from '@il-postino/types'
import { validateForm as validateFacebookForm } from './components/ChannelsComponents/FacebookForm'
import { validateForm as validateTelegramForm } from './components/ChannelsComponents/TelegramForm'

import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'

import { Basic as Layout } from '../../../layouts'

import useOrganization from '../../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}))

type Props = any
type Params = {
  channelId?: string
  action?: 'create' | 'edit'
}
type FormData = any
const ChannelsPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const firestore = useFirestore()
  const i18nNs = 'pages.channels'
  const history = useHistory()
  const { channelId, action }: Params = useParams()
  const organization = useOrganization()

  const basePath = useMemo(() => {
    return organization && `/org/${organization.id}/settings/channels`
  }, [organization])

  const handleAddChannel = useCallback(() => {
    const newChannelRef = firestore.collection('channels').doc()
    history.push(`${basePath}/${newChannelRef.id}/create`)
  }, [firestore, history, basePath])

  useFirestoreConnect(
    channelId
      ? [
          {
            collection: 'channels',
            doc: channelId
          }
        ]
      : []
  )
  const channelData: IChannel = useSelector((state: any) => {
    if (channelId) {
      const channel = state.firestore.data.channels?.[channelId]
      return !channel?.softRemoved ? channel : null
    }
    return null
  })

  const handleDelete = useCallback(() => {
    // loader.start()
    firestore
      .collection('channels')
      .doc(channelId)
      .update({ softRemoved: true })
      .then(() => {
        // loader.complete()
        history.push(`${basePath}`)
      })
  }, [channelId, firestore, history, basePath])

  async function onSubmit(values: Partial<FormData>) {
    // loader.start()
    const channelRef = firestore.collection('channels').doc(channelId)
    if (organization) {
      const data = {
        ...values,
        softRemoved: false,
        organization: firestore.doc(`/organizations/${organization.id}`)
      }
      if (action === 'create') {
        channelRef.set({ ...data, connectionStatus: 'pending' }).finally(() => {
          // loader.complete()
          history.push(`${basePath}//${channelId}/edit`)
        })
      } else {
        channelRef.update(data).finally(() => {
          // loader.complete()
        })
      }
    }
  }

  // yes, this can even be async!
  async function validate(values: FormData) {
    if (!values.key) {
      return { key: 'An channel is required' }
    }

    switch (values.key) {
      case 'messenger':
        return validateFacebookForm(values)
      case 'telegram':
        return validateTelegramForm(values)
    }

    return
  }

  const initialValues = useMemo(() => {
    if (isLoaded(channelData)) {
      const channel: Partial<IChannel> = !isEmpty(channelData)
        ? _omit(channelData, 'organization')
        : { enabled: true }
      return channel
    }
  }, [channelData])

  let submit: any

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      goBackButtonLink={action ? basePath : null}
      topBarActions={
        <React.Fragment>
          {!channelId && (
            <IconButton onClick={handleAddChannel}>
              <AddIcon />
            </IconButton>
          )}
          {action && channelId && (
            <React.Fragment>
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  submit?.()
                }}
              >
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      <Container maxWidth='lg' className={classes.container}>
        {/* If there's no channel selected, then show the full list */}
        {!channelId && <ChannelsList />}

        {/* If there's a selected channel, then display the create/edit form */}
        {!isLoaded(channelData) && action && <div>Loading...</div>}

        {isLoaded(channelData) &&
        ((!isEmpty(channelData) && action === 'edit') || action === 'create') &&
        channelId ? (
          <Grid container spacing={3}>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validate}
              render={({ handleSubmit, values }) => {
                submit = handleSubmit
                return (
                  <ChannelDetails
                    channelId={channelId}
                    action={action}
                    values={values}
                    onSubmit={handleSubmit}
                  />
                )
              }}
            />
          </Grid>
        ) : (
          isLoaded(channelData) &&
          isEmpty(channelData) && <div>Channel not found :(</div>
        )}
      </Container>
    </Layout>
  )
}

export default ChannelsPage
