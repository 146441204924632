import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import { ITeamWithUsers } from '../index'

export default function GroupedAvatars(props: {
  team: ITeamWithUsers
  id: any
}) {
  const { team, id } = props
  return (
    <AvatarGroup max={5}>
      {team.users.map((u, i) => {
        return <Avatar key={`team-${team.id}-avatar-${i}`} src={u.avatarUrl} />
      })}
    </AvatarGroup>
  )
}
