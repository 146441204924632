// Pages
import AutomationFlowsPage from './AutomationFlows'
import BillingProfile from './Settings/BillingProfile'
import Dashboard from './Dashboard'
import Channels from './Settings/Channels'
import Integrations from './Settings/Integrations'
import Conversations from './Conversations'
import CustomFields from './Settings/CustomFields'
import General from './Settings/General'
import MessageTemplates from './Settings/MessageTemplates'
import Organization from './Organization'
import Settings from './Settings'
import QuickReplies from './Settings/QuickReplies'
import Tags from './Settings/Tags'
import TeamsAndUsers from './Settings/TeamsAndUsers'
import MyProfile from './MyProfile'
import SelectOrganization from './SelectOrganization'
import CreateOrganization from './CreateOrganization'
import LogoutPage from './Logout'
import PageNotFound from './NotFound'
import OrganizationRedirect from './OrganizationRedirect'
import Redirect from './Redirect'

// Icons
import CachedIcon from '@material-ui/icons/Cached'
import DashboardIcon from '@material-ui/icons/Dashboard'
import OrganizationIcon from '@material-ui/icons/Settings'
import ForumIcon from '@material-ui/icons/Forum'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import SettingsIcon from '@material-ui/icons/Settings'
import TuneIcon from '@material-ui/icons/Tune'
import PaymentIcon from '@material-ui/icons/Payment'
import Crop75Icon from '@material-ui/icons/Crop75'
import RateReviewIcon from '@material-ui/icons/RateReview'
import PowerIcon from '@material-ui/icons/Power'
import LabelIcon from '@material-ui/icons/Label'
import FlashOnIcon from '@material-ui/icons/FlashOn'

const menu = [
  {
    key: 'createOrganization',
    path: '/create-organization',
    exact: true,
    requiresAuth: true,
    Component: CreateOrganization
  },
  {
    key: 'selectOrganization',
    path: '/select-organization',
    exact: true,
    requiresAuth: true,
    Component: SelectOrganization
  },
  {
    key: 'logout',
    path: '/logout',
    exact: true,
    requiresAuth: true,
    Component: LogoutPage
  },
  {
    key: 'dashboard',
    i18nNamespace: 'menu.dashboard',
    to: '/dashboards',
    path: '/org/:organizationId/dashboards/:dashboardLayoutId?',
    requiresAuth: true,
    menuSections: ['MainMenu'],
    Component: Dashboard,
    Icon: DashboardIcon
  },
  {
    key: 'conversations',
    i18nNamespace: 'menu.conversations',
    to: '/conversations',
    path: '/org/:organizationId/conversations/:conversationId?',
    requiresAuth: true,
    menuSections: ['MainMenu'],
    Component: Conversations,
    Icon: ForumIcon
  },
  {
    key: 'automationFlows',
    i18nNamespace: 'menu.automation-flows',
    to: '/automation-flows',
    path: '/org/:organizationId/automation-flows/:automationFlowId?/:nodeId?',
    requiresAuth: true,
    menuSections: ['MainMenu'],
    Component: AutomationFlowsPage,
    Icon: DeviceHubIcon
  },
  {
    key: 'settings',
    i18nNamespace: 'menu.settings',
    to: '/settings',
    path: '/org/:organizationId/settings',
    requiresAuth: true,
    menuSections: ['MainMenu'],
    Component: Settings,
    Icon: SettingsIcon,
    subMenu: [
      {
        key: 'general',
        i18nNamespace: 'menu.general',
        to: '/settings/general',
        path: '/org/:organizationId/settings/general',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: General,
        Icon: TuneIcon
      },
      {
        key: 'customFields',
        i18nNamespace: 'menu.custom-fields',
        to: '/settings/custom-fields',
        path: '/org/:organizationId/settings/custom-fields',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: CustomFields,
        Icon: Crop75Icon
      },
      {
        key: 'quickReplies',
        i18nNamespace: 'menu.quick-replies',
        to: '/settings/quick-replies',
        path: '/org/:organizationId/settings/quick-replies',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: QuickReplies,
        Icon: FlashOnIcon
      },
      {
        key: 'messageTemplates',
        i18nNamespace: 'menu.message-templates',
        to: '/settings/message-templates',
        path: '/org/:organizationId/settings/message-templates',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: MessageTemplates,
        Icon: RateReviewIcon
      },
      {
        key: 'teamsAndUsers',
        i18nNamespace: 'menu.teams-and-users',
        to: '/settings/teams-and-users',
        path: '/org/:organizationId/settings/teams-and-users',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: TeamsAndUsers,
        Icon: PeopleAltIcon
      },
      {
        key: 'tags',
        i18nNamespace: 'menu.tags',
        to: '/settings/tags',
        path: '/org/:organizationId/settings/tags',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: Tags,
        Icon: LabelIcon
      },
      {
        key: 'channels',
        i18nNamespace: 'menu.channels',
        to: '/settings/channels',
        path:
          '/org/:organizationId/settings/channels/:channelId?/:action(create|edit)?',
        requiresAuth: true,
        menuSections: ['MainMenu'],
        Component: Channels,
        Icon: CachedIcon
      },
      {
        key: 'integrations',
        i18nNamespace: 'menu.integrations',
        to: '/settings/integrations',
        path:
          '/org/:organizationId/settings/integrations/:action(add|create|edit)?/:id?',
        requiresAuth: true,
        menuSections: ['MainMenu'],
        Component: Integrations,
        Icon: PowerIcon
      },
      {
        key: 'billingProfile',
        i18nNamespace: 'menu.billing-profile',
        to: '/settings/billing-profile',
        path: '/org/:organizationId/settings/billing-profile',
        requiresAuth: true,
        menuSections: ['SubMenu'],
        Component: BillingProfile,
        Icon: PaymentIcon
      }
    ]
  },
  {
    key: 'myOrganization',
    i18nNamespace: 'menu.my-organization',
    to: '/my-organization',
    path: '/org/:organizationId/my-organization',
    requiresAuth: true,
    menuSections: ['MainMenu', 'SecondaryMenu'],
    Component: Organization,
    Icon: OrganizationIcon
  },
  {
    key: 'myProfile',
    i18nNamespace: 'menu.my-profile',
    to: '/my-profile',
    path: '/org/:organizationId/my-profile',
    requiresAuth: true,
    Component: MyProfile,
    Icon: OrganizationIcon
  },
  {
    key: 'organizationRedirect',
    exact: true,
    path: '/org/:organizationId',
    requiresAuth: true,
    Component: OrganizationRedirect
  },
  {
    key: 'organizationPageNotFound',
    path: '/org/:organizationId/*',
    requiresAuth: true,
    Component: PageNotFound
  },
  {
    key: 'pageNotFound',
    path: '*',
    requiresAuth: true,
    Component: Redirect
  }
]

export const getPrivate = () => {
  return menu.filter(({ requiresAuth }) => !!requiresAuth)
}

export const getMenu = (section: string): any => {
  function copy(o: {}) {
    return Object.assign({}, o)
  }

  return menu.map(copy).filter(function f(o: any) {
    let flag = false
    let subItemsFlag = false
    if (o.menuSections?.includes(section) || !section) {
      flag = true
    }
    if (o.items) {
      o.items = o.items.map(copy).filter(f)
      subItemsFlag = !!o.items.length
    }
    return flag || (o.items && subItemsFlag)
  })
}

export const getSubMenu = (menuKey?: string, section?: string) => {
  function copy(o: {}) {
    return Object.assign({}, o)
  }

  const chosenMenu = (menu.find((m) => m.key === menuKey) || []) as any

  return chosenMenu?.subMenu.filter(function f(o: any) {
    let flag = false
    let subItemsFlag = false
    if (o.menuSections?.includes(section) || !section) {
      flag = true
    }
    if (o.items) {
      o.items = o.items.map(copy).filter(f)
      subItemsFlag = !!o.items.length
    }
    return flag || (o.items && subItemsFlag)
  })
}

export default menu
