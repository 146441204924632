import React, { useCallback, useMemo } from 'react'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TNode } from '@il-postino/types'
import { v4 as uuidv4 } from 'uuid'

import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import LinkIcon from '@material-ui/icons/Link'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import { set as _set } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {},
  margin: {
    margin: theme.spacing(1)
  }
}))
export default ({
  node,
  onEditNode
}: {
  node: TNode<'action.webHook:post'>
  onEditNode: (newNode: TNode<'action.webHook:post'>) => void
}) => {
  const classes = useStyles()

  const handleChange = useCallback(
    (e: any) => {
      console.log('e.currentTarget', e.target.name, e.target.value)
      const newNode = _set(
        { ...node },
        `properties.data.${e.target.name}`,
        e.target.value
      )
      onEditNode(newNode)
    },
    [node, onEditNode]
  )

  const bearerToken = useMemo(() => {
    return node.properties.data?.auth?.bearer || uuidv4()
  }, [node])

  return (
    <div className={clsx(classes.root)}>
      <TextField
        className={classes.margin}
        label='Enter an URL'
        onChange={handleChange}
        InputProps={{
          name: 'url',
          value: node.properties.data?.url,
          startAdornment: (
            <InputAdornment position='start'>
              <LinkIcon />
            </InputAdornment>
          )
        }}
      />
      <TextField
        className={classes.margin}
        label='Bearer token'
        onChange={handleChange}
        InputProps={{
          name: 'auth.bearer',
          value: bearerToken,
          startAdornment: (
            <InputAdornment position='start'>
              <VpnKeyIcon />
            </InputAdornment>
          )
        }}
      />
      <Typography variant='subtitle2' color='textSecondary'>
        {JSON.stringify(node, null, 2)}
      </Typography>
    </div>
  )
}
