import React, { memo, useCallback } from 'react'

import { useIntl } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { getSubMenu } from '../../structure'
import { useLocation, useHistory } from 'react-router-dom'

import useOrganization from '../../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: 24,
    lineHeight: 29
  }
}))

const loadingElements = [...new Array(5).keys()]

function SettingsSubdrawer() {
  const classes = useStyles()
  const { pathname } = useLocation()
  const history = useHistory()
  const organization = useOrganization()

  const { formatMessage: i18n } = useIntl()

  const handleMenuClick = useCallback(
    (path) => {
      history.push(path)
    },
    [history]
  )

  return (
    <React.Fragment>
      <List
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            {'Settings'}
          </ListSubheader>
        }
      >
        {getSubMenu('settings').map((menuItem: any) => {
          return (
            <ListItem
              button
              key={menuItem.key}
              onClick={() =>
                handleMenuClick(`/org/${organization?.id}${menuItem.to}`)
              }
              selected={pathname.includes(menuItem.path)}
            >
              <ListItemIcon>
                <menuItem.Icon />
              </ListItemIcon>
              <ListItemText primary={i18n({ id: menuItem.i18nNamespace })} />
            </ListItem>
          )
        })}
      </List>
    </React.Fragment>
  )
}

export default memo(SettingsSubdrawer)
