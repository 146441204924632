import React from 'react'

import Typography from '@material-ui/core/Typography'
import RegularNodeContainer from '../Containers/Regular'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'
import Chip from '@material-ui/core/Chip'
import useChannels from 'src/hooks/useChannels'

export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'trigger.conversation:created'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  const { channels } = useChannels()

  return (
    <RegularNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <Typography variant='subtitle2' color='textSecondary' component='p'>
        When the channel is:
      </Typography>
      <Chip
        size='small'
        color='secondary'
        label={
          channels.find((c) => c.id === node.properties.data?.channel?.id)
            ?.name || node.properties.data?.channel?.id
        }
      />
    </RegularNodeContainer>
  )
}
