import React, { memo, useMemo, useCallback } from 'react'

import * as actions from '@lucho_1312/react-flow-chart/src/container/actions'
import { FlowChart, IFlowChartCallbacks } from '@lucho_1312/react-flow-chart'

import CustomCanvasOuter from './CustomCanvasOuter'
import CustomCanvasInner from './CustomCanvasInner'
import NodeInner from './CustomNodeInner'
import CustomNode from './CustomNode'
import CustomPort from './CustomPort'

export default memo(
  ({
    onChangeAutomationFlow,
    automationFlow,
    onNodeClick,
    onCloseBuilderItemsSideBar,
    config
  }: any) => {
    const CustomNodeInner = useCallback(
      (props) => <NodeInner {...props} onNodeClick={onNodeClick} />,
      [onNodeClick]
    )

    const customCallbacks = useMemo<{ [key: string]: any }>(() => {
      return {
        onNodeClick: ({ nodeId }: { nodeId: string }) => {
          onCloseBuilderItemsSideBar()
          if (onNodeClick) {
            onNodeClick(nodeId)
          }
        },
        onCanvasClick: ({ nodeId }: { nodeId: string }) => {
          onCloseBuilderItemsSideBar()
        },
        onLinkClick: ({ nodeId }: { nodeId: string }) => {
          onCloseBuilderItemsSideBar()
        }
      }
    }, [onCloseBuilderItemsSideBar, onNodeClick])

    const stateActionCallbacks = useMemo(() => {
      return Object.entries(actions).reduce(
        (
          acc: { [key: string]: any },
          [actionKey, action]: [string, (...args: any) => any]
        ) => {
          acc[actionKey] = (...args: any) => {
            const newChartTransformer = action(...args)
            const newChart = newChartTransformer(automationFlow.flow)
            if (customCallbacks[actionKey]) {
              customCallbacks[actionKey](...args)
            }
            onChangeAutomationFlow({ ...automationFlow, flow: { ...newChart } })
            return newChart
          }
          return acc
        },
        {}
      ) as IFlowChartCallbacks
    }, [automationFlow, customCallbacks, onChangeAutomationFlow])
    return (
      <FlowChart
        chart={automationFlow.flow}
        callbacks={stateActionCallbacks}
        Components={{
          CanvasOuter: CustomCanvasOuter,
          CanvasInner: CustomCanvasInner,
          NodeInner: CustomNodeInner,
          Node: CustomNode,
          Port: CustomPort
        }}
        config={config}
      />
    )
  }
)
