import React from 'react'
// import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import MainSideBar from './components/MainSideBar'
import { motion } from 'framer-motion'

import MainWrapper from './MainWrapper'
import TopBar from './components/TopBar'

const useStyles = makeStyles((theme) => ({
  mainColumn: {
    position: 'relative',
    width: '100%'
  },
  content: {
    // flexGrow: 1,
    // width: '100%',
    height: '100vh',
    overflow: 'auto'
    // paddingTop: theme.spacing(8),
  },

  appBarSpacer: theme.mixins.toolbar
}))

export const Basic = ({
  children,
  sectionTitle,
  subDrawer,
  topBarActions,
  goBackButtonLink,
  topBarWithBackground
}: any) => {
  const classes = useStyles()

  return (
    <MainWrapper>
      <MainSideBar subDrawer={subDrawer} />
      <div className={classes.mainColumn}>
        {/* <TopBar onClickTab={onClickTab} activeTab={activeTab} /> */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          className={clsx(
            { [classes.content]: true },
            { [classes.appBarSpacer]: true }
          )}
        >
          {sectionTitle ? (
            <TopBar
              headline={sectionTitle}
              actions={topBarActions}
              goBackButtonLink={goBackButtonLink}
              withBackground={topBarWithBackground}
            />
          ) : null}
          {/* <main> */}
          {children}
          {/* </main> */}
        </motion.div>
      </div>
    </MainWrapper>
  )
}
