import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'

import { Select, Switches } from 'mui-rff'

import { IChannel } from '@il-postino/types'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'

import FacebookForm from './ChannelsComponents/FacebookForm'
import TelegramForm from './ChannelsComponents/TelegramForm'

type Props = {
  channelId: IChannel['id']
  action: 'create' | 'edit'
  values: any
  onSubmit: any
}
export default ({ channelId, action, onSubmit, values }: Props) => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.channels'

  const formFields: any[] = [
    <Switches
      label={i18n({ id: `${i18nNs}.form.fields.enabled.label` })}
      name='enabled'
      data={{ label: 'Active', value: true }}
    />
  ]

  const getChannelSpecificForm = useCallback(
    (key) => {
      switch (key) {
        case 'messenger':
          return <FacebookForm values={values} />
        case 'telegram':
          return <TelegramForm values={values} />
        default:
          return <div>key: {key}</div>
      }
    },
    [values]
  )

  return (
    <form onSubmit={onSubmit} noValidate>
      <Grid container direction='column' spacing={3}>
        <Grid item>
          <Select
            name='key'
            label={i18n({ id: `${i18nNs}.form.fields.key.label` })}
            formControlProps={{ margin: 'normal' }}
            disabled={!!values.params}
            required={true}
            value={values.key}
          >
            {['messenger', 'telegram', 'whatsApp'].map((key: any) => {
              return (
                <MenuItem value={key}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={key} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={i18n({
                        id: `${i18nNs}.form.fields.key.options.${key}`
                      })}
                    />
                  </ListItem>
                </MenuItem>
              )
            })}
          </Select>
        </Grid>
        {values.key &&
          formFields.map((item, idx) => (
            <Grid item key={idx}>
              {item}
            </Grid>
          ))}
      </Grid>

      {values?.key && getChannelSpecificForm(values.key)}
      <pre
        style={{
          overflowX: 'auto',
          width: 800
        }}
      >
        {JSON.stringify(values, null, 2)}
      </pre>
    </form>
  )
}
