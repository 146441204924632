import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useOrganization from '../../../../hooks/useOrganization'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'
import AppCard from './AppCard'
import { useHistory } from 'react-router-dom'

import { IApp } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const history = useHistory()
  const organization = useOrganization()

  const basePath = useMemo(() => {
    return organization && `/org/${organization.id}/settings/integrations`
  }, [organization])

  const apps: IApp[] = useSelector((state: any) => state.firestore.ordered.apps)

  useFirestoreConnect(
    !organization
      ? []
      : [
          {
            collection: 'apps',
            where: [
              [
                'visibility',
                'in',
                ['public', firestore.doc(`organizations/${organization.id}`)]
              ],
              ['softRemoved', '==', false]
            ]
            // orderBy: ['name', 'asc']
          }
        ]
  )

  const onSelectApp = useCallback(
    (app) => {
      if (basePath) {
        history.push(`${basePath}/add/${app.id}`)
      }
    },
    [history, basePath]
  )

  return (
    <Grid container spacing={3} className={classes.root}>
      {!isLoaded(apps) && <div>Loading...</div>}
      {isLoaded(apps) && isEmpty(apps) && <div>No apps found</div>}
      {isLoaded(apps) &&
        !isEmpty(apps) &&
        apps.map((app, i) => {
          return (
            <Grid item xs={4} key={i}>
              <AppCard key={app.id} app={app} onSelectApp={onSelectApp} />
            </Grid>
          )
        })}
    </Grid>
  )
}
