import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { IPortDefaultProps } from '@lucho_1312/react-flow-chart'
import StatsCounter from '../StatsCounter'

const useStyles = makeStyles((theme) => ({
  portDefaultOuter: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover > div > div': {
      background: theme.palette.secondary.main
    }
  },
  portDefaultInner: {
    width: '10px',
    height: '10px',
    cursor: 'pointer',
    transform: 'rotate(45deg)'
  },

  stem: {
    position: 'absolute',
    width: '3px',
    height: '11px',
    background: 'grey',
    top: '-2px',
    left: '7px'
  },

  sick: {
    position: 'absolute',
    width: '4px',
    height: '3px',
    background: 'grey',
    left: '4px',
    top: '6px'
  },

  active: {
    background: theme.palette.secondary.main
  }
}))

export default ({
  isLinkSelected,
  isLinkHovered,
  port,
  node,
  config
}: IPortDefaultProps) => {
  const classes = useStyles()

  const isActive = !config.readonly && (isLinkSelected || isLinkHovered)
  return (
    <div className={classes.portDefaultOuter}>
      {config.showStats && (
        <StatsCounter config={config} node={node} port={port} />
      )}
      <div
        className={clsx(classes.portDefaultInner, {
          [classes.active]: isActive
        })}
      >
        <div className={classes.stem}></div>
        <div className={classes.sick}></div>
      </div>
    </div>
  )
}
