import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { INodeInnerDefaultProps } from '@lucho_1312/react-flow-chart'

import CardContent from '@material-ui/core/CardContent'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 220,
    background: '#ffff88',
    padding: theme.spacing(1)
  },
  nodeContent: {
    paddingTop: theme.spacing(0)
  }
}))

interface CustomNodeProps extends INodeInnerDefaultProps {
  onNodeClick?: (nodeId: string) => void
  children: any
}

export default ({ onNodeClick, node, children, config }: CustomNodeProps) => {
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      onClick={() => onNodeClick && onNodeClick(node.id)}
    >
      <CardContent className={classes.nodeContent}>{children}</CardContent>
    </div>
  )
}
