import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IConfig, IOnCanvasClick } from '@lucho_1312/react-flow-chart'

export interface ICanvasInnerDefaultProps {
  config: IConfig
  children: any
  onClick: IOnCanvasClick
  tabIndex: number
  onKeyDown: (e: React.KeyboardEvent) => void
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    outline: '1px dashed rgba(0, 0, 0, 0.1)',
    width: '4000px',
    height: '3000px',
    cursor: 'move',
    backgroundSize: '20px 20px',
    backgroundColor: theme.palette.divider,
    backgroundImage:
      'linear-gradient(90deg, hsla(0, 0%, 100%, 0.2) 1px, transparent 0 ), linear-gradient(180deg, hsla(0, 0%, 100%, 0.2) 1px, transparent 0)'
  }
}))

export default ({ children, ...props }: any) => {
  const classes = useStyles()

  return (
    <div {...props} className={classes.root}>
      {children}
    </div>
  )
}
