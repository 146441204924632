import React, { memo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useHistory } from 'react-router-dom'

import { Unauthenticated as LayoutUnauthenticated } from '../../layouts'

// import GoogleButton from 'react-google-button' // optional
import CreateOrganizationForm from './components/CreateOrganizationForm'
import PendingInvitations from '../SelectOrganization/components/PendingInvitations'

import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

const CreateOrganizationPage = () => {
  const history = useHistory()
  const classes = useStyles()

  const { auth, profile } = useSelector((state) => state.firebase)

  const { formatMessage: i18n } = useIntl()

  const i18nNs = 'pages.create-organization'

  const handleCreatedOrganization = useCallback(
    (createdOrganizationId) => {
      history.push(`/${createdOrganizationId}`)
    },
    [history]
  )

  return (
    <LayoutUnauthenticated
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <Container maxWidth='xs' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant='h5' component='h1' align='center'>
              {i18n(
                { id: `${i18nNs}.title` },
                { displayName: profile?.displayName?.replace(/ .*/, '') }
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <PendingInvitations profile={profile} />
            <CreateOrganizationForm
              auth={auth}
              handleCreatedOrganization={handleCreatedOrganization}
            />
          </Grid>
        </Grid>
      </Container>
    </LayoutUnauthenticated>
  )
}

export default memo(CreateOrganizationPage)
