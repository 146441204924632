import React, { useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TNode } from '@il-postino/types'
import useChannels from 'src/hooks/useChannels'
import { useFirestore } from 'react-redux-firebase'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { set as _set } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))
export default ({
  node,
  onEditNode
}: {
  node: TNode<'trigger.conversation:created'>
  onEditNode: (newNode: TNode<'trigger.conversation:created'>) => void
}) => {
  const classes = useStyles()
  const [selectedChannel, setSelectedChannel] = React.useState<string>(
    node.properties.data?.channel?.id || ''
  )
  const firestore = useFirestore()
  const { channels } = useChannels()

  const handleChange = useCallback(
    (e: any) => {
      const newNode = _set(
        { ...node },
        `properties.data.${e.target.name}`,
        e.target.name === 'channel'
          ? firestore.doc(`channels/${e.target.value}`)
          : e.target.value.map((teamId: string) => {
              return firestore.doc(`teams/${teamId}`)
            })
      )
      onEditNode(newNode)
    },
    [node, onEditNode, firestore]
  )

  const handleChangeChannel = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedChannel(event.target.value as string)
    handleChange(event)
  }

  return (
    <div className={clsx(classes.root)}>
      <FormControl className={classes.formControl}>
        <InputLabel id={node.id}>Channel</InputLabel>
        <Select
          labelId={`channel-${node.id}`}
          className={classes.selectEmpty}
          name='channel'
          value={selectedChannel}
          onChange={handleChangeChannel}
        >
          {channels.map((c) => {
            return (
              <MenuItem key={c.id} value={c.id}>
                {c.name}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    </div>
  )
}
