const fonts = {
  size: {
    extraSmall: '11px',
    small: '13px',
    normal: '15px',
    big: '16px',
    large: '18px',
    xLarge: '20px',
    xxLarge: '25px'
  },
  headlineSize: {
    small: '28px',
    normal: '35px',
    big: '45px',
    large: '55px',
    xLarge: '65px',
    xxLarge: '75px'
  },
  weight: {
    thin: '100',
    light: '300',
    regular: '400',
    medium: '500',
    bold: '700',
    black: '900'
  },
  family: {
    primary: `"Lato", sans-serif`,
    secondary: `"Montserrat", sans-serif`
  }
}

export default fonts
