import Conversation from './Conversation'

import * as SystemMessagesTypes from './SystemMessagesTypes'

import { IMessage } from '@il-postino/types'

export type IConversationMessage =
  | IMessage
  | SystemMessagesTypes.ISystemMessagesTypes

export default Conversation
