import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardHeader from '@material-ui/core/CardHeader'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { IIntegration } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}))

type Props = {
  integration: IIntegration
  onEditIntegration: any
  onReconnect: (integrationId: IIntegration['id']) => void
}
const ConnectionCard = ({
  integration,
  onEditIntegration,
  onReconnect
}: Props) => {
  const classes = useStyles()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.integrations'

  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => {
          onEditIntegration(integration.id)
        }}
      >
        <CardHeader title={integration.name} subheader={integration.app.name} />
        <CardMedia
          component='img'
          alt={integration.name}
          // height='160'
          image={integration.app.avatarUrl}
          title={integration.name}
          style={{ maxWidth: 100, maxHeight: 100, margin: '0 auto' }}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {integration.name}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            @{integration.username}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size='medium' color='primary'>
            {i18n({ id: `${i18nNs}.buttons.settings` })}
          </Button>
          {integration.connectionStatus !== 'connected' && (
            <Button
              size='medium'
              color='primary'
              disabled={['pending', 'reconnecting'].includes(
                integration.connectionStatus
              )}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation()
                onReconnect(integration.id)
              }}
            >
              {i18n({ id: `${i18nNs}.buttons.reconnect` })}
            </Button>
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  )
}

export default ConnectionCard
