import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useOrganization from '../hooks/useOrganization'
import { IState } from '../types'

function Redirect() {
  const history = useHistory()

  const { profile } = useSelector((state: IState) => state.firebase)

  const organization = useOrganization()

  useEffect(() => {
    console.log('Holis')
    if (!organization && profile) {
      if (
        !profile.organizations ||
        !Object.keys(profile.organizations).length
      ) {
        history.push('/create-organization')
      }
      if (
        profile.organizations &&
        Object.keys(profile.organizations).length === 1
      ) {
        history.push(`/org/${Object.keys(profile.organizations)[0]}`)
      }
      if (
        profile.organizations &&
        Object.keys(profile.organizations).length > 1
      ) {
        history.push('/select-organization')
      }
    }
  }, [history, organization, profile])

  return <div>{'Redirecting'}</div>
}

export default Redirect
