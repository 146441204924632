import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Paper from '@material-ui/core/Paper'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { useSelector } from 'react-redux'

import { Unauthenticated as LayoutUnauthenticated } from '../../layouts'

// import GoogleButton from 'react-google-button' // optional
import OrganizationSelectorList from './components/OrganizationSelectorList'
import PendingInvitations from './components/PendingInvitations'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  }
}))

const SelectOrganizationPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.select-organization'
  const { profile } = useSelector((state) => state.firebase)

  return (
    <LayoutUnauthenticated
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <Container maxWidth='xs' className={classes.container}>
        <Grid container spacing={3}>
          {!!profile.organizations?.length ? (
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant='h5' component='h1' align='center'>
                {i18n(
                  { id: `${i18nNs}.title` },
                  { displayName: profile.displayName.replace(/ .*/, '') }
                )}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                <PendingInvitations profile={profile} />
                <OrganizationSelectorList
                  profileOrganizations={profile.organizations}
                />
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </LayoutUnauthenticated>
  )
}

export default SelectOrganizationPage
