import React, { memo } from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { IUser } from '@il-postino/types'

import * as MessagesTypes from './MessagesTypes'

import * as SystemMessagesTypes from './SystemMessagesTypes'

import { IConversationMessage } from './index'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(0.5)
  }
  // flowChange: {
  //   marginTop: theme.spacing(1)
  // },
}))

type IProps = {
  user?: IUser
  conversationFirstName?: string
  message: IConversationMessage
}

function ConversationRow({ message, user, conversationFirstName }: IProps) {
  const classes = useStyles()
  console.log('message', message)
  let messageRow

  if (message.type === 'text') {
    messageRow = (
      <MessagesTypes.Text
        message={message}
        user={user}
        conversationFirstName={conversationFirstName}
      />
    )
  } else if (message.type === 'date') {
    messageRow = <SystemMessagesTypes.Date message={message} />
  } else {
    messageRow = <SystemMessagesTypes.Unsupported />
  }

  return (
    <div
      className={clsx({
        [classes.root]: true
        // [classes.flowChange]: message.flowChange
      })}
    >
      {messageRow}
    </div>
  )
}

export default memo(ConversationRow)
