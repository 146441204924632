import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Basic as Layout } from '../../layouts'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { IOrganization } from '@il-postino/types'

import { Form } from 'react-final-form'
import { TextField, KeyboardDatePicker } from 'mui-rff'

import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from './components/SettingsSubdrawer'

import useOrganization from '../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  container: {
    display: 'Flex',
    justifyContent: 'center'
  },
  actions: {
    float: 'right'
  }
}))

type FormData = any

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const organization = useOrganization()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.message-templates'

  // yes, this can even be async!
  async function onSubmit(values: FormData) {
    // loader.start()
    firestore
      .collection('organizations')
      .doc(organization?.id)
      .update({ name: values.name })
      .then(() => {
        // loader.complete()
      })
  }

  // yes, this can even be async!
  async function validate(values: FormData) {
    if (!values.name) {
      return { name: 'An organization name is required' }
    }
    return
  }

  const initialValues = { name: organization?.name }

  const formFields: any[] = [
    <TextField
      name='name'
      label={i18n({ id: `${i18nNs}.form.fields.name.label` })}
      required={true}
    />
  ]

  let submit: any
  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      topBarActions={
        <React.Fragment>
          <IconButton onClick={(event) => submit(event)}>
            <SaveIcon />
          </IconButton>
        </React.Fragment>
      }
    >
      <Container className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validate}
              render={({ handleSubmit, values }) => {
                submit = handleSubmit
                return (
                  <form onSubmit={onSubmit} noValidate>
                    <Grid container direction='column' alignContent='stretch'>
                      {formFields.map((item, idx) => (
                        <Grid item key={idx}>
                          {item}
                        </Grid>
                      ))}
                    </Grid>
                    <pre>{JSON.stringify(values)}</pre>
                  </form>
                )
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
