import React from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import hermesWing from './hermes-wing.png'
import { IMessage } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    width: 16
  },
  img: {
    position: 'absolute',
    width: 12,
    filter:
      'invert(0%) sepia(6%) saturate(7500%) hue-rotate(328deg) brightness(94%) contrast(106%)'
  },
  img2: {
    left: 8
  },
  imgRead: {
    filter:
      'invert(47%) sepia(26%) saturate(4910%) hue-rotate(170deg) brightness(100%) contrast(105%)'
  }
}))

type IProps = {
  message: IMessage
}

function Wings({ message }: IProps) {
  const classes = useStyles()

  const { sentAt, deliveredAt, readAt } = message

  let status = null as 'read' | 'delivered' | 'send' | null

  if (readAt && Object.keys(readAt).length) {
    status = 'read'
  } else if (deliveredAt && Object.keys(deliveredAt).length) {
    status = 'delivered'
  } else if (sentAt && Object.keys(sentAt).length) {
    status = 'send'
  }

  return (
    <div className={classes.root}>
      <img
        className={clsx({
          [classes.img]: true,
          [classes.imgRead]: status === 'read'
        })}
        src={hermesWing}
        alt={'Hermes wing'}
      />
      {status === 'delivered' || status === 'read' ? (
        <img
          className={clsx({
            [classes.img]: true,
            [classes.img2]: true,
            [classes.imgRead]: status === 'read'
          })}
          src={hermesWing}
          alt={'Hermes wing'}
        />
      ) : null}
    </div>
  )
}

export default Wings
