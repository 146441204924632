import React from 'react'

import { Route, Switch } from 'react-router-dom'

import { getPrivate } from '../pages/structure'

const privateRoutes = getPrivate()

function Private() {
  return (
    <Switch>
      {privateRoutes.reduce(
        (acc, { path, exact = false, Component, subMenu }) => {
          if (subMenu) {
            subMenu.forEach(({ path, Component }) => {
              acc.push(<Route path={`${path}`}>{() => <Component />}</Route>)
            })
          }
          acc.push(
            <Route path={`${path}`} exact={exact}>
              {() => {
                return <Component />
              }}
            </Route>
          )
          return acc
        },
        [] as any[]
      )}
    </Switch>
  )
}

export default Private
