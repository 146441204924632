import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import SaveIcon from '@material-ui/icons/Save'
import IconButton from '@material-ui/core/IconButton'
import axios from 'axios'
import { ChargeBee, _portal_session } from 'chargebee-typescript'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import { Basic as Layout } from '../../layouts'
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { IOrganization, IBillingProfile } from '@il-postino/types'

import { Form } from 'react-final-form'
import { TextField, Select, KeyboardDatePicker } from 'mui-rff'

import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from './components/SettingsSubdrawer'

import useOrganization from '../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  card: {
    maxWidth: 420,
    marginTop: 50
  },
  container: {
    display: 'Flex',
    justifyContent: 'center'
  },
  actions: {
    float: 'right'
  }
}))

type FormData = any

type Props = {
  auth: any
  profile: any
  organization: IOrganization
  menuItem: any
  loader: any
}

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const [initializedChargebee, setInitializedChargebee] = useState(false)
  const { formatMessage: i18n } = useIntl()
  const organization = useOrganization()
  const i18nNs = 'pages.billingProfile'

  useFirestoreConnect(
    organization
      ? [
          {
            collection: 'organizations',
            doc: organization.id
          },
          {
            collection: 'billingProfiles',
            doc: organization.id
          }
        ]
      : []
  )

  const organizationData: IOrganization = useSelector(
    ({ firestore: { data } }: any): any =>
      organization && data.organizations?.[organization.id]
  )

  const billingProfile: IBillingProfile = useSelector(
    ({ firestore: { data } }: any): any =>
      organization && data.billingProfiles?.[organization?.id]
  )

  // yes, this can even be async!
  async function onSubmit(values: FormData) {
    // loader.start()
    firestore
      .collection('billingProfiles')
      .doc(organization?.id)
      .update({
        organization: firestore.doc(`organizations/${organization?.id}`),
        ...values
      })
      .then(() => {
        // loader.complete()
      })
  }

  // yes, this can even be async!
  async function validate(values: FormData) {
    if (!values.businessName) {
      return { businessName: 'An organization name is required' }
    }
    return
  }

  const formFields: any[] = [
    <TextField
      name='firstName'
      label={i18n({ id: `${i18nNs}.form.fields.firstName.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.firstName.helperText` })}
      required={true}
    />,
    <TextField
      name='lastName'
      label={i18n({ id: `${i18nNs}.form.fields.lastName.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.lastName.helperText` })}
      required={true}
    />,
    <TextField
      name='businessName'
      label={i18n({ id: `${i18nNs}.form.fields.businessName.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.businessName.helperText` })}
      required={true}
    />,
    <TextField
      name='email'
      label={i18n({ id: `${i18nNs}.form.fields.email.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.email.helperText` })}
      required={true}
    />,
    <TextField
      name='phone'
      label={i18n({ id: `${i18nNs}.form.fields.phone.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.phone.helperText` })}
      required={true}
    />,
    <Select
      label={i18n({ id: `${i18nNs}.form.fields.preferredCurrency.label` })}
      name='preferredCurrency'
      required={true}
      data={[
        { label: 'USD', value: 'USD' },
        { label: 'NZD', value: 'NZD' }
      ]}
      helperText={i18n({
        id: `${i18nNs}.form.fields.preferredCurrency.helperText`
      })}
    />
  ]

  const initialValues = useMemo(() => {
    if (isLoaded(billingProfile)) {
      const bP = { ...billingProfile }
      if (!isEmpty(billingProfile)) {
        delete bP.organization
      }

      return bP
    }
  }, [billingProfile])

  useEffect(() => {
    if (!initializedChargebee) {
      axios
        .get(
          `https://us-central1-il-postino-app.cloudfunctions.net/internal/billing/${organization?.id}`
        )
        .then(({ data: portalSession }) => {
          console.log('portalSession', portalSession)
          ;(window as any).Chargebee.registerAgain()
          const chargebeeInstance = (window as any).Chargebee.getInstance()
          chargebeeInstance.setPortalSession(portalSession)
          setInitializedChargebee(true)
        })
        .catch(console.error)
    }
  }, [organization, setInitializedChargebee, initializedChargebee])

  let submit: any

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      topBarActions={
        <React.Fragment>
          <IconButton onClick={(event) => submit?.(event)}>
            <SaveIcon />
          </IconButton>
        </React.Fragment>
      }
    >
      <Container className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={6}>
            {!isLoaded(billingProfile) ? (
              'Loading...'
            ) : (
              <Form
                onSubmit={onSubmit}
                initialValues={initialValues}
                validate={validate}
                render={({ handleSubmit, values }) => {
                  submit = handleSubmit
                  return (
                    <form onSubmit={onSubmit} noValidate>
                      <Grid container direction='column' alignContent='stretch'>
                        {formFields.map((item, idx) => (
                          <Grid item key={idx}>
                            {item}
                          </Grid>
                        ))}
                      </Grid>
                      <pre>{JSON.stringify(values, null, 2)}</pre>
                    </form>
                  )
                }}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            Plan information
            {!isLoaded(organizationData) ? (
              'Loading...'
            ) : (
              <pre>{JSON.stringify(organizationData.plan, null, 2)}</pre>
            )}
            <button data-cb-type='portal'>{'Manage organization'}</button>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
