import React, { useState, useCallback } from 'react'
import { WidthProvider, Responsive } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import _ from 'lodash'

import ConversationsByChannel from '../modules/ConversationsByChannel'
import Counter from '../modules/Counter'
import { TDashboardLayoutModule } from '@il-postino/types'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export default ({
  columns = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
  onLayoutChange,
  layout,
  readOnly = false
}: any) => {
  const [cols, setCols] = useState(12)

  // We're using the cols coming back from this to calculate where to add new items.
  const onBreakpointChange = useCallback(
    (breakpoint: any, cols: any) => {
      setCols(cols)
    },
    [setCols]
  )
  const handleLayoutChange = useCallback(
    (layout: any) => {
      onLayoutChange?.(layout)
      // setLayout(layout)
    },
    [onLayoutChange]
  )

  const handleRemoveItem = useCallback(
    (id) => {
      const newLayout = _.reject(layout, { i: id })
      handleLayoutChange(newLayout)
    },
    [layout, handleLayoutChange]
  )

  const createElement = useCallback(
    (el: TDashboardLayoutModule) => {
      let ModuleComponent
      switch (el.moduleKey) {
        case 'ConversationsByChannel':
          ModuleComponent = ConversationsByChannel
          break
        case 'Counter':
          ModuleComponent = Counter
          break
      }

      return (
        ModuleComponent && (
          <div key={el.i} data-grid={el} {...el}>
            <ModuleComponent
              key={el.i}
              element={el}
              onRemoveItem={handleRemoveItem}
              readOnly={readOnly}
              {...el}
            />
          </div>
        )
      )
    },
    [layout, readOnly]
  )

  return (
    <div>
      <ResponsiveReactGridLayout
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={onBreakpointChange}
        cols={columns}
        className='layout'
        rowHeight={100}
        isDraggable={!readOnly}
        isResizable={!readOnly}
      >
        {layout.map((el: any) => createElement(el))}
      </ResponsiveReactGridLayout>
    </div>
  )
}
