import { useEffect, useRef, useMemo } from 'react'
import {
  useFirestore,
  ReduxFirestoreQuerySetting,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import { useSelector } from 'react-redux'

import _isEqual from 'lodash-es/isEqual'
/**
 * @description React hook that automatically listens/unListens
 * to provided Cloud Firestore paths. Using pagination path to save the data
 **/

export default function useFirestoreConnectCustom(
  querySetting: ReduxFirestoreQuerySetting
) {
  const firestore = useFirestore()

  // This contains all the queries
  const queryListRef = useRef<ReduxFirestoreQuerySetting[] | null>(null)

  // This contains the last the query to compare
  const queryLastRef = useRef<ReduxFirestoreQuerySetting | null>(null)

  // We nee the storeAs property to make the pagination
  if (!querySetting.storeAs) {
    throw new Error('The query must use storeAs to use this hook')
  }

  // This hook is used only to paginate
  if (!querySetting.limit) {
    throw new Error('The query must use limit to use this hook')
  }

  useEffect(() => {
    if (queryLastRef.current && queryListRef.current) {
      // I take off the storeAs because i'm modifying it and it make the isEqual fail
      const {
        startAfter,
        endBefore,
        storeAs,
        ...otherQuerySetting
      } = querySetting

      const {
        startAfter: oldStartAfter,
        storeAs: oldStoreAs,
        endBefore: oldEndBefore,
        ...oldOtherQuerySetting
      } = queryLastRef.current

      const isEqualQuery = _isEqual(oldOtherQuerySetting, otherQuerySetting)

      const isEqualStartAfter = _isEqual(oldStartAfter, startAfter)

      const isEqualEndBefore = _isEqual(oldEndBefore, endBefore)

      if (isEqualQuery && (!isEqualStartAfter || !isEqualEndBefore)) {
        console.log('Cambió solo el startAfter', startAfter)
        // If only changes the startAfter i need to change the page

        const { storeAs } = querySetting

        const newElement = {
          ...querySetting,
          storeAs: `${storeAs}${queryListRef.current?.length || 0}`
        }

        queryLastRef.current = newElement

        if (!isEqualStartAfter) {
          queryListRef.current.push(newElement)
        } else {
          queryListRef.current.unshift(newElement)
        }

        firestore.setListeners([newElement])
      } else if (!isEqualQuery) {
        console.log('Cambio la query')
        // If the query changes i need to start again, not tested
        if (queryListRef.current) {
          firestore.unsetListeners(queryListRef.current)
        }

        const { storeAs } = querySetting

        const queryWithPage = {
          ...querySetting,
          storeAs: `${storeAs}0`
        }

        firestore.setListeners([queryWithPage])

        queryLastRef.current = queryWithPage

        queryListRef.current = [queryWithPage]
      } else {
        // If the query is the same i'm not doing nothing
        console.log('Son la misma query no hago nada')
      }
    } else {
      // First mount i must start to listen and create the query list
      console.log('Primer mount')

      const {
        startAfter,
        endBefore,
        storeAs,
        ...otherQuerySetting
      } = querySetting

      const queryLastMessages = {
        ...otherQuerySetting,
        endAt: startAfter,
        storeAs: `${storeAs}0`
      }

      const queryWithPage = {
        ...querySetting,
        startAfter,
        storeAs: `${storeAs}1`
      }

      firestore.setListeners([queryLastMessages, queryWithPage])

      queryLastRef.current = queryWithPage

      queryListRef.current = [queryLastMessages, queryWithPage]
    }
  }, [firestore, querySetting])

  useEffect(() => {
    return () => {
      if (queryListRef.current) {
        console.log('Unmount')
        firestore.unsetListeners(queryListRef.current)

        queryLastRef.current = null

        queryListRef.current = null
      }
    }
    // Equivalent to componentWillUnmount
  }, [firestore])

  const orderedData = useSelector(
    (state: any) => state.firestore.ordered as any
  )

  const mappedData = useMemo(
    () =>
      queryListRef.current?.reduce((acc, { limit, storeAs }) => {
        if (storeAs) {
          acc = [...acc, ...(orderedData[storeAs] || [])]
        }
        return acc
      }, [] as any) || [],
    [orderedData]
  )

  let isFetchingNext = false
  let hasMoreNext = true
  if (queryLastRef.current) {
    const { storeAs } = queryLastRef.current
    if (storeAs) {
      const data = orderedData[storeAs]
      hasMoreNext = !isEmpty(data)
      isFetchingNext = !isLoaded(data)
    }
  }

  return [mappedData, { hasMoreNext, isFetchingNext }]
}
