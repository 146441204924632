import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import NoteNodeContainer from '../Containers/Note'
import { Editor, EditorState, convertFromRaw } from 'draft-js'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300
    // background: 'yellow'
  }
}))
export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'note'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  const classes = useStyles()

  return (
    <NoteNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <div className={clsx(classes.root)}>
        <Editor
          editorState={
            node.properties.data?.content
              ? EditorState.createWithContent(
                  convertFromRaw(node.properties.data.content)
                )
              : EditorState.createEmpty()
          }
          onChange={() => {}}
          readOnly
        />
      </div>
    </NoteNodeContainer>
  )
}
