import React from 'react'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core'

const styles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main
  }
}))

type Props = {
  onInviteUsers: any
  onEditTeam: any
}

export default function TableActions({ onInviteUsers, onEditTeam }: Props) {
  const classes = styles()
  return (
    <div>
      <IconButton classes={{ root: classes.root }}>
        <SearchRoundedIcon />
      </IconButton>
      <IconButton classes={{ root: classes.root }} onClick={onInviteUsers}>
        <PersonAddRoundedIcon />
      </IconButton>
      <IconButton classes={{ root: classes.root }} onClick={onEditTeam}>
        <MoreVertRoundedIcon />
      </IconButton>
    </div>
  )
}
