import React, { useMemo, useCallback } from 'react'

import Drawer from '@material-ui/core/Drawer'

import Avatar from '@material-ui/core/Avatar'

import Brightness4Icon from '@material-ui/icons/Brightness4'
// import NotificationsIcon from '@material-ui/icons/Notifications'

import MenuItem from './MenuItem'
import LogoIcon from './LogoIcon'
import { getMenu } from '../../../pages/structure'
import { useParams } from 'react-router-dom'

import { useDarkMode } from '../../../providers/DarkModeProvider'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { IState } from '../../../types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 56
  },
  spacer: {
    flex: 1
  },
  drawerPaper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}))

export default function MainMenu() {
  const classes = useStyles()
  const { organizationId } = useParams()

  const { profile } = useSelector((state: IState) => state.firebase)
  const organization = profile?.organizations?.[organizationId]

  const [colorScheme, setColorScheme] = useDarkMode()

  const { pathname } = useLocation()

  const mainMenu = useMemo(() => {
    return getMenu('MainMenu')
  }, [])

  const handleToggleTheme = useCallback(() => {
    if (colorScheme === 'light') {
      setColorScheme('dark')
    } else {
      setColorScheme('light')
    }
  }, [colorScheme, setColorScheme])

  const OrganizationAvatar = useCallback(
    () => (
      <Avatar alt={organization.displayName} src={organization.displayName} />
    ),
    [organization]
  )

  // const ProfileAvatar = useCallback(
  //   () => <Avatar alt={profile.displayName} src={profile.avatarUrl} />,
  //   [profile]
  // )

  return (
    <Drawer
      variant='permanent'
      className={classes.root}
      classes={{ paper: classes.drawerPaper }}
    >
      <LogoIcon />
      {mainMenu
        .filter(({ Icon }: any) => !!Icon)
        .map((menuItem: any) => (
          <MenuItem
            key={menuItem.key}
            Icon={menuItem.Icon}
            to={`/org/${organizationId}${menuItem.to}`}
            active={pathname.startsWith(`/org/${organizationId}${menuItem.to}`)}
          />
        ))}
      <div className={classes.spacer} />
      {/* Bottom Menu */}
      <MenuItem Icon={Brightness4Icon} onClick={handleToggleTheme} />
      <MenuItem to={'/select-organization'} Icon={OrganizationAvatar} />
    </Drawer>
  )
}
