import React, { useCallback } from 'react'
import { ContentState } from 'draft-js'

import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css'
import { TNode } from '@il-postino/types'
import Input from '../../Input'

const useStyles = makeStyles((theme) => ({
  root: {},
  editor: {
    cursor: 'text',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#DFE1E5',
    margin: '0 auto',
    textAlign: 'left',
    width: '95%',
    minHeight: 300,
    maxHeight: 500,
    // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)',
    borderRadius: theme.spacing(0.2),
    overflowY: 'scroll'
  }
}))

function tryParseJSON(jsonString: string) {
  try {
    var o = JSON.parse(jsonString)

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === 'object') {
      return o
    }
  } catch (e) {}

  return false
}

export default ({
  node,
  onEditNode
}: {
  node: TNode<'condition.filter'>
  onEditNode: (newNode: TNode<'condition.filter'>) => void
}) => {
  const classes = useStyles()

  const handleChange = useCallback(
    (content: ContentState) => {
      const contentText = content.getPlainText()
      if (tryParseJSON(contentText)) {
        node.properties.data = {
          conditions: JSON.parse(contentText)
        }
        onEditNode({ ...node })
      }
    },
    [node, onEditNode]
  )

  return (
    <div className={clsx(classes.root)}>
      <Input
        initialValue={ContentState.createFromText(
          JSON.stringify(node.properties.data?.conditions || {}, null, 2)
        )}
        onChange={handleChange}
      />

      <Typography variant='subtitle2' color='textSecondary'>
        {JSON.stringify(node?.properties, null, 2)}
      </Typography>
    </div>
  )
}
