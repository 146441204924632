import React, { memo, useMemo, useState, useCallback, useRef } from 'react'

import { useFirestore } from 'react-redux-firebase'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage, IConversation } from '@il-postino/types'

import { IConversationMessage } from './index'

import ConversationRow from './Row'

import useFirestoreConnectCustom from './useFirestoreConnectCustom'

import InfiniteScroll from 'react-infinite-scroller'

import { useSelector } from 'react-redux'

import { IState } from '../../types'

import { orderAndIntersperseMessages } from './utils'

import { useFirestoreConnect } from 'react-redux-firebase'

import * as FirestoreTypes from '@firebase/firestore-types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'scroll',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#ECEFF1',
    flex: '1'
  }
}))

const LIMIT = 20

type IProps = {
  organizationId: string
  conversationId: string
  conversationFirstName?: string
  lastMessageAt: IConversation['lastMessageAt'] | undefined
}

function ConversationMessages({
  organizationId,
  conversationId,
  conversationFirstName,
  lastMessageAt
}: IProps) {
  const classes = useStyles()
  const parentRef = useRef(null)
  const firestore = useFirestore()

  const [startAfter, setStartAfter] = useState<
    IConversation['lastMessageAt'] | undefined
  >(lastMessageAt)

  const [messages, other] = useFirestoreConnectCustom({
    collection: 'conversations',
    doc: conversationId,
    subcollections: [
      {
        collection: 'messages',
        where: [
          [
            'organization',
            '==',
            firestore.doc(`organizations/${organizationId}`)
          ],
          ['hidden', '==', false]
        ]
      }
    ],
    storeAs: `messagesByConversation${conversationId}`,
    orderBy: ['sentAt', 'desc'],
    limit: LIMIT,
    startAfter
  }) as [IMessage[], { hasMoreNext: boolean; isFetchingNext: boolean }]

  // TODO: This must be move to a root place
  useFirestoreConnect([
    {
      collection: 'users',
      where: [[`organizations.${organizationId}.id`, '==', organizationId]]
    }
  ])

  const orderedMessages = useMemo(() => orderAndIntersperseMessages(messages), [
    messages
  ]) as IConversationMessage[]

  const users = useSelector((state: IState) => state.firestore.data.users)

  const handleLoadMore = useCallback(() => {
    setStartAfter(messages?.[messages.length - 1].sentAt)
  }, [messages])

  return (
    <div className={classes.root} ref={parentRef}>
      {orderedMessages && orderedMessages.length ? (
        <InfiniteScroll
          pageStart={0}
          isReverse
          initialLoad={false}
          threshold={100}
          useWindow={false}
          loadMore={handleLoadMore}
          hasMore={other.hasMoreNext}
          loader={<div key={0}>{'Fetching more messages...'}</div>}
          getScrollParent={() => parentRef.current}
        >
          {orderedMessages.map((message: any) => (
            <ConversationRow
              key={message.id}
              message={message}
              user={users?.[message.sender?.id]}
              conversationFirstName={conversationFirstName}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <span>{'Initial loading...'}</span>
      )}
    </div>
  )
}

export default memo(ConversationMessages)
