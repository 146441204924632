import React from 'react'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { Select } from 'mui-rff'
import { FacebookProvider, Login, Status } from 'react-facebook'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

import Avatar from '@material-ui/core/Avatar'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import { IChannel } from '@il-postino/types'

import { useForm } from 'react-final-form'

type Props = {
  values: IChannel<'messenger'>
}
export default ({ values }: Props) => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.channels.facebook'
  const formApi = useForm()

  const formFields: any[] = [
    Array.isArray(values.params?.fbLogin?.pages) ? (
      <React.Fragment>
        <Select
          name='params.connectedPageId'
          label={i18n({ id: `${i18nNs}.form.fields.connectedPage.label` })}
          formControlProps={{ margin: 'normal' }}
          value={values.params?.connectedPageId}
          disabled={!!values.params.connectedPageLongLivedAccessToken}
        >
          {values.params.fbLogin.pages.map((page: any) => {
            return (
              <MenuItem value={page.id}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src={page.picture?.data?.url} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={page.name}
                    secondary={`@${page.username}`}
                  />
                </ListItem>
              </MenuItem>
            )
          })}
        </Select>
        {!values.params?.connectedPageId && (
          <FormHelperText>
            {i18n({
              id: `${i18nNs}.form.fields.connectedPage.helperText`
            })}
          </FormHelperText>
        )}
      </React.Fragment>
    ) : null
  ]

  const handleResponse = async (data: any) => {
    const windowContext: any = window
    windowContext.FB.api(
      `/me/accounts?fields=picture,name,id,access_token,tasks,category_list,category,about,bio,is_webhooks_subscribed,connected_instagram_account,has_whatsapp_business_number,has_whatsapp_number,whatsapp_number,instagram_business_account,link,description,app_id,company_overview,can_checkin,can_post,emails,general_info,has_added_app,hours,username,website&access_token=${data.tokenDetail?.accessToken}`,
      (accounts: any) => {
        formApi.registerField('params.fbLogin', () => null, {})
        formApi.change('params.fbLogin', {
          profile: data.profile,
          tokenDetail: data.tokenDetail,
          pages: accounts.data // TODO: Results are paginated. Concatenate all the results
        })
      }
    )
  }

  const handleError = (error: any) => {
    alert(`Facebook error:  ${error}`)
  }

  return (
    <Grid container direction='column' spacing={3}>
      <FacebookProvider appId='2531838576835120'>
        <Status>
          {({ loading, status }: any) => (
            <div>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <Grid item>
                  <Login
                    scope='email,public_profile,pages_messaging,leads_retrieval,pages_manage_metadata,pages_show_list'
                    onCompleted={handleResponse}
                    onError={handleError}
                  >
                    {({
                      loading: loadingLogin,
                      handleClick,
                      error,
                      data
                    }: any) => (
                      <FormControl>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={handleClick}
                        >
                          {!values.params?.fbLogin
                            ? loadingLogin
                              ? 'Connecting with Facebook...'
                              : 'Connect Messenger'
                            : 'Reconnect Messenger'}
                        </Button>
                        <FormHelperText>
                          {!values.params?.fbLogin
                            ? i18n({
                                id: `${i18nNs}.form.fields.connect.helperTextDisconnected`
                              })
                            : i18n({
                                id: `${i18nNs}.form.fields.connect.helperTextConnected`
                              })}
                        </FormHelperText>
                      </FormControl>
                    )}
                  </Login>
                </Grid>
              )}
              {formFields.map((item, idx) => (
                <Grid item key={idx}>
                  {item}
                </Grid>
              ))}
            </div>
          )}
        </Status>
      </FacebookProvider>
    </Grid>
  )
}

export const validateForm = (values: any) => {
  if (!values.params?.fbLogin?.tokenDetail?.accessToken) {
    return { key: 'You must connect your Facebook account' }
  }
  if (!values.params?.connectedPageId) {
    return { params: { connectedPageId: 'A Page must be selected' } }
  }
  return
}
