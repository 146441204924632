import React from 'react'
import { Redirect } from 'react-router-dom'

import { useParams } from 'react-router-dom'

function OrganizationRedirect() {
  const { organizationId } = useParams()

  return <Redirect to={`${organizationId}/conversations`} />
}

export default OrganizationRedirect
