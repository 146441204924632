import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'

import useOrganization from '../../../hooks/useOrganization'
import AppsList from './components/AppsList'
import AppPage from './components/AppPage'
import IntegrationPage from './components/IntegrationPage'
import IntegrationsList from './components/IntegrationsList'
import Container from '@material-ui/core/Container'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { omit as _omit } from 'lodash'
import { Form } from 'react-final-form'
import SettingsSubdrawer from '../components/SettingsSubdrawer'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IIntegration } from '@il-postino/types'

import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'

import { Basic as Layout } from '../../../layouts'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 240
  }
}))

type Props = any
type Params = {
  id?: string
  action?: 'add' | 'create' | 'edit'
}
type FormData = any
const IntegrationsPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const firestore = useFirestore()
  const i18nNs = 'pages.integrations'
  const history = useHistory()
  const { id, action }: Params = useParams()
  const organization = useOrganization()

  const basePath = useMemo(() => {
    return organization && `/org/${organization.id}/settings/integrations`
  }, [organization])

  const handleAddIntegration = useCallback(() => {
    history.push(`${basePath}/add`)
  }, [history, basePath])

  useFirestoreConnect(
    id
      ? [
          {
            collection: 'integrations',
            doc: id
          }
        ]
      : []
  )
  const integrationData: IIntegration = useSelector((state: any) => {
    if (id) {
      const integration = state.firestore.data.integrations?.[id]
      return !integration?.softRemoved ? integration : null
    }
    return null
  })

  const handleDelete = useCallback(() => {
    firestore
      .collection('integrations')
      .doc(id)
      .update({ softRemoved: true })
      .then(() => {
        history.push(`${basePath}`)
      })
  }, [id, firestore, history, basePath])

  async function onSubmit(values: Partial<FormData>) {
    const integrationRef = firestore.collection('integrations').doc(id)
    if (organization) {
      const data = {
        ...values,
        softRemoved: false,
        organization: firestore.doc(`/organizations/${organization.id}`)
      }
      if (action === 'create') {
        integrationRef
          .set({ ...data, connectionStatus: 'pending' })
          .finally(() => {
            history.push(`${basePath}/${id}/edit`)
          })
      } else {
        integrationRef.update(data).finally(() => {})
      }
    }
  }

  // yes, this can even be async!
  async function validate(values: FormData) {
    if (!values.key) {
      return { key: 'An integration is required' }
    }
    return
  }

  const initialValues = useMemo(() => {
    if (isLoaded(integrationData)) {
      const integration: Partial<IIntegration> = !isEmpty(integrationData)
        ? _omit(integrationData, 'organization')
        : { enabled: true }
      return integration
    }
  }, [integrationData])

  let submit: any

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      goBackButtonLink={action ? `${basePath}` : null}
      topBarActions={
        <React.Fragment>
          {!id && !action && (
            <IconButton onClick={handleAddIntegration}>
              <AddIcon />
            </IconButton>
          )}
          {action && action !== 'add' && id && (
            <React.Fragment>
              <IconButton onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  submit?.()
                }}
              >
                <SaveIcon />
              </IconButton>
            </React.Fragment>
          )}
        </React.Fragment>
      }
    >
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          {/* If there's no integration selected, then show the full list */}
          {!id && !action && <IntegrationsList />}

          {action === 'add' && !id && <AppsList />}
          {action === 'add' && id && <AppPage appId={id} action={action} />}

          {action === 'edit' && id && (
            <IntegrationPage integrationId={id} action={action} />
          )}

          {/* If there's a selected integration, then display the create/edit form */}
          {id && !isLoaded(integrationData) && !action && <div>Loading...</div>}

          {/* {isLoaded(integrationData) &&
          ((!isEmpty(integrationData) && action === 'edit') ||
            action === 'create') &&
          id ? (
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              validate={validate}
              render={({ handleSubmit, values }) => {
                submit = handleSubmit
                return (
                  <IntegrationDetails
                    id={id}
                    action={action}
                    values={values}
                    onSubmit={handleSubmit}
                  />
                )
              }}
            />
          ) : (
            isLoaded(integrationData) &&
            isEmpty(integrationData) && <div>Integration not found :(</div>
          )} */}
        </Grid>
      </Container>
    </Layout>
  )
}

export default IntegrationsPage
