import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { useFirebase } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import { Conversational as LayoutConversational } from '../layouts'

import * as jwtDecode from 'jwt-decode'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  fixedHeight: {
    height: 540
  }
}))

const OrganizationPage = () => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const firebase = useFirebase()
  const history = useHistory()

  const { auth, profile } = useSelector((state) => state.firebase)

  const i18nNs = 'pages.my-organization'

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight)

  function handleLogout() {
    firebase.logout()
    history.push('/')
  }

  // This used to be in Router.js. I only put it here to keep the code
  // useFirestoreConnect(() => {
  //   if (isLoaded(auth) && !isEmpty(auth)) {
  //     return [{ collection: 'organizations', doc: selectedOrganizationId }]
  //   }
  // })
  // useFirestoreConnect(() => {
  //   if (profile?.organizations?.length) {
  //     console.log('+++ Fetch: profileOrganizations', profileOrganizations)
  //     return [{ collection: 'organizations', where: ['id', 'in', profileOrganizations] }]
  //   }
  // })
  // const organizations = useSelector((state) => state.firestore.data.organizations)

  return (
    <LayoutConversational sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}>
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <Avatar alt={profile.displayName} src={profile.avatarUrl} />
              <Button onClick={handleLogout} color='primary'>
                Logout
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Paper className={fixedHeightPaper}>
              {/* {i18n({ id: `${i18nNs}.Dashboard` })} */}
              <h2>auth</h2>
              <pre>{JSON.stringify(auth, null, 2)}</pre>
            </Paper>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Paper className={fixedHeightPaper}>
              {/* {i18n({ id: `${i18nNs}.Dashboard` })} */}
              <h2>token</h2>
              <pre>
                {JSON.stringify(
                  jwtDecode(auth.stsTokenManager.accessToken),
                  null,
                  2
                )}
              </pre>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </LayoutConversational>
  )
}

export default OrganizationPage
