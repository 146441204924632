import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Slide from '@material-ui/core/Slide'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'

import FilterForm from './Nodes/Filter/Form'
import SplitAbForm from './Nodes/SplitAb/Form'
import NewConversationForm from './Nodes/NewConversation/Form'
import NewConversationMessageForm from './Nodes/NewConversationMessage/Form'
import SendMessageForm from './Nodes/SendMessage/Form'
import AssignConversationForm from './Nodes/AssignConversation/Form'
import NoteForm from './Nodes/Note/Form'
import WebHookForm from './Nodes/WebHook/Form'

import MaterialIcon from '../../../components/MaterialIcon'
import { TNode } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 300,
    height: '100%',
    background: theme.palette.background.default
  },
  settingsHeader: {
    paddingBottom: theme.spacing(1)
  },
  settingsContent: {
    paddingTop: theme.spacing(1)
  },
  saveButton: {
    float: 'right'
  }
}))

interface IAutomationFlowNodeSettingsSideBar {
  onEditNode: (newNode: TNode) => void
  node: TNode
  automationFlow?: any
}

export default ({ node, onEditNode }: IAutomationFlowNodeSettingsSideBar) => {
  const classes = useStyles()

  const FormComponent = useMemo(() => {
    switch (node.properties.key) {
      case 'action.conversation:assign':
        return (
          <AssignConversationForm
            node={node as TNode<'action.conversation:assign'>}
            onEditNode={onEditNode}
          />
        )
      case 'action.conversation:sendMessage':
        return (
          <SendMessageForm
            node={node as TNode<'action.conversation:sendMessage'>}
            onEditNode={onEditNode}
          />
        )
      case 'action.webHook:post':
        return (
          <WebHookForm
            node={node as TNode<'action.webHook:post'>}
            onEditNode={onEditNode}
          />
        )
      case 'condition.filter':
        return (
          <FilterForm
            node={node as TNode<'condition.filter'>}
            onEditNode={onEditNode}
          />
        )
      case 'condition.splitAb':
        return (
          <SplitAbForm
            node={node as TNode<'condition.splitAb'>}
            onEditNode={onEditNode}
          />
        )
      case 'note':
        return <NoteForm node={node as TNode<'note'>} onEditNode={onEditNode} />
      case 'trigger.conversation:created':
        return (
          <NewConversationForm
            node={node as TNode<'trigger.conversation:created'>}
            onEditNode={onEditNode}
          />
        )
      case 'trigger.conversation:incomingMessage':
        return (
          <NewConversationMessageForm
            node={node as TNode<'trigger.conversation:incomingMessage'>}
            onEditNode={onEditNode}
          />
        )
      default:
        return <div>HAY UN NODO DE TIPO {node.type}</div>
    }
  }, [node, onEditNode])

  return (
    <Slide direction='right' in={!!node} mountOnEnter unmountOnExit>
      <Card className={classes.root}>
        <CardHeader
          className={classes.settingsHeader}
          avatar={
            node.properties?.icon ? (
              <MaterialIcon
                icon={node.properties.icon}
                aria-label={node.properties.title}
              />
            ) : null
          }
          title={node.properties?.title}
          subheader={node.properties?.subHeader}
        />
        <Divider />
        <CardContent className={classes.settingsContent}>
          {FormComponent}
        </CardContent>
      </Card>
    </Slide>
  )
}
