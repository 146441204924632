import React from 'react'
import { useIntl } from 'react-intl'
import Grid from '@material-ui/core/Grid'
import { TextField } from 'mui-rff'
import { FormHelperText } from '@material-ui/core'
import { IChannel } from '@il-postino/types'

type Props = {
  values: IChannel<'telegram'>
}
export default ({ values }: Props) => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.channels.telegram'

  const formFields: any[] = [
    <React.Fragment>
      <TextField
        name='params.botId'
        label={i18n({ id: `${i18nNs}.form.fields.botId.label` })}
        required={true}
      />
      <FormHelperText>
        {i18n({ id: `${i18nNs}.form.fields.botId.helperText` })}
      </FormHelperText>
    </React.Fragment>,
    <React.Fragment>
      <TextField
        name='params.botToken'
        label={i18n({ id: `${i18nNs}.form.fields.botToken.label` })}
        required={true}
      />
      <FormHelperText>
        {i18n({ id: `${i18nNs}.form.fields.botToken.helperText` })}
      </FormHelperText>
    </React.Fragment>
  ]

  return (
    <Grid container direction='column' alignContent='stretch'>
      {formFields.map((item, idx) => (
        <Grid item key={idx}>
          {item}
        </Grid>
      ))}
    </Grid>
  )
}

export const validateForm = (values: any) => {
  if (!values.params?.botId) {
    return { params: { botId: 'You must specify the ID of your Telegram bot' } }
  }
  if (!values.params?.botToken) {
    return { params: { botToken: 'You must add a token' } }
  }
  return
}
