import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid black',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2)
  }
}))

export default ({ children, onRemoveItem, readOnly, ...props }: any) => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.dashboard.module-box'

  return (
    <div {...props} className={classes.root}>
      {!readOnly && (
        <span
          className='remove'
          style={{
            position: 'absolute',
            right: '2px',
            top: 0,
            cursor: 'pointer'
          }}
          onClick={() => onRemoveItem(props.element.id)}
        >
          X
        </span>
      )}
      {props.element.id}
      {children}
    </div>
  )
}
