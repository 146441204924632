import React, { useMemo } from 'react'

import RegularNodeContainer from '../Containers/Regular'
import Chip from '@material-ui/core/Chip'
// import { makeStyles } from '@material-ui/core/styles'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'

// const useStyles = makeStyles((theme) => ({
//   root: {
//     maxWidth: 300
//     // background: 'yellow'
//   }
// }))

export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'action.webHook:post'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  // const classes = useStyles()
  const url = useMemo(() => {
    const webHookUrl = node.properties.data?.url
    return webHookUrl
      ? `${webHookUrl.slice(0, 15)}...${webHookUrl.slice(-12)}`
      : ''
  }, [node])
  return (
    <RegularNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <Chip size='small' color='secondary' label='POST' />
      <p>
        <code>{url}</code>
      </p>
    </RegularNodeContainer>
  )
}
