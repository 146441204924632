import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
    width: 56,
    fontFamily: 'Lato',
    fontStyle: 'italic',
    fontWeight: 900,
    fontSize: 24
    // lineHeight: 29
  }
})

export default function LogoIcon() {
  const classes = useStyles()
  return <div className={classes.root}>{'IP'}</div>
}
