import colors from './colors'
import fonts from './fonts'

export default {
  colors,
  fonts,
  palette: {
    type: 'dark',
    common: {
      black: 'rgba(0, 0, 0, 0.84)',
      white: '#fff',
    },
    // primary: {
    //   light: '#cbffff',
    //   main: '#99d0d3',
    //   dark: '#699fa2',
    //   contrastText: '#fff',
    // },
    // secondary: {
    //   light: '#fff64f',
    //   main: '#ffc400',
    //   dark: '#ed9600',
    //   contrastText: '#fff',
    // },
    primary: {
      light: '#90caf9',
      main: '#5d99c6',
      dark: '#266b95',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffdd4b',
      main: '#ffab00',
      dark: '#ed9600',
      contrastText: '#fff',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
  },
}
