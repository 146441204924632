import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RegularNodeContainer from '../Containers/Regular'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'
import Chip from '@material-ui/core/Chip'
import useTeams from 'src/hooks/useTeams'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 2
  }
}))

export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'action.conversation:assign'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  const classes = useStyles()
  const teams = useTeams()

  return (
    <RegularNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <Typography variant='subtitle2' color='textSecondary' component='p'>
        Assign to the teams:
      </Typography>
      {teams.length &&
        node.properties.data?.teams?.length &&
        node.properties.data?.teams?.map((teamRef) => {
          return (
            <Chip
              className={classes.chip}
              size='small'
              color='secondary'
              label={teams && teams.find((a) => a.id === teamRef.id)?.name}
            />
          )
        })}
    </RegularNodeContainer>
  )
}
