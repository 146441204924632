import React, { useCallback } from 'react'

import Icon from '@material-ui/core/Icon'

const MaterialIcon = ({ icon, ...props }) => {
  const pascalCaseToSnakeCase = useCallback(() => {
    return icon
      .replace(/(?:^|\.?)([A-Z])/g, (x, y) => {
        return '_' + y.toLowerCase()
      })
      .replace(/^_/, '')
  }, [icon])

  return <Icon {...props}>{pascalCaseToSnakeCase(icon)}</Icon>
}

export default MaterialIcon
