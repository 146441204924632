import React, { useCallback, useMemo } from 'react'
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TNode } from '@il-postino/types'
import Input from '../../Input'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

export default ({
  node,
  onEditNode
}: {
  node: TNode<'action.conversation:sendMessage'>
  onEditNode: (newNode: TNode) => void
}) => {
  const classes = useStyles()

  const initialValue = useMemo(() => {
    return (
      node.properties.data?.content &&
      convertFromRaw(node.properties.data?.content)
    )
  }, [node])

  const handleChange = useCallback(
    (contentState: ContentState) => {
      node.properties.data = { content: convertToRaw(contentState) }
      onEditNode({ ...node })
    },
    [node, onEditNode]
  )

  return (
    <div className={clsx(classes.root)}>
      <Input
        key={node.id}
        initialValue={initialValue}
        onChange={handleChange}
      />

      <Typography variant='subtitle2' color='textSecondary'>
        {JSON.stringify(node?.properties, null, 2)}
      </Typography>
    </div>
  )
}
