import React, { memo } from 'react'

// import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import ListSelector from '../../../components/ListSelector'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const PendingInvitations = ({ profile }) => {
  // const { formatMessage: i18n } = useIntl()
  const firestore = useFirestore()
  const { auth } = useSelector((state) => state.firebase)

  useFirestoreConnect([
    {
      collection: 'usersInvitations',
      where: [['email', '==', profile.email]]
    }
  ])

  const usersInvitations = useSelector(
    (state) => state.firestore.ordered.usersInvitations
  )

  const handleAcceptInvitation = (invitationId) => {
    firestore
      .collection('usersInvitations')
      .doc(invitationId)
      .update({
        status: 'accepted',
        user: firestore.doc(`users/${auth.uid}`),
        acceptedAt: firestore.FieldValue.serverTimestamp()
      })
      .catch(console.error)
  }

  return (
    <div>
      {!isLoaded(usersInvitations) ? (
        <p>Loading...</p>
      ) : isEmpty(usersInvitations) ? (
        <p>No pending invitations</p>
      ) : (
        <ListSelector
          items={usersInvitations.map((i) => {
            return {
              id: i.id,
              value: i.organization.id,
              // enabled: ['accepted', 'rejected'].includes(i.status),
              enabled: ['pending'].includes(i.status),
              primaryText: i.organization.id,
              avatarUrl: 'A',
              onClickAction: ['pending'].includes(i.status)
                ? () => handleAcceptInvitation(i.id)
                : null,
              actionIcon: ['pending'].includes(i.status) ? (
                <CheckCircleIcon />
              ) : null
            }
          })}
        />
      )}
    </div>
  )
}

export default memo(PendingInvitations)
