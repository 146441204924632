import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import useOrganization from '../../../../hooks/useOrganization'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { IIntegration } from '@il-postino/types'
import { Typography, Divider, Avatar } from '@material-ui/core'
import Form from '@rjsf/material-ui'

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginLeft: theme.spacing(5),
    width: theme.spacing(20),
    height: theme.spacing(20)
  },
  verifiedBadge: {
    marginLeft: theme.spacing(2),
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  button: {
    marginLeft: theme.spacing(3)
  }
}))

type Props = {
  menuItem?: any
  integrationId?: IIntegration['id']
  action: 'add' | 'edit'
}
export default ({ menuItem, action, integrationId }: Props) => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.integrations'

  const firestore = useFirestore()
  const history = useHistory()
  const organization = useOrganization()
  const { auth } = useSelector((state: any) => state.firebase)

  const integration: IIntegration = useSelector((state: any) => {
    if (integrationId) {
      return state.firestore.data?.integrations?.[integrationId]
    }
  })

  useFirestoreConnect([
    {
      collection: 'integrations',
      doc: integrationId
    }
  ])

  const onEditIntegration = useCallback(
    (integration) => {
      if (organization && auth) {
        // firestore
        //   .collection('integrations')
        //   .add({
        //     name: integration.name,
        //     organization: firestore.doc(
        //       `organizations/${organization.id}`
        //     ),
        //     creator: firestore.doc(`users/${auth.uid}`),
        //     enabled: true,
        //     connectionStatus: 'pending',
        //     key: integration.key,
        //     params: {}
        //   })
        //   .then((integrationRef) => {
        //     history.push(
        //       `/${organization.index}${menuItem.path}/edit/${integrationRef.id}`
        //     )
        //   })
        //   .catch(console.error)
        //   .finally(() => {})
      }
    },
    [auth, organization]
  )

  return (
    <Grid container spacing={3} className={classes.root}>
      {!isLoaded(integration) && <div>Loading...</div>}
      {isLoaded(integration) && isEmpty(integration) && (
        <div>{i18n({ id: `${i18nNs}.others.integrationNotFound` })}</div>
      )}
      {isLoaded(integration) && !isEmpty(integration) && (
        <React.Fragment>
          <Grid item xs={3}>
            <Avatar
              variant='square'
              className={classes.avatar}
              src={integration.app.avatarUrl}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant='h5'>{integration.name}</Typography>

            <Typography variant='caption'>{`${integration.app.name} by ${integration.app.organizationName}`}</Typography>
            <Typography variant='body1'>
              {integration.app.shortDescription}
            </Typography>
            <Typography variant='body2'>
              {integration.app.description}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider light />
          </Grid>
          <Grid item xs={12}>
            {action === 'edit' && (
              <Form
                schema={{
                  title: 'Todo',
                  type: 'object',
                  required: ['title'],
                  properties: {
                    title: {
                      type: 'string',
                      title: 'Title',
                      default: 'A new task'
                    },
                    done: { type: 'boolean', title: 'Done?', default: false }
                  }
                }}
              />
            )}
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  )
}
