import React, { useState, useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Avatar from '@material-ui/core/Avatar'
import * as Yup from 'yup'
import { capitalCase } from 'change-case'

import FormDialog from 'src/components/FormDialog'
import CustomTitle from './CustomTitle'
import TeamTableRow from './TeamTableRow'

import { IUserInvitation, IUser, TUserRole, ITeam } from '@il-postino/types'

import {
  TextField,
  Checkboxes,
  CheckboxData,
  makeValidate,
  Select
} from 'mui-rff'
import { IOrganizationUser } from '../index'

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableHead: {
    backgroundColor: 'lightgray'
  },
  fixedHeaderSmall: {
    width: 50
  },
  fixedHeaderMedium: {
    width: 350
  },
  checkboxes: {
    flexWrap: 'nowrap'
  },
  editUserAvatarContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  editUserAvatarImg: {
    width: 180,
    height: 180
  }
})

export type TTeamTableProps = {
  users: IOrganizationUser[]
  usersInvitations: IUserInvitation[]
  onEditUser: any
  intl: any
  teams: ITeam[]
}

export default function Row({
  users,
  usersInvitations,
  intl,
  onEditUser,
  teams
}: TTeamTableProps) {
  const classes = useRowStyles()
  const i18nNs = 'pages.teams-and-users'
  const i18n = intl
  const [dialogOpened, setDialogOpened] = useState(false)

  const [
    currentSelectedUser,
    setCurrentSelectedUser
  ] = useState<IOrganizationUser | null>(null)
  console.log('currentSelectedUser', currentSelectedUser && currentSelectedUser)

  const handleClickOpenDialog = useCallback(
    (user: IOrganizationUser) => {
      console.log('user', user)
      setCurrentSelectedUser(user)
      setDialogOpened(true)
    },
    [setDialogOpened, setCurrentSelectedUser]
  )

  const handleCloseDialog = useCallback(() => {
    setCurrentSelectedUser(null)
    setDialogOpened(false)
  }, [setDialogOpened, setCurrentSelectedUser])

  const handleDeleteUser = useCallback(() => {
    return console.log('Delete User!')
  }, [])

  async function onSubmitEditUser(values: any) {
    onEditUser(values)
  }

  const schema = Yup.object().shape({
    name: Yup.string().required('A name is required'),
    email: Yup.string()
      .required('An email is required')
      .email('Please enter a valid email'),
    roles: Yup.array().required('One or more roles must be provided'),
    teams: Yup.array().required('One or more teams must be provided')
    // available: Yup.boolean()
  })

  const initialValues = useMemo(() => {
    return {
      name: currentSelectedUser?.displayName,
      email: currentSelectedUser?.email,
      roles: currentSelectedUser ? Object.keys(currentSelectedUser.roles) : [],
      teams: currentSelectedUser?.teams
    }
  }, [currentSelectedUser])

  const validate = makeValidate(schema)
  const formFields = useMemo(() => {
    if (!currentSelectedUser) {
      return []
    }
    return [
      <div className={classes.editUserAvatarContainer}>
        <Avatar
          src={currentSelectedUser?.avatarUrl}
          className={classes.editUserAvatarImg}
        />
      </div>,
      <TextField
        name='name'
        label={i18n({
          id: `${i18nNs}.form.editUser.fields.name.placeholder`
        })}
        placeholder={i18n({
          id: `${i18nNs}.form.editUser.fields.name.placeholder`
        })}
        required={true}
        fullWidth
      />,
      <TextField
        name='email'
        label={i18n({
          id: `${i18nNs}.form.editUser.fields.email.label`
        })}
        placeholder={i18n({
          id: `${i18nNs}.form.editUser.fields.email.placeholder`
        })}
        required={true}
        fullWidth
        disabled
      />,
      <Select
        label={i18n({ id: `${i18nNs}.form.editUser.fields.roles.label` })}
        name='roles'
        required={true}
        data={Object.entries(currentSelectedUser.roles).map((r: any) => {
          return {
            label: capitalCase(r[0]),
            value: r[0]
          }
        })}
        multiple
      />,
      <Select
        label={i18n({ id: `${i18nNs}.form.editUser.fields.teams.label` })}
        name='teams'
        required={true}
        data={teams.map((team: ITeam) => {
          return {
            label: team.name,
            value: team.id
          }
        })}
        multiple
      />
      // <Checkboxes
      //   name='available'
      //   data={[
      //     {
      //       label: (
      //         <CustomTitle
      //           title={i18n({
      //             id: `${i18nNs}.form.editUser.fields.available.title`
      //           })}
      //           subtitle={i18n({
      //             id: `${i18nNs}.form.editUser.fields.available.description`
      //           })}
      //         />
      //       ),
      //       value: true
      //     }
      //   ]}
      //   formControlLabelProps={{
      //     labelPlacement: 'end'
      //   }}
      //   formGroupProps={{
      //     row: true,
      //     classes: {
      //       row: classes.checkboxes
      //     }
      //   }}
      // />
    ]
  }, [users, currentSelectedUser])

  type TColumn = {
    label: string
    size: string
    align?: any
  }

  const columns: TColumn[] = [
    { label: '', size: 'small' },
    { label: 'name', size: 'medium' },
    { label: 'role', size: 'medium' },
    { label: 'status', size: 'medium' },
    { label: '', size: 'medium', align: 'right' },
    { label: '', size: 'medium', align: 'right' }
  ]
  return (
    <Table size='small' aria-label='inner-table-header'>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {columns.map((col: TColumn) => {
            return (
              <TableCell
                aria-label={`inner-table-header-${col.label}-col.`}
                className={
                  col.size === 'medium'
                    ? classes.fixedHeaderMedium
                    : classes.fixedHeaderSmall
                }
                align={col.align ? col.align : 'left'}
              >
                {capitalCase(col.label)}
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {/* Edit User Dialog */}
        <FormDialog
          open={dialogOpened}
          onClose={handleCloseDialog}
          onSubmit={onSubmitEditUser}
          formFields={formFields}
          initialValues={initialValues}
          validate={validate}
          headline={i18n({
            id: `${i18nNs}.form.editUser.headline.editUser`
          })}
          onDelete={handleDeleteUser}
        />

        {/* Render all the rows for the users invitations */}
        {usersInvitations.map((userInvitation: IUserInvitation) => (
          <TeamTableRow
            id={userInvitation.id}
            label={'user-invitation'}
            displayName={userInvitation.email}
            roles={userInvitation.roles}
            status={userInvitation.status}
            avatarUrl={userInvitation.email}
          />
        ))}

        {/* Render all the rows for the users */}
        {users.map((user: any) => (
          <TeamTableRow
            id={user.id}
            label={'user'}
            displayName={user.displayName}
            roles={user.roles}
            status={user.status}
            avatarUrl={user.avatarUrl}
            onEditUser={() => handleClickOpenDialog(user)}
          />
        ))}
      </TableBody>
    </Table>
  )
}
