import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useOrganization from '../../../../hooks/useOrganization'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  isLoaded,
  isEmpty,
  useFirestore
} from 'react-redux-firebase'
import ProviderCard from './ProviderCard'
import { useHistory } from 'react-router-dom'

import { IIntegration } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const history = useHistory()
  const organization = useOrganization()

  const basePath = useMemo(() => {
    return organization && `/org/${organization.id}/settings/integrations`
  }, [organization])

  const integrations: IIntegration[] = useSelector(
    (state: any) => state.firestore.ordered.integrations
  )

  useFirestoreConnect(
    !organization
      ? []
      : [
          {
            collection: 'integrations',
            where: [
              [
                'organization',
                '==',
                firestore.doc(`organizations/${organization.id}`)
              ],
              ['softRemoved', '==', false]
            ]
            // orderBy: ['name', 'asc']
          }
        ]
  )

  const handleReconnectIntegration = useCallback(
    (integrationId) => {
      firestore
        .collection('integrations')
        .doc(integrationId)
        .update({ connectionStatus: 'reconnecting' })
    },
    [firestore]
  )

  const handleEditIntegration = useCallback(
    (integrationId) => {
      if (basePath) {
        history.push(`${basePath}/edit/${integrationId}`)
      }
    },
    [basePath, history]
  )

  return (
    <Grid container spacing={3} className={classes.root}>
      {!isLoaded(integrations) && <div>Loading...</div>}
      {isLoaded(integrations) && isEmpty(integrations) && (
        <div>
          No integrations added to your organization. Click "+" to add a new
          one.
        </div>
      )}
      {isLoaded(integrations) &&
        !isEmpty(integrations) &&
        integrations.map((integration, i) => {
          return (
            <Grid item xs={3} key={i}>
              <ProviderCard
                key={integration.id}
                integration={integration}
                onEditIntegration={handleEditIntegration}
                onReconnect={handleReconnectIntegration}
              />
            </Grid>
          )
        })}
    </Grid>
  )
}
