import React, { useCallback, memo } from 'react'
import { FormattedRelativeTime } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import ListItem from '@material-ui/core/ListItem'

import { IConversation } from '@il-postino/types'

import Counter from './Counter'

import AvatarWithChannel from '../AvatarWithChannel'
import ContactFullName from '../ContactFullName'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    height: 68
    // width: '100%',
    // paddingLeft: theme.spacing(2),
    // justifyContent: 'space-between'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  time: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.common.black
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'space-between'
  },
  lastMessageText: {
    fontSize: 14,
    lineHeight: '17px',
    color: `rgba(0, 0, 0, 0.54)`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0
  }
}))

interface IProps {
  conversation: IConversation
  onSelectConversation(id: string): void
}

function ConversationListItem({ conversation, onSelectConversation }: IProps) {
  const classes = useStyles()

  const { contact, lastMessage, unreadMessages, channelData } = conversation

  const handleClick = useCallback(() => onSelectConversation(conversation.id), [
    onSelectConversation,
    conversation
  ])

  const lastMessageAt = conversation?.lastMessageAt as any

  const lastMessageText = lastMessage?.content?.text

  return (
    <ListItem button className={classes.root} onClick={handleClick}>
      <AvatarWithChannel
        className={classes.avatar}
        contact={contact}
        channelDataKey={channelData?.key}
      />
      <div className={classes.content}>
        <div className={classes.row}>
          <ContactFullName contact={contact} />
          {lastMessageAt ? (
            <div className={classes.time}>
              <FormattedRelativeTime
                value={lastMessageAt.seconds - Date.now() / 1000}
                unit={'second'}
                numeric='auto'
                updateIntervalInSeconds={10}
                // eslint-disable-next-line react/style-prop-object
                style='short'
              />
            </div>
          ) : null}
        </div>
        <div className={classes.row}>
          <div className={classes.lastMessageText}>{lastMessageText}</div>
          {unreadMessages ? <Counter count={unreadMessages} /> : null}
        </div>
      </div>
    </ListItem>
  )
}

export default memo(ConversationListItem)
