import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'
import useOrganization from './useOrganization'
import { IChannel } from '@il-postino/types'

export default (): { channels: IChannel[] } => {
  const organization = useOrganization()
  const firestore = useFirestore()
  const channels: IChannel[] = useSelector(
    (state: any) => state.firestore.ordered.channels
  )

  useFirestoreConnect([
    {
      collection: 'channels',
      where: [
        [
          'organization',
          '==',
          firestore.doc(`organizations/${organization?.id}`)
        ],
        ['enabled', '==', true]
      ],
      orderBy: ['name', 'asc']
    }
  ])

  const allChannels = useMemo(() => {
    if (isLoaded(channels) && !isEmpty(channels)) {
      return channels
    } else {
      return []
    }
  }, [channels])

  return {
    channels: allChannels
  }
}
