import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'

import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'

// import GoogleButton from 'react-google-button' // optional

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  }
}))

const LoginBox = ({ menuItem }) => {
  const classes = useStyles()
  const { formatMessage: i18n } = useIntl()
  const firebase = useFirebase()
  const i18nNs = 'pages.login.login-box'

  const auth = useSelector((state) => state.firebase.auth)

  function loginWithGoogle() {
    return firebase.login({ provider: 'google', type: 'popup' })
  }

  return (
    <Paper className={classes.paper}>
      <Avatar className={classes.avatar}>
        <AccountCircleIcon />
      </Avatar>
      LOGIN
      {!isLoaded(auth) ? (
        <span>{i18n({ id: `${i18nNs}.loading` })}</span>
      ) : isEmpty(auth) ? (
        // <GoogleButton/> button can be used instead
        <Button onClick={loginWithGoogle} color='primary'>
          {i18n({ id: `${i18nNs}.login-with-google` })}
        </Button>
      ) : (
        <pre>{JSON.stringify(auth, null, 2)}</pre>
      )}
      <Grid container>
        <Grid item xs>
          {/* <Link href='#' variant='body2'> */}
          {i18n({ id: `${i18nNs}.forgot-password` })}
          {/* </Link> */}
        </Grid>
        <Grid item>
          {/* <Link href='#' variant='body2'> */}
          {i18n({ id: `${i18nNs}.sign-up` })}
          {/* </Link> */}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default LoginBox
