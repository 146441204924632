import { TNode } from 'types'

export interface INodeBuilder {
  title: string
  items: Omit<TNode, 'id' | 'position'>[]
}

const builderItems: INodeBuilder[] = [
  {
    title: 'Triggers',
    items: [
      {
        type: 'trigger',
        ports: {
          port1: {
            id: 'port1',
            type: 'right',
            properties: {
              key: 'output'
            }
          }
        },
        properties: {
          title: 'Trigger: New conversation',
          subHeader: 'When new conversation is created',
          key: 'trigger.conversation:created',
          icon: 'AddToHomeScreenIcon'
        }
      },
      {
        type: 'trigger',
        ports: {
          port1: {
            id: 'port1',
            type: 'right',
            properties: {
              key: 'output'
            }
          }
        },
        properties: {
          title: 'Trigger: New incoming message',
          subHeader: 'When new a new message is received',
          key: 'trigger.conversation:incomingMessage',
          icon: 'Message'
        }
      },
      {
        type: 'trigger',
        ports: {
          port1: {
            id: 'port1',
            type: 'right',
            properties: {
              custom: 'property'
            }
          }
        },
        properties: {
          title: 'Trigger: Contact created',
          key: 'trigger.contact:created',
          icon: 'PersonAddIcon'
        }
      }
    ]
  },

  {
    title: 'Conditions',
    items: [
      {
        type: 'condition',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {
              key: 'input'
            }
          },
          port2: {
            id: 'port2',
            type: 'right',
            properties: {
              key: 'output'
            }
          }
        },
        properties: {
          title: 'Filter',
          key: 'condition.filter',
          icon: 'FilterListIcon'
        }
      },
      {
        type: 'condition',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {
              key: 'input'
            }
          },
          port2: {
            id: 'port2',
            type: 'right',
            properties: {
              case: 'succeeded',
              key: 'tick'
            }
          },
          port3: {
            id: 'port3',
            type: 'right',
            properties: {
              case: 'failed',
              key: 'cross'
            }
          }
        },
        properties: {
          title: 'Split A/B',
          key: 'condition.splitAb',
          icon: 'CallSplitIcon'
        }
      }
    ]
  },
  {
    title: 'Actions',
    items: [
      {
        type: 'action',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {
              key: 'input'
            }
          }
        },
        properties: {
          title: 'Send message',
          // subHeader: 'Send a ',
          key: 'action.conversation:sendMessage',
          icon: 'RateReviewIcon'
        }
      },
      {
        type: 'action',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {
              key: 'input'
            }
          }
        },
        properties: {
          title: 'Assign conversation',
          // subHeader: 'Send a ',
          key: 'action.conversation:assign',
          icon: 'Directions'
        }
      },
      {
        type: 'action',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {
              key: 'input'
            }
          }
        },
        properties: {
          title: 'WebHook',
          subHeader: 'Use your own URL',
          key: 'action.webHook:post',
          icon: 'Http'
        }
      }
    ]
  },
  {
    title: 'Others',
    items: [
      {
        type: 'note',
        ports: {
          port1: {
            id: 'port1',
            type: 'left',
            properties: {}
          }
        },
        properties: {
          title: 'Note',
          key: 'note',
          icon: 'NoteIcon'
        }
      }
    ]
  }
]

export default builderItems
