import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { INodeInnerDefaultProps } from '@lucho_1312/react-flow-chart'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'

import EditIcon from '@material-ui/icons/Edit'

import MaterialIcon from '../../../../../components/MaterialIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300
  },
  nodeHeader: {
    paddingBottom: theme.spacing(1)
  },
  nodeContent: {
    paddingTop: theme.spacing(1)
  }
}))

interface CustomNodeProps extends INodeInnerDefaultProps {
  onNodeClick: (nodeId: string) => void
  children: any
}

export default ({ onNodeClick, node, children, config }: CustomNodeProps) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
      onClick={() => onNodeClick && onNodeClick(node.id)}
    >
      <CardHeader
        className={classes.nodeHeader}
        avatar={
          node.properties?.icon ? (
            <MaterialIcon
              icon={node.properties.icon}
              aria-label={node.properties.title}
            />
          ) : null
        }
        action={
          <IconButton aria-label='edit'>
            <EditIcon fontSize='small' />
          </IconButton>
        }
        title={node.properties?.title}
        subheader={node.properties?.subheader}
      />
      <Divider />
      <CardContent className={classes.nodeContent}>{children}</CardContent>
    </Card>
  )
}
