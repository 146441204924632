import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import useOrganization from './useOrganization'
import { ITeam } from 'types'

export default (): ITeam[] => {
  const organization = useOrganization()
  // const firestore = useFirestore()
  useFirestoreConnect(
    organization
      ? ([
          {
            collection: 'organizations',
            doc: organization.id,
            subcollections: [
              {
                collection: 'teams',
                where: [
                  ['softRemoved', '==', false]
                  // ['enabled', '==', true]
                ]
              }
            ],
            storeAs: 'teams',
            orderBy: ['name', 'asc']
          }
        ] as any)
      : undefined
  )

  const teams: ITeam[] = useSelector(
    (state: any) => state.firestore.ordered.teams
  )
  const allTeams = useMemo(() => {
    const allTeams: ITeam[] = []

    if (isLoaded(teams) && !isEmpty(teams)) {
      return allTeams.concat(
        teams.reduce((acc: any, t) => {
          acc.push({
            ...t
          })
          return acc
        }, [])
      )
    }
    return allTeams
  }, [teams])

  return allTeams
}
