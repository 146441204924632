import React, { Fragment, ReactNode } from 'react'

import clsx from 'clsx'

import Avatar from '@material-ui/core/Avatar'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage, IUser } from '@il-postino/types'

import ReadBy from './ReadBy'
import Retry from './Retry'

const useStyles = makeStyles((theme) => ({
  message: {
    maxWidth: '65%'
  },
  'message-left': {
    marginRight: 'auto'
  },
  'message-right': {
    marginLeft: 'auto'
  },
  avatar: {
    marginLeft: theme.spacing(1)
  }
}))

type IProps = {
  user?: IUser
  conversationFirstName?: string
  message: IMessage | any // TODO: Fix types IMessage
  children: ReactNode
}

function UserMessage({
  message,
  user,
  children,
  conversationFirstName
}: IProps) {
  const classes = useStyles()

  return (
    <Fragment>
      <div
        className={clsx(
          classes.message,
          classes[
            message.sender?.type === 'contact'
              ? 'message-left'
              : 'message-right'
          ]
        )}
      >
        {children}
        {message.failed ? (
          <Retry message={message} />
        ) : (
          <ReadBy
            message={message}
            conversationFirstName={conversationFirstName}
          />
        )}
      </div>
      {message.sender?.type !== 'contact' && user ? (
        <Avatar
          className={classes.avatar}
          alt={user.displayName}
          src={user.avatarUrl}
        />
      ) : null}
    </Fragment>
  )
}

export default UserMessage
