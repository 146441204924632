import React, { useEffect, useMemo } from 'react'
import { EditorState, ContentState } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin'
import { makeStyles } from '@material-ui/core/styles'
import 'draft-js-inline-toolbar-plugin/lib/plugin.css'

const useStyles = makeStyles((theme) => ({
  root: {},
  editor: {
    cursor: 'text',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: '#DFE1E5',
    margin: '0 auto',
    textAlign: 'left',
    width: '95%',
    minHeight: 300,
    maxHeight: 500,
    // boxShadow: '0px 0px 6px 1px rgba(0,0,0,0.5)',
    borderRadius: theme.spacing(0.2),
    overflowY: 'scroll'
  }
}))

export default ({
  initialValue,
  onChange
}: {
  initialValue?: ContentState
  onChange: (contentState: ContentState) => void
}) => {
  const classes = useStyles()

  const [editorState, setEditorState] = React.useState(
    initialValue
      ? EditorState.createWithContent(initialValue)
      : EditorState.createEmpty()
  )
  const inlineToolbarPlugin = useMemo(createInlineToolbarPlugin, [
    createInlineToolbarPlugin
  ])
  const { InlineToolbar }: any = inlineToolbarPlugin

  const editor = React.useRef(null)

  function focusEditor() {
    ;(editor.current as any)?.focus()
  }

  useEffect(() => {
    focusEditor()
  }, [])

  return (
    <div onClick={focusEditor} className={classes.editor}>
      <Editor
        ref={editor}
        editorState={editorState}
        onChange={(editorState) => {
          setEditorState(editorState)
          onChange(editorState.getCurrentContent())
        }}
        plugins={[inlineToolbarPlugin]}
      />
      <InlineToolbar />
    </div>
  )
}
