import React from 'react'

import { FormattedDate } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import { IMessage } from '@il-postino/types'

export type IMessageTypeDate = Pick<IMessage, 'id' | 'sentAt'> & {
  type: 'date'
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
    marginTop: theme.spacing(1),
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 14,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 24
  }
}))

type IProps = {
  message: IMessageTypeDate
}

function DateMessage({ message }: IProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/* TODO: FIX THE CAST BELOW */}
      <FormattedDate value={(message.sentAt as any)?.toDate()} />
    </div>
  )
}

export default DateMessage
