const colors = {
  // Main Palette
  main: {
    cerulean: '#007EA7',
    prussianBlue: '#003249',
    carolinaBlue: '#54ADD3',
    paleRobinEggBlue: '#9AD1D4',
    almostWhite: '#FCFFFD',
    gainsBoro: '#CCDBDC',
    lightSlateGray: '#738E9B',
    italianSkyBlue: '#C0FDFB',
    lightCyan: '#DAFFEF',
  },
  // Secondary Palette
  secondary: {},
  // Typography Palette
  typography: {
    porcelain: 'rgba(236, 239, 241)',
    black87: 'rgba(0, 0, 0, 0.87)',
    black54: 'rgba(0, 0, 0, 0.54)',
    black32: 'rgba(0, 0, 0, 0.32)',
    white: 'rgba(255, 255, 255)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white70: 'rgba(255, 255, 255, 0.7)',
  },
  // Random Color Labels
  randomColors: {
    whatsApp: '#25D366',
  },
  others: {
    transparent: 'transparent',
  },
}

export default colors
