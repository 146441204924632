import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Toolbar from '@material-ui/core/Toolbar'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'

import MenuItem from '@material-ui/core/MenuItem'

import { IAutomationFlow } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  toolBar: {
    position: 'absolute',
    top: theme.spacing(0),
    maxWidth: `calc(70% - ${theme.spacing(7)}px)`,
    left: theme.spacing(2)
    // zIndex: 1000
  }
}))

interface Props {
  automationFlowId?: string
  orderedAutomationFlows?: IAutomationFlow[]
  onSave: () => void
  onSelectAutomationFlowId: (automationFlowId: string) => void
  onToggleStats: () => void
}

const AutomationFlowToolBar = ({
  automationFlowId,
  orderedAutomationFlows = [],
  onSelectAutomationFlowId,
  onSave,
  onToggleStats
}: Props) => {
  const classes = useStyles()

  const handleChangeAutomationFlow = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    onSelectAutomationFlowId(event.target.value as string)
  }

  return (
    <div className={classes.toolBar}>
      <Toolbar>
        <Select
          value={automationFlowId}
          // TODO: This is not working
          inputProps={{ disableUnderline: true }}
          onChange={handleChangeAutomationFlow}
        >
          {orderedAutomationFlows.map((automationFlow) => (
            <MenuItem key={automationFlow.id} value={automationFlow.id}>
              <ListItemText primary={automationFlow.name} />
            </MenuItem>
          ))}
        </Select>
        <Button variant='contained' color='primary' onClick={onSave}>
          {'Save'}
        </Button>
        <Button variant='contained' color='secondary' onClick={onToggleStats}>
          {'Toggle stats'}
        </Button>
      </Toolbar>
    </div>
  )
}

export default AutomationFlowToolBar
