import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 18,
    minWidth: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
    fontSize: 10,
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '12px',
    // TODO: Se how to merge classes with TypeScript
    marginLeft: theme.spacing(2)
  }
}))

type IProps = {
  count: number
}

export default function Counter({ count }: IProps) {
  const classes = useStyles()
  return count ? <div className={classes.badge}>{count}</div> : null
}
