import React, { useCallback } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { TNode } from '@il-postino/types'
import useOrganization from 'src/hooks/useOrganization'
import useTeams from 'src/hooks/useTeams'
import { useFirestore } from 'react-redux-firebase'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { set as _set } from 'lodash'

import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}))
export default ({
  node,
  onEditNode
}: {
  node: TNode<'action.conversation:assign'>
  onEditNode: (newNode: TNode<'action.conversation:assign'>) => void
}) => {
  const classes = useStyles()
  const [selectedTeams, setSelectedTeams] = React.useState<string[]>(
    node.properties.data?.teams?.map((a) => a.id) || []
  )
  const firestore = useFirestore()
  const organization = useOrganization()
  const teams = useTeams()

  const handleChange = useCallback(
    (e: any) => {
      const newNode = _set(
        { ...node },
        `properties.data.${e.target.name}`,
        e.target.value.map((teamId: string) => {
          return firestore.doc(
            `organizations/${organization?.id}/teams/${teamId}`
          )
        })
      )
      onEditNode(newNode)
    },
    [node, onEditNode, firestore, organization]
  )
  const handleChangeTeams = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTeams(event.target.value as string[])
    handleChange(event)
  }

  return (
    <div className={clsx(classes.root)}>
      <FormControl className={classes.formControl}>
        <InputLabel id='demo-mutiple-chip-label'>Teams</InputLabel>
        <Select
          name='teams'
          multiple
          value={selectedTeams}
          onChange={handleChangeTeams}
          input={<Input id={`teams-${node.id}`} />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {(selected as string[]).map((value) => (
                <Chip
                  key={value}
                  label={teams && teams.find((a) => a.id === value)?.name}
                  className={classes.chip}
                />
              ))}
            </div>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250
              }
            }
          }}
        >
          {teams.map((a) => (
            <MenuItem key={a.id} value={a.id}>
              {a.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
