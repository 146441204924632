import React, { memo, useState, useCallback } from 'react'

import { useSelector } from 'react-redux'
import { useFirestore, isLoaded, isEmpty } from 'react-redux-firebase'

import { IState } from '../../types'

import ConversationHeader from './Header'
import ConversationInput from './Input'
import ConversationMessages from './Messages'

import useOrganization from '../../hooks/useOrganization'

import { makeStyles } from '@material-ui/core/styles'

type IProps = {
  conversationId: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: '1'
  }
}))

function Conversation({ conversationId }: IProps) {
  const [value, setValue] = useState('')
  const [sending, setSending] = useState(false)

  const classes = useStyles()
  const firestore = useFirestore()
  const organization = useOrganization()

  const conversation = useSelector((state: IState) => {
    return state.firestore.data?.conversations?.[conversationId]
  })

  console.log('conversation', conversation)

  const auth = useSelector((state: IState) => state?.firebase?.auth)

  const handleMarkRead = useCallback(() => {
    if (conversationId) {
      firestore.collection('conversations').doc(conversationId).update({
        unreadMessages: 0
      })
      if (isLoaded(conversation) && !isEmpty(conversation)) {
        const lastMessageAt = conversation.lastMessageAt
        const readLastAt = conversation.readAt && conversation.readAt[auth.uid]
        if (!readLastAt || lastMessageAt > readLastAt) {
          console.log('conversation.updateDelivered! TRUE')
          firestore
            .collection('conversations')
            .doc(conversationId)
            .collection('readAt')
            .doc(auth.uid)
            .set(
              {
                at: firestore.FieldValue.serverTimestamp(),
                type: 'user'
              },
              { merge: true }
            )
            .catch(console.error)
        } else {
          console.log('conversation.updateDelivered! No need to')
        }
      }
    }
  }, [conversationId, auth.uid, conversation, firestore])

  const handleSend = useCallback(() => {
    if (value && organization) {
      setSending(true)
      firestore
        .collection('conversations')
        .doc(conversationId)
        .collection('messages')
        .add({
          organization: firestore.doc(`organizations/${organization.id}`),
          creator: firestore.doc(`users/${auth.uid}`),
          conversation: firestore.doc(`conversations/${conversationId}`),
          hidden: false,
          sender: {
            id: auth.uid,
            type: 'user'
          },
          recipient: {
            id: conversation?.contact.id.id,
            ...(conversation?.contact.externalId
              ? { externalId: conversation?.contact.externalId }
              : {}),
            type: 'contact'
          },
          status: 'pending',
          sentAt: firestore.FieldValue.serverTimestamp(),
          direction: 'outbound',
          type: 'text',
          content: {
            text: value
          }
        })
        .then((data) => {
          setSending(false)
          setValue('')
        })
        .catch((error) => {
          console.error(error)
          setSending(false)
        })
    }
  }, [organization, auth.uid, conversation, conversationId, firestore, value])

  return (
    <div className={classes.root} onClick={handleMarkRead}>
      {isLoaded(conversation) && organization ? (
        <>
          <ConversationHeader
            contact={conversation?.contact}
            channelDataKey={conversation?.channelData?.key}
          />
          <ConversationMessages
            // I use the key to refresh the whole conversation
            key={conversationId}
            lastMessageAt={conversation.lastMessageAt}
            organizationId={organization.id}
            conversationId={conversationId}
            conversationFirstName={conversation.contact.firstName}
          />
          <ConversationInput
            disabled={sending}
            value={value}
            onChange={setValue}
            onSend={handleSend}
          />
        </>
      ) : null}
    </div>
  )
}

export default memo(Conversation)
