import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage, IUser } from '@il-postino/types'

import BubbleMessage from './components/Bubble'
import UserMessage from './components/UserMessage'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.8, 1),
    wordBreak: 'break-word'
  },
  spacer: {
    display: 'inline-block',
    width: 70
  }
}))

type IProps = {
  user?: IUser
  conversationFirstName?: string
  message: IMessage
}

function TextMessage({ message, user, conversationFirstName }: IProps) {
  const classes = useStyles()

  return (
    <UserMessage
      message={message}
      user={user}
      conversationFirstName={conversationFirstName}
    >
      <BubbleMessage message={message}>
        <div className={classes.root}>
          {message.content.text}
          <span className={classes.spacer} />
        </div>
      </BubbleMessage>
    </UserMessage>
  )
}

export default TextMessage
