import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { IContact } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '19px',
    textTransform: 'capitalize',
    color: theme.palette.common.black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

interface IProps {
  contact: IContact
}

export default function ContactFullName({ contact }: IProps) {
  const classes = useStyles()

  const fullName = `${contact?.firstName || ''} ${contact?.lastName || ''}`

  return <div className={classes.root}>{fullName}</div>
}
