import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { Basic as Layout } from '../../../layouts'

import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import { TextField, Select } from 'mui-rff'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { ICustomField, TCustomFieldEntity } from '@il-postino/types'
import FormDialog from '../../../components/FormDialog'
import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import { camelCase } from 'change-case'

import SettingsSubdrawer from '../components/SettingsSubdrawer'
import UnarchivedCustomFields from './components/UnarchivedCustomFields'
import ArchivedCustomFields from './components/ArchivedCustomFields'
import createDecorator from 'final-form-calculate'

import useOrganization from '../../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: TCustomFieldEntity
  selectedTab: TCustomFieldEntity
}
function TabPanel(props: TabPanelProps) {
  const { children, selectedTab, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={selectedTab !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {selectedTab === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

type FormData = any

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.custom-fields'
  const organization = useOrganization()

  const [selectedEntityTab, setSelectedEntityTab] = React.useState<
    TCustomFieldEntity
  >('contact')

  const [dialogOpened, setDialogOpened] = React.useState(false)
  const [editableRow, setEditableRow] = React.useState<
    ICustomField | undefined
  >()

  useFirestoreConnect(
    organization
      ? [
          {
            collection: 'organizations',
            doc: organization.id,
            subcollections: [
              {
                collection: 'customFields',
                where: [['softRemoved', '==', false]]
              }
            ],
            storeAs: 'customFields'
          }
        ]
      : []
  )

  const {
    customFields
  }: {
    customFields: ICustomField[]
  } = useSelector((state: any) => state.firestore.ordered)

  const handleClickOpenDialog = useCallback(() => {
    setDialogOpened(true)
  }, [setDialogOpened])

  const handleCloseDialog = useCallback(() => {
    setEditableRow(undefined)
    setDialogOpened(false)
  }, [setDialogOpened])

  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<{}>, newValue: TCustomFieldEntity) => {
      setSelectedEntityTab(newValue)
    },
    [setSelectedEntityTab]
  )

  async function onSubmitNewCustomField(values: ICustomField) {
    // loader.start()

    const customFieldSubcollection = firestore
      .collection('organizations')
      .doc(organization?.id)
      .collection('customFields')

    if (values.id) {
      customFieldSubcollection
        .doc(values.id)
        .update(values)
        .then(() => {
          setEditableRow(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    } else {
      setEditableRow(values)
      customFieldSubcollection
        .add(values)
        .then(() => {
          setEditableRow(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    }
  }

  const handleEdit = useCallback(
    (id: ICustomField['id']) => {
      if (isLoaded(customFields) && !isEmpty(customFields)) {
        setEditableRow(customFields?.find((cF) => cF.id === id))
        setDialogOpened(true)
      }
    },
    [customFields, setEditableRow]
  )

  const handleArchive = useCallback(
    (ids: ICustomField['id'][]) => {
      for (const id of ids) {
        firestore
          .doc(`organizations/${organization?.id}/customFields/${id}`)
          .update({ archived: true })
      }
    },
    [firestore, organization]
  )

  const handleUnarchive = useCallback(
    (ids: ICustomField['id'][]) => {
      for (const id of ids) {
        firestore
          .doc(`organizations/${organization?.id}/customFields/${id}`)
          .update({ archived: false })
      }
    },
    [firestore, organization]
  )

  const handleDelete = useCallback(
    (ids: ICustomField['id'][]) => {
      for (const id of ids) {
        firestore
          .doc(`organizations/${organization?.id}/customFields/${id}`)
          .update({ softRemoved: true })
      }
    },
    [firestore, organization]
  )

  async function validate(values: ICustomField) {
    const validation: any = {}
    if (!values.name) {
      validation['name'] = 'A field name is required'
    }
    if (!values.type) {
      validation['type'] = 'A field type is required'
    }
    return validation
  }

  const initialValues: Partial<ICustomField> = useMemo(() => {
    return (
      editableRow || {
        archived: false,
        entity: selectedEntityTab,
        softRemoved: false
      }
    )
  }, [selectedEntityTab, editableRow])

  const formFields: any[] = [
    <TextField
      name='name'
      label={i18n({ id: `${i18nNs}.form.fields.name.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.name.helper-text` })}
      required={true}
    />,
    <TextField
      name='key'
      label={i18n({ id: `${i18nNs}.form.fields.key.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.key.helper-text` })}
      required={true}
    />,
    <Select
      label={i18n({ id: `${i18nNs}.form.fields.type.label` })}
      name='type'
      required={true}
      data={[
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.select-value` }),
          value: '',
          disabled: true
        },
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.text` }),
          value: 'text'
        },
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.number` }),
          value: 'number'
        },
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.date` }),
          value: 'date'
        },
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.date-time` }),
          value: 'dateTime'
        },
        {
          label: i18n({ id: `${i18nNs}.form.fields.type.values.boolean` }),
          value: 'boolean'
        }
      ]}
      helperText={i18n({ id: `${i18nNs}.form.fields.type.helper-text` })}
    />,
    <TextField
      name='description'
      label={i18n({ id: `${i18nNs}.form.fields.description.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.description.helper-text` })}
      required={false}
    />
  ]

  const calculatedFieldsDecorator = createDecorator({
    field: /name/, // when a field matching this pattern changes...
    updates: {
      key: (ignoredValue: any, allValues: any) => {
        return camelCase(allValues.name || '')
      }
    }
  })

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      topBarActions={
        <IconButton onClick={handleClickOpenDialog}>
          <AddIcon />
        </IconButton>
      }
    >
      <FormDialog
        open={dialogOpened}
        onClose={handleCloseDialog}
        onSubmit={onSubmitNewCustomField}
        formFields={formFields}
        initialValues={initialValues}
        validate={validate}
        decorators={[calculatedFieldsDecorator]}
        headline={i18n({ id: `${i18nNs}.form.headline.${selectedEntityTab}` })}
      />
      <Container className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Tabs
              value={selectedEntityTab}
              onChange={handleChangeTab}
              aria-label='simple tabs example'
            >
              <Tab
                value='contact'
                label={i18n({ id: `${i18nNs}.tabs.contacts` })}
              />
              <Tab value='user' label={i18n({ id: `${i18nNs}.tabs.users` })} />
            </Tabs>
            {!isLoaded(customFields) && 'Loading custom fields...'}
            {isLoaded(customFields) && (
              <React.Fragment>
                {(['contact', 'user'] as TCustomFieldEntity[]).map(
                  (tabEntityType: TCustomFieldEntity) => {
                    return (
                      <TabPanel
                        selectedTab={selectedEntityTab}
                        index={tabEntityType}
                        key={tabEntityType}
                      >
                        <UnarchivedCustomFields
                          entity={tabEntityType}
                          onArchive={handleArchive}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                          rows={
                            isEmpty(customFields)
                              ? []
                              : customFields.filter((r: ICustomField) => {
                                  return (
                                    r.entity === tabEntityType && !r.archived
                                  )
                                })
                          }
                        />
                        <ArchivedCustomFields
                          entity={tabEntityType}
                          onUnarchive={handleUnarchive}
                          onEdit={handleEdit}
                          onDelete={handleDelete}
                          rows={
                            isEmpty(customFields)
                              ? []
                              : customFields.filter((r: ICustomField) => {
                                  return (
                                    r.entity === tabEntityType && r.archived
                                  )
                                })
                          }
                        />
                      </TabPanel>
                    )
                  }
                )}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
