import React, { memo } from 'react'

import AvatarWithChannel from '../AvatarWithChannel'
import ContactFullName from '../ContactFullName'

import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'
import HelpRoundedIcon from '@material-ui/icons/HelpRounded'
import IconButton from '@material-ui/core/IconButton'

import { makeStyles } from '@material-ui/core/styles'

import { TChannelKey, IContact } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 56,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    backgroundColor: theme.palette.common.white
  },
  contact: {
    flex: '1'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}))

type IProps = {
  contact: IContact
  channelDataKey?: TChannelKey
}

function ConversationHeader({ contact, channelDataKey }: IProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <AvatarWithChannel
        className={classes.avatar}
        contact={contact}
        channelDataKey={channelDataKey}
      />
      <div className={classes.contact}>
        <ContactFullName contact={contact} />
      </div>
      <IconButton>
        <HelpRoundedIcon />
      </IconButton>
      <IconButton>
        <MoreVertRoundedIcon />
      </IconButton>
    </div>
  )
}

export default memo(ConversationHeader)
