import React, { useState, useCallback, useMemo } from 'react'
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme
} from '@material-ui/core/styles'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'

import BadgeAvatar from './BadgeAvatar'
import StyledLabel from './StyledLabel'
import { IUserInvitation } from '@il-postino/types'

export type TTeamTableRowProps = {
  id: string
  displayName: string
  label: string
  roles: IUserInvitation['roles']
  status: string
  avatarUrl: string
  onEditUser?: any
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
      }
    }
  })
)(TableRow)

const useRowStyles = makeStyles({
  innerTableAvatarCell: {
    padding: 0
  }
})

const getRole = (roles: IUserInvitation['roles']): string => {
  if (roles.manager && roles.owner) {
    return 'Admin'
  } else {
    return 'Employee'
  }
}

export default function TeamTableRow(props: TTeamTableRowProps) {
  const { id, avatarUrl, status, displayName, roles, onEditUser, label } = props
  const classes = useRowStyles()
  return (
    <StyledTableRow key={id} aria-label={`inner-table-${label}`}>
      <TableCell
        className={classes.innerTableAvatarCell}
        component='th'
        scope='row'
      >
        <BadgeAvatar src={avatarUrl} status={status} />
      </TableCell>
      <TableCell aria-label={`inner-table-${label}-displayname`}>
        <b>{displayName}</b>
      </TableCell>
      <TableCell aria-label={`inner-table-${label}-roles`}>
        {getRole(roles)}
      </TableCell>
      <TableCell aria-label={`inner-table-${label}-status`}>
        <StyledLabel status={status || 'Without Status'} />
      </TableCell>
      <TableCell aria-label='inner-table-empty'></TableCell>
      <TableCell align='right' aria-label={`inner-table-${label}-actions`}>
        <IconButton>
          <EditIcon onClick={onEditUser} />
        </IconButton>
        <IconButton>
          <MoreVertRoundedIcon />
        </IconButton>
      </TableCell>
    </StyledTableRow>
  )
}
