import React from 'react'

type Props = {
  title: string
  subtitle: string
}

export default ({ title, subtitle }: Props) => {
  return (
    <div>
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  )
}
