import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import clsx from 'clsx'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

import IconButton from '@material-ui/core/IconButton'
import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'

import { Basic as Layout } from '../../layouts'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded'

import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { ITeam, IUser, IUserInvitation } from '@il-postino/types'

import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from './components/SettingsSubdrawer'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  table: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableHead: {
    backgroundColor: 'lightgray'
  },
  teamAvatars: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default () => {
  const classes = useStyles()

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.settings'

  return (
    <Layout subDrawer={<SettingsSubdrawer />}>
      <Container className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            Settings page
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
