import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import { Form } from 'react-final-form'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    marginRight: 'auto',
    color: '#F44336'
  }
}))

type Props = {
  headline: string
  contextText?: string
  open: boolean
  onClose: any
  onSubmit: any
  onDelete?: any
  initialValues: any
  formFields: any
  validate: any
  decorators?: any
}
export default ({
  headline,
  contextText,
  open,
  onClose,
  onSubmit,
  initialValues,
  formFields,
  validate,
  decorators,
  onDelete
}: Props) => {
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'components.form-dialog'
  const classes = useStyles()

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      decorators={decorators || []}
      render={({ handleSubmit, values }) => {
        return (
          <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby='form-dialog-title'
          >
            <form onSubmit={handleSubmit} noValidate>
              <DialogTitle id='form-dialog-title'>{headline}</DialogTitle>
              <DialogContent>
                {contextText && (
                  <DialogContentText>{contextText}</DialogContentText>
                )}

                {formFields.map((item: any, idx: number) => item)}
                <pre>{JSON.stringify(values, null, 2)}</pre>
              </DialogContent>

              <DialogActions>
                {onDelete && (
                  <Button
                    onClick={onDelete}
                    color='primary'
                    className={classes.deleteButton}
                  >
                    <FormattedMessage id={`${i18nNs}.buttons.delete`} />
                  </Button>
                )}
                <Button onClick={onClose} color='primary'>
                  {i18n({ id: `${i18nNs}.buttons.cancel` })}
                </Button>
                <Button type='submit' color='primary'>
                  {i18n({ id: `${i18nNs}.buttons.submit` })}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        )
      }}
    />
  )
}
