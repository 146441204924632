import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import DashboardModuleBox from '../components/DashboardModuleBox'

import { Textfit } from 'react-textfit'

import { useCountUp } from 'react-countup'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  }
}))

export default (props: any) => {
  const classes = useStyles()
  const { countUp } = useCountUp({ end: 1234567 })
  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.dashboard.modules.conversations-by-channel'

  return (
    <DashboardModuleBox {...props}>
      <div className={classes.root}>
        <span>
          <Textfit max={250} mode='single'>
            {countUp}
          </Textfit>
        </span>
        <span>
          <Textfit max={250} mode='single'>
            Active conversations {props.readOnly}
          </Textfit>
        </span>
      </div>
    </DashboardModuleBox>
  )
}
