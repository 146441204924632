import { createMuiTheme } from '@material-ui/core/styles'

import colors from './colors'
import fonts from './fonts'

import lightTheme from './themeLight'
import darkTheme from './themeDark'

export default (type = 'light') => {
  const theme = type === 'light' ? lightTheme : darkTheme
  return createMuiTheme({
    colors,
    fonts,
    ...theme
  })
}
