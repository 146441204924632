import React from 'react'

import { FormattedMessage } from 'react-intl'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import { IMessage } from '@il-postino/types'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 10
  }
}))

type IProps = {
  message: IMessage | any // TODO: Fix types IMessage
  conversationFirstName?: string
}

function ReadBy({ message, conversationFirstName }: IProps) {
  const classes = useStyles()

  const { readAt, recipient } = message

  let names = [] as string[]

  if (readAt && readAt[recipient?.externalId] && conversationFirstName) {
    names = [conversationFirstName]
  }

  return names.length ? (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      {names.length === 1 ? (
        <FormattedMessage
          id={'components.conversation.message.ReadBy'}
          values={{
            name: names[0]
          }}
        />
      ) : names.length === 2 ? (
        <FormattedMessage
          id={'components.conversation.message.ReadByAnd'}
          values={{
            name1: names[0],
            name2: names[0]
          }}
        />
      ) : (
        <FormattedMessage
          id={'components.conversation.message.ReadByAndMore'}
          values={{
            names: names.join(','),
            count: names.length - 2
          }}
        />
      )}
    </div>
  ) : null
}

export default ReadBy
