// import moment from 'moment'
import { IMessage } from '@il-postino/types'
import { IConversationMessage } from './index'

function orderAndIntersperseMessages(items: IMessage[] = []) {
  return items.reduce(
    (acc: IConversationMessage[], item: IMessage, index: number) => {
      // const prevItem = acc[acc.length - 1]

      // const prevDate = prevItem && moment(prevItem?.sentAt?.toDate()) // 5
      // const date = moment(item.sentAt?.toDate()) // 4

      acc.unshift(item)

      // if (
      //   prevDate &&
      //   prevDate.diff(date, 'days') > 0 &&
      //   prevItem.type !== 'date'
      // ) {
      //   acc.unshift({
      //     id: prevDate.toString(),
      //     type: 'date',
      //     sentAt: prevItem.sentAt
      //   })
      // }

      // First date
      if (items.length - 1 === index && item.sentAt) {
        acc.unshift({
          id: item.sentAt?.toString(),
          type: 'date',
          sentAt: item.sentAt
        })
      }

      return acc
    },
    []
  )
}

export { orderAndIntersperseMessages }
