import React, { useCallback, useMemo, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  SortableElementProps,
  SortableContainerProps
} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { sortBy as _sortBy } from 'lodash'
import { Basic as Layout } from '../../../layouts'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import { TextField } from 'mui-rff'
import { ITag } from '@il-postino/types'
import FormDialog from '../../../components/FormDialog'
import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from '../components/SettingsSubdrawer'

import useOrganization from '../../../hooks/useOrganization'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  table: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableHead: {
    backgroundColor: 'lightgray'
  },
  teamAvatars: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export default () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const [dialogOpened, setDialogOpened] = React.useState(false)
  const [editableTag, setEditableTag] = React.useState<ITag | undefined>()
  const [orderedTags, setOrderedTags] = React.useState<ITag[]>([])

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.tags'
  const organization = useOrganization()

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organization?.id,
      subcollections: [
        {
          collection: 'tags',
          where: [['softRemoved', '==', false]]
        }
      ],
      storeAs: 'tags'
    }
  ])

  const tags: ITag[] = useSelector((state: any) => {
    return state.firestore.ordered.tags
  })

  useEffect(() => {
    if (isLoaded(tags) && !isEmpty(tags)) {
      setOrderedTags(_sortBy(tags, 'order'))
    }
  }, [tags, setOrderedTags])

  const DragHandle = SortableHandle(() => <DragHandleIcon />)

  const SortableItem = SortableElement(({ tag }: { tag: ITag }) => (
    <ListItem
      style={{
        backgroundColor: `#${tag.color}`
      }}
    >
      <ListItemIcon>
        <DragHandle />
      </ListItemIcon>
      <ListItemText primary={tag.name} />
      <ListItemSecondaryAction>
        <IconButton
          edge='end'
          aria-label='edit'
          onClick={() => handleEdit(tag.id)}
        >
          <EditIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  ))

  const SortableList = SortableContainer(
    ({ tags }: SortableContainerProps & { tags: ITag[] }) => {
      return (
        <List>
          {tags.map((value, index: number) => (
            <SortableItem
              key={`item-${value.id}`}
              index={value.order}
              tag={value}
            />
          ))}
        </List>
      )
    }
  )

  const onSortEnd = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (isLoaded(tags) && !isEmpty(tags)) {
        // loader.start()

        const newOrder = arrayMove(
          _sortBy(tags, 'order').map((t) => t.id),
          oldIndex,
          newIndex
        )

        // Update the DB
        const newOrderedTags: ITag[] = []
        const writeBatch = firestore.batch()
        newOrder.map((id, index) => {
          const tag = tags.find((t) => t.id === id)
          if (tag) {
            newOrderedTags.push(tag)
            if (tag.order !== index) {
              const tagRef = firestore.doc(
                `/organizations/${organization?.id}/tags/${tag.id}`
              )
              writeBatch.update(tagRef, { order: index })
            }
          }
        })
        setOrderedTags(newOrderedTags)
        await writeBatch.commit()
        //loader.complete()
      }
    },
    [firestore, organization, tags]
  )

  const handleEdit = useCallback(
    (id: ITag['id']) => {
      if (isLoaded(tags) && !isEmpty(tags)) {
        setEditableTag(tags?.find((t) => t.id === id))
        setDialogOpened(true)
      }
    },
    [tags, setEditableTag]
  )

  async function onSubmitNewTag(values: ITag) {
    // loader.start()

    const tagSubcollection = firestore
      .collection('organizations')
      .doc(organization?.id)
      .collection('tags')

    if (values.id) {
      tagSubcollection
        .doc(values.id)
        .update(values)
        .then(() => {
          setEditableTag(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    } else {
      setEditableTag(values)
      tagSubcollection
        .add(values)
        .then(() => {
          setEditableTag(undefined)
          handleCloseDialog()
        })
        .catch(alert)
        .finally(() => {
          // loader.complete()
        })
    }
  }

  const handleClickOpenDialog = useCallback(() => {
    setDialogOpened(true)
  }, [setDialogOpened])

  const handleCloseDialog = useCallback(() => {
    setEditableTag(undefined)
    setDialogOpened(false)
  }, [setDialogOpened])

  async function validate(values: ITag) {
    const validation: any = {}
    if (!values.name) {
      validation['name'] = 'A field name is required'
    }
    return validation
  }

  const initialValues: Partial<ITag> = useMemo(() => {
    return (
      editableTag || {
        softRemoved: false
      }
    )
  }, [editableTag])

  const formFields: any[] = [
    <TextField
      key='name'
      name='name'
      label={i18n({ id: `${i18nNs}.form.fields.name.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.name.helper-text` })}
      required={true}
    />,
    <TextField
      key='color'
      name='color'
      label={i18n({ id: `${i18nNs}.form.fields.color.label` })}
      helperText={i18n({ id: `${i18nNs}.form.fields.color.helper-text` })}
      required={true}
    />
  ]

  return (
    <Layout
      subDrawer={<SettingsSubdrawer />}
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      topBarActions={
        <IconButton onClick={handleClickOpenDialog}>
          <AddIcon />
        </IconButton>
      }
    >
      <FormDialog
        open={dialogOpened}
        onClose={handleCloseDialog}
        onSubmit={onSubmitNewTag}
        formFields={formFields}
        initialValues={initialValues}
        validate={validate}
        headline={i18n({ id: `${i18nNs}.form.headline.create` })}
      />
      <Container className={classes.root}>
        <Grid container spacing={0}>
          {/* Users list */}
          <Grid item xs={12}>
            {!isLoaded(tags) && 'Loading...'}
            {isLoaded(tags) && isEmpty(tags) && 'No tags found'}
            {isLoaded(tags) && !isEmpty(tags) && (
              <SortableList
                onSortEnd={onSortEnd}
                useDragHandle
                tags={orderedTags}
                lockAxis='y'
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
