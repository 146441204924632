import * as React from 'react'

// Redux
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

// Firebase
import firebaseConfig from './firebaseConfig'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore' // <- needed if using firestore
// import 'firebase/database' // <- needed if using real time database
// import 'firebase/functions' // <- needed if using httpsCallable

// react-redux-firebase
import { ReactReduxFirebaseProvider, getFirebase } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore' // <- needed if using firestore

// Reducers
import rootReducer from '../reducers'

// Plugins / Integrations
import thunk from 'redux-thunk'

// redux-persist
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

// Redux thunk
const middlewares = [thunk.withExtraArgument(getFirebase)]

// Initialize firebase and firebase services
firebase.initializeApp(firebaseConfig)
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store)

const profilePopulates = [{ child: 'organization', root: 'organization' }] // How do I implement this? http://react-redux-firebase.com/docs/populate.html

// Setup RRF props
let userRef = null
const rrfProps = {
  firebase,
  config: {
    // allowMultipleListeners: true,
    preserveOnLogin: {
      auth: true,
      profile: true
    }, // https://github.com/prescottprue/react-redux-firebase/issues/738
    userProfile: 'users', // http://react-redux-firebase.com/docs/recipes/profile.html
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB -> http://react-redux-firebase.com/docs/firestore.html#profile
    onAuthStateChanged: (user) => {
      console.log('userTokenClaim', user)

      // Remove previous listener.
      if (userRef) {
        userRef()
      }
      // On user login add new listener.
      if (user) {
        // Check if refresh is required.
        userRef = firebase
          .firestore()
          .doc(`users/${user.uid}`)
          .onSnapshot((snapshot) => {
            if (snapshot.get('refreshToken')) {
              // Force refresh to pick up the latest custom claims changes.
              // Note this is always triggered on first call. Further optimization could be
              // added to avoid the initial trigger when the token is issued and already contains
              // the latest claims.

              // https://firebase.google.com/docs/reference/js/firebase.User#getidtoken
              user.getIdToken(true)
              // https://firebase.google.com/docs/reference/js/firebase.User#reload
              user.reload().then(console.info)
              console.info('userTokenClaim', 'has changed')
            }
          })
      }
    },
    // profileFactory: ({ organizations, ...userData }, profileData, firebase) => {
    //   // Defines how profiles are stored in database
    //   // console.log('++++ userData', userData)
    //   console.info('++++ profileData organizations', organizations)
    //   console.info('++++ profileData userData', userData)
    //   console.info('++++ profileData profileData', profileData)
    //   const profile = {
    //     ...profileData,
    //     organizations: Object.entries(organizations).reduce((acc, data) => {
    //       const organizationId = data[0]
    //       const { teams, ...organizationData } = data[1]
    //       acc[organizationId] = { ...organizationData, teams: teams.map(a => a.id) }
    //     }, {})
    //   }
    //   console.info('Da profile', profile)
    //   return profile
    // },
    populatedProfile: profilePopulates
  },
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

export default ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {children}
        </ReactReduxFirebaseProvider>
      </PersistGate>
    </Provider>
  )
}
