import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import RegularNodeContainer from '../Containers/Regular'
import { IConfig } from '@lucho_1312/react-flow-chart'
import { TNode } from '@il-postino/types'
import Chip from '@material-ui/core/Chip'
import useTeams from 'src/hooks/useTeams'
import useChannels from 'src/hooks/useChannels'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: 2
  }
}))

export default ({
  node,
  config,
  onNodeClick
}: {
  node: TNode<'trigger.conversation:incomingMessage'>
  config: IConfig
  onNodeClick: (nodeId: string) => void
}) => {
  const classes = useStyles()
  const teams = useTeams()
  const { channels } = useChannels()

  return (
    <RegularNodeContainer onNodeClick={onNodeClick} node={node} config={config}>
      <Typography variant='subtitle2' color='textSecondary' component='p'>
        When the team is any of:
      </Typography>
      {teams.length &&
        node.properties.data?.teams?.length &&
        node.properties.data?.teams?.map((teamRef) => {
          return (
            <Chip
              className={classes.chip}
              size='small'
              color='secondary'
              label={teams && teams.find((a) => a.id === teamRef.id)?.name}
            />
          )
        })}
      <Typography variant='subtitle2' color='textSecondary' component='p'>
        and the channel is:
      </Typography>
      <Chip
        size='small'
        color='secondary'
        label={
          channels.find((c) => c.id === node.properties.data?.channel?.id)
            ?.name || node.properties.data?.channel?.id
        }
      />
    </RegularNodeContainer>
  )
}
