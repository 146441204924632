import React, { useState, useMemo, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'

import IconButton from '@material-ui/core/IconButton'
// import MoreVertRoundedIcon from '@material-ui/icons/MoreVertRounded'

import { Basic as Layout } from '../../../layouts'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
// import LinkIcon from '@material-ui/icons/Link'

import {
  TextField,
  Checkboxes,
  CheckboxData,
  makeValidate,
  Select
} from 'mui-rff'
// import Button from '@material-ui/core/Button'
import Skeleton from '@material-ui/lab/Skeleton'

import TeamTable from './components/TeamTable'
import GroupedAvatars from './components/GroupedAvatars'
import CustomTitle from './components/CustomTitle'

// import { Autocomplete } from 'mui-rff'
import * as Yup from 'yup'

import {
  ITeam,
  IUser,
  IUserInvitation,
  TUserOrganization,
  TUserRole
} from '@il-postino/types'

import { useSelector } from 'react-redux'
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import SettingsSubdrawer from '../components/SettingsSubdrawer'
import TableActions from './components/TableActions'
import FormDialog from 'src/components/FormDialog'

import useOrganization from '../../../hooks/useOrganization'

import { IState } from '../../../types'

interface FilmOptionType {
  inputValue?: string
  title: string
  value?: number | string
}

export interface IOrganizationUser {
  id: IUser['id']
  avatarUrl: IUser['avatarUrl']
  displayName: IUser['displayName']
  email: IUser['email']
  roles: TUserOrganization['roles']
  status: IUser['status']
  teams: TUserOrganization['teams']
}

export interface ITeamWithUsers extends ITeam {
  users: IOrganizationUser[]
  usersInvitations: IUserInvitation[]
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ECEFF1'
  },
  table: {
    '& > *': {
      borderBottom: 'unset'
    }
  },
  tableHead: {
    backgroundColor: 'lightgray'
  },
  teamAvatars: {
    display: 'flex',
    alignItems: 'center'
  },
  teamName: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 15
  },
  inviteButton: {
    color: 'white',
    backgroundColor: '#00BCD4'
  },
  skeletonRect: {
    width: '100%',
    height: '50px',
    marginBottom: theme.spacing(1)
  },
  checkboxes: {
    flexWrap: 'nowrap'
  }
}))

const UsersPage = () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const organization = useOrganization()

  const [selectedIndex, setSelectedIndex] = useState('')
  const [dialogOpened, setDialogOpened] = useState('')

  const [currentSelectedTeam, setCurrentSelectedTeam] = useState<
    ITeam | undefined
  >()

  const { auth } = useSelector((state: IState) => state.firebase)

  useFirestoreConnect([
    {
      collection: 'organizations',
      doc: organization?.id,
      subcollections: [
        {
          collection: 'teams',
          where: [['softRemoved', '==', false]]
        }
      ],
      storeAs: 'teams',
      orderBy: ['name', 'asc']
    },
    {
      collection: 'users',
      where: [[`organizations.${organization?.id}.id`, '==', organization?.id]]
    },
    {
      collection: 'usersInvitations',
      where: [
        [
          'organization',
          '==',
          firestore.doc(`organizations/${organization?.id}`)
        ],
        ['status', '==', 'pending']
      ],
      orderBy: ['email', 'asc']
    }
  ])

  const {
    users,
    usersInvitations,
    teams
  }: {
    users: IUser[]
    usersInvitations: IUserInvitation[]
    teams: ITeam[]
  } = useSelector((state: any) => state.firestore.ordered)

  const handleOpenTeam = (index: string) => {
    // We handle the index as a string, because if it were a number,
    // the first element of the list (Element Zero: 0) would be a falsy value

    /**With this, we close the accordion */
    if (selectedIndex === index) {
      setSelectedIndex('')
    } else {
      /**With this, we open the accordion */
      setSelectedIndex(index)
    }
  }

  const handleClickOpenInviteUsersDialog = useCallback(
    (teamId: ITeam['id']) => {
      if (isLoaded(teams) && !isEmpty(teams)) {
        setCurrentSelectedTeam(teams.find((t) => t.id === teamId))
        setDialogOpened('inviteUserDialog')
      }
    },
    [teams]
  )
  const handleClickOpenEditTeamDialog = useCallback(
    (teamId: ITeam['id']) => {
      if (isLoaded(teams) && !isEmpty(teams)) {
        setCurrentSelectedTeam(teams.find((t) => t.id === teamId))
        setDialogOpened('createTeam')
      }
    },
    [teams]
  )

  const handleCloseDialog = useCallback(() => {
    setCurrentSelectedTeam(undefined)
    setDialogOpened('')
  }, [setDialogOpened, setCurrentSelectedTeam])

  const handleClickOpenCreateTeamDialog = useCallback(() => {
    setDialogOpened('createTeam')
  }, [setDialogOpened])

  const { formatMessage: i18n } = useIntl()
  const i18nNs = 'pages.teams-and-users'

  const isReady = useMemo(() => {
    return (
      isLoaded(users) &&
      isLoaded(usersInvitations) &&
      isLoaded(teams) &&
      !isEmpty(teams)
    )
  }, [teams, users, usersInvitations])

  const teamsAndUsers = useMemo(() => {
    if (isReady && organization) {
      return teams.reduce((acc: ITeamWithUsers[], team) => {
        const teamUsers = users
          .filter((u) =>
            u.organizations[organization.id].teams?.includes(team.id)
          )
          .map((u) => {
            return {
              id: u.id,
              avatarUrl: u.avatarUrl,
              displayName: u.displayName,
              email: u.email,
              roles: u.organizations[organization.id].roles,
              status: u.status,
              teams: u.organizations[organization.id].teams
            }
          })
        acc.push({
          ...team,
          users: teamUsers,
          usersInvitations: usersInvitations?.filter((userInvitation) =>
            userInvitation.teams.includes(team.id)
          )
        })
        return acc
      }, [])
    }
    return []
  }, [isReady, organization, teams, users, usersInvitations])

  const checkboxesRolesData: CheckboxData[] = [
    'agent',
    'owner',
    'manager',
    'developer'
  ].map((role: string) => {
    return {
      label: (
        <CustomTitle
          title={i18n({
            id: `${i18nNs}.form.inviteUsers.fields.roles.checkboxes.${role}-title`
          })}
          subtitle={i18n({
            id: `${i18nNs}.form.inviteUsers.fields.roles.checkboxes.${role}-description`
          })}
        />
      ),
      value: role
    }
  })

  /**
   * SUBMIT ACTIONS
   */

  async function onSubmitEditUser(values: any) {
    console.log('Editando desde el Padre', values)

    // const editedUser = {
    //   organizations: {
    //     [`${firestore.doc(`organizations/${organization.id}`)}`]: {
    //       id: firestore.doc(`organizations/${organization.id}`),
    //       name: values.name,
    //       status: 'enabled', // FORCED
    //       teams: values.teams,
    //       roles:  {
    //         owner: values.roles.includes('owner'),
    //         manager: values.roles.includes('manager'),
    //         agent: values.roles.includes('agent'),
    //         developer: values.roles.includes('developer')
    //       }
    //     }
    //   }
    // }

    // firestore.collection('users').doc(values.id)
  }

  async function onSubmitInviteNewUsers(
    values: IUserInvitation & { roles: TUserRole[] }
  ) {
    // loader.start()
    const invitationEmails = values.userEmails.split(',')
    const batch = firestore.batch()

    invitationEmails.forEach((email: IUserInvitation['email']) => {
      const userInvitation = {
        organization: firestore.doc(`organizations/${organization?.id}`),
        creator: firestore.doc(`users/${auth.uid}`),
        createdAt: firestore.FieldValue.serverTimestamp(),
        email: email,
        status: 'pending',
        teams: values.teams,
        roles: {
          owner: values.roles.includes('owner'),
          manager: values.roles.includes('manager'),
          agent: values.roles.includes('agent'),
          developer: values.roles.includes('developer')
        }
      }
      batch.set(firestore.collection('usersInvitations').doc(), userInvitation)
    })

    await batch.commit()

    alert(JSON.stringify(values, null, 2))
    // loader.complete()
    handleCloseDialog()
    return
  }

  async function onSubmitCreateTeam(values: ITeam) {
    if (organization) {
      const teamsSubCollection = firestore
        .collection('organizations')
        .doc(organization.id)
        .collection('teams')

      if (values.id) {
        teamsSubCollection
          .doc(values.id)
          .update(values)
          .then(() => {
            setCurrentSelectedTeam(undefined)

            handleCloseDialog()
          })
          .catch(alert)
          .finally(() => {
            // loader.complete()
          })
      } else {
        setCurrentSelectedTeam(values)

        teamsSubCollection
          .add(values)
          .then(() => {
            setCurrentSelectedTeam(undefined)

            handleCloseDialog()
          })
          .catch(alert)
          .finally(() => {
            // loader.complete()
          })
      }
      return
    }
  }

  /**
   * FORM SCHEMAS AND VALIDATIONS
   */

  // USER INVITE

  const userInviteSchema = Yup.object().shape({
    userEmails: Yup.string()
      .email('Please enter a valid email')
      .required('The user email is required'),
    roles: Yup.array().required('One or more roles must be provided')
  })

  const userInviteValidate = makeValidate(userInviteSchema)

  const userInviteInitialValues = useMemo(() => {
    return {
      teams: [currentSelectedTeam?.id]
    }
  }, [currentSelectedTeam])

  const userInviteFormFields = useMemo(() => {
    if (!isReady) {
      return []
    }
    return [
      <CustomTitle
        title={i18n({
          id: `${i18nNs}.form.inviteUsers.fields.userEmails.label`
        })}
        subtitle={i18n({
          id: `${i18nNs}.form.inviteUsers.fields.userEmails.helper-text`
        })}
      />,
      <Select
        label={i18n({ id: `${i18nNs}.form.inviteUsers.fields.teams.label` })}
        name='teams'
        required={true}
        data={teams.map((team: ITeam) => {
          return {
            label: team.name,
            value: team.id
          }
        })}
        multiple
        helperText={i18n({
          id: `${i18nNs}.form.inviteUsers.fields.teams.helper-text`
        })}
      />,
      <TextField
        name='userEmails'
        placeholder={i18n({
          id: `${i18nNs}.form.inviteUsers.fields.userEmails.placeholder`
        })}
        required={true}
        fullWidth
        variant='outlined'
      />,
      <CustomTitle
        title={i18n({ id: `${i18nNs}.form.inviteUsers.fields.roles.label` })}
        subtitle={i18n({
          id: `${i18nNs}.form.inviteUsers.fields.roles.helper-text`
        })}
      />,

      <Checkboxes
        name='roles'
        data={checkboxesRolesData}
        formControlLabelProps={{
          labelPlacement: 'end'
        }}
        formGroupProps={{
          row: true,
          classes: {
            row: classes.checkboxes
          }
        }}
      />
    ]
  }, [checkboxesRolesData, classes.checkboxes, i18n, isReady, teams])

  // CREATE TEAM

  const createTeamSchema = Yup.object().shape({
    enabled: Yup.boolean(),
    name: Yup.string().required('A name must be provided'),
    softRemoved: Yup.boolean()
  })

  const createTeamValidate = makeValidate(createTeamSchema)

  const createTeamInitialValues: Partial<ITeam> = useMemo(() => {
    return (
      currentSelectedTeam || {
        name: '',
        enabled: true,
        softRemoved: false
      }
    )
  }, [currentSelectedTeam])

  const createTeamFormFields = useMemo(() => {
    return [
      <CustomTitle
        title={i18n({ id: `${i18nNs}.form.createTeam.fields.title` })}
        subtitle={i18n({
          id: `${i18nNs}.form.createTeam.fields.subtitle`
        })}
      />,
      <TextField
        name='name'
        placeholder={i18n({
          id: `${i18nNs}.form.createTeam.fields.name.placeholder`
        })}
        required={true}
        fullWidth
        variant='outlined'
      />,
      <Checkboxes
        name='enabled'
        data={[
          {
            label: 'Enabled',
            value: true
          }
        ]}
        formControlLabelProps={{
          labelPlacement: 'end'
        }}
        formGroupProps={{
          row: true,
          classes: {
            row: classes.checkboxes
          }
        }}
      />
      // <Checkboxes
      //   name='softRemoved'
      //   data={[
      //     {
      //       label: 'Deleted',
      //       value: true
      //     }
      //   ]}
      //   formControlLabelProps={{
      //     labelPlacement: 'end'
      //   }}
      //   formGroupProps={{
      //     row: true,
      //     classes: {
      //       row: classes.checkboxes
      //     }
      //   }}
      // />
    ]
  }, [classes.checkboxes, i18n])

  return (
    <Layout
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
      subDrawer={<SettingsSubdrawer />}
      topBarActions={
        <IconButton onClick={handleClickOpenCreateTeamDialog}>
          <GroupAddIcon />
        </IconButton>
      }
    >
      {/* User Invite */}
      <FormDialog
        open={dialogOpened === 'inviteUserDialog'}
        onClose={handleCloseDialog}
        onSubmit={onSubmitInviteNewUsers}
        formFields={userInviteFormFields}
        initialValues={userInviteInitialValues}
        validate={userInviteValidate}
        headline={i18n({
          id: `${i18nNs}.form.inviteUsers.headline.inviteUsers`
        })}
      />

      {/* Create Team */}
      <FormDialog
        open={dialogOpened === 'createTeam'}
        onClose={handleCloseDialog}
        onSubmit={onSubmitCreateTeam}
        formFields={createTeamFormFields}
        initialValues={createTeamInitialValues}
        validate={createTeamValidate}
        headline={i18n({
          id: `${i18nNs}.form.createTeam`
        })}
      />

      <Container className={classes.root}>
        <Grid container spacing={0}>
          {/* Users list */}
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                margin: 16
              }}
            >
              {/* <Button
                // disabled={!inputValue}
                // color='secondary'
                className={classes.inviteButton}
                variant='contained'
                onClick={handleInviteUser}
                startIcon={<LinkIcon />}
              >
                Invite via Link
              </Button> */}
            </div>

            <TableContainer>
              <Table aria-label='collapsible table' size='small'>
                <TableBody>
                  {isReady ? (
                    teamsAndUsers.map((team, index) => {
                      return (
                        <React.Fragment key={team.id}>
                          <TableRow className={classes.table}>
                            <TableCell padding='checkbox'>
                              <IconButton
                                aria-label='expand row'
                                size='small'
                                onClick={() => handleOpenTeam(String(index))}
                              >
                                {String(index) === selectedIndex ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                            <TableCell
                              component='th'
                              scope='row'
                              className={classes.teamName}
                            >
                              {team.name}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell className={classes.teamAvatars}>
                              <GroupedAvatars team={team} id={team.id} />
                            </TableCell>
                            <TableCell align='right'></TableCell>
                            <TableCell align='right'>
                              <TableActions
                                onInviteUsers={() =>
                                  handleClickOpenInviteUsersDialog(team.id)
                                }
                                onEditTeam={() =>
                                  handleClickOpenEditTeamDialog(team.id)
                                }
                              />
                            </TableCell>
                            {/* <TableCell align='right'>{row.actions}</TableCell> */}
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ padding: 0 }} colSpan={6}>
                              <Collapse
                                in={String(index) === selectedIndex}
                                timeout='auto'
                                unmountOnExit
                              >
                                <Box>
                                  <TeamTable
                                    key={team.id}
                                    teams={teams}
                                    users={team.users}
                                    usersInvitations={team.usersInvitations}
                                    onEditUser={onSubmitEditUser}
                                    intl={i18n}
                                  />
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      )
                    })
                  ) : (
                    <React.Fragment>
                      <Box>
                        {/* <Skeleton variant='text' /> */}
                        <Skeleton
                          variant='rect'
                          className={classes.skeletonRect}
                        />
                      </Box>
                      <Box>
                        <Skeleton
                          variant='rect'
                          className={classes.skeletonRect}
                        />
                      </Box>
                      <Box>
                        <Skeleton
                          variant='rect'
                          className={classes.skeletonRect}
                        />
                      </Box>
                    </React.Fragment>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default UsersPage
