import React, { memo } from 'react'

import IconButton from '@material-ui/core/IconButton'
import SearchRoundedIcon from '@material-ui/icons/SearchRounded'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 56,
    width: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 900,
    fontSize: 24,
    lineHeight: 29
  }
}))

function ConversationsListHeader() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>{'Conversations'}</div>
      <IconButton>
        <SearchRoundedIcon />
      </IconButton>
    </div>
  )
}

export default memo(ConversationsListHeader)
