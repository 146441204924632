import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'

import ListSelector from '../../../components/ListSelector'
import { useHistory } from 'react-router-dom'

const OrganizationSelectorBox = ({ profileOrganizations }) => {
  const history = useHistory()

  const { formatMessage: i18n } = useIntl()

  const i18nNs = 'pages.select-organization.organization-selector-box'

  const handleOrganizationSelect = useCallback(
    (organizationId) => {
      history.push(`/org/${organizationId}`)
    },
    [history]
  )

  return (
    <ListSelector
      listSubheader={i18n({ id: `${i18nNs}.select-organization` })}
      onClickItem={handleOrganizationSelect}
      items={Object.values(profileOrganizations).map((organization) => {
        return {
          id: organization.id,
          primaryText: organization.name,
          value: organization.id,
          enabled: organization.status !== 'disabled',
          avatarUrl: organization.avatarUrl
        }
      })}
    />
  )
}

export default OrganizationSelectorBox
