import colors from './colors'
import fonts from './fonts'

export default {
  colors,
  fonts,
  typography: {
    fontFamily: [
      '"Lato"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },
  palette: {
    type: 'light',
    common: {
      black: 'rgba(0, 0, 0, 0.87)',
      white: '#FFFFFF'
    },
    background: {
      paper: '#fff',
      default: '#fafafa'
    },
    primary: {
      light: 'rgba(154, 209, 212, 1)',
      main: '#673AB7',
      dark: 'rgba(38, 107, 149, 1)',
      contrastText: '#fff'
    },
    secondary: {
      light: 'rgba(255, 221, 75, 1)',
      main: 'rgba(255, 171, 0, 1)',
      dark: 'rgba(238, 150, 2, 1)',
      contrastText: '#fff'
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff'
    }
    // text: {
    //   primary: 'rgba(0, 0, 0, 0.87)',
    //   secondary: 'rgba(0, 0, 0, 0.54)',
    //   disabled: 'rgba(0, 0, 0, 0.38)',
    //   hint: 'rgba(0, 0, 0, 0.38)',
    // },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiIconButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#90A4AE',
        padding: 8
      }
    }
  }
}
