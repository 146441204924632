import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Skeleton from '@material-ui/lab/Skeleton'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'

const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%'
    // maxWidth: 360,
  }
}))

function ClickableListItem({ children, onClick, value, ...props }) {
  const handleClick = useCallback(() => {
    onClick(value)
  }, [onClick, value])

  return (
    <ListItem onClick={handleClick} {...props}>
      {children}
    </ListItem>
  )
}

function ClickableAction({ onClick, value, icon }) {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation()
      onClick(value)
    },
    [onClick, value]
  )
  return (
    <ListItemSecondaryAction onClick={handleClick}>
      <IconButton edge='end' aria-label='comments'>
        {icon}
      </IconButton>
    </ListItemSecondaryAction>
  )
}

function SkeletonListItem() {
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Skeleton variant='circle' width={40} height={40} animation='wave' />
        </ListItemAvatar>
        <ListItemText>
          <Skeleton
            animation='wave'
            height={10}
            width='80%'
            style={{ marginBottom: 6 }}
          />
          <Skeleton
            animation='wave'
            height={10}
            width='60%'
            style={{ marginBottom: 6 }}
          />
        </ListItemText>
      </ListItem>
    </>
  )
}

const ListSelector = ({
  items,
  listSubheader,
  onClickItem,
  onClickAction,
  actionIcon
}) => {
  const classes = useStyles()

  return (
    <List
      className={classes.list}
      subheader={<ListSubheader>{listSubheader}</ListSubheader>}
    >
      {!items ? (
        <SkeletonListItem />
      ) : (
        items.map((item) => {
          return (
            <ClickableListItem
              key={item.id}
              value={item.value || item.id}
              role={undefined}
              disabled={!item.enabled}
              button
              onClick={onClickItem}
            >
              <ListItemAvatar>
                <Avatar alt={item.primaryText} src={item.avatarUrl || ' '} />
              </ListItemAvatar>
              <ListItemText
                id={`list-label-${item.id}`}
                primary={item.primaryText}
              />
              {(item.onClickAction || onClickAction) && (
                <ClickableAction
                  value={item.value || item.id}
                  onClick={item.onClickAction || onClickAction}
                  icon={item.actionIcon || actionIcon}
                />
              )}
            </ClickableListItem>
          )
        })
      )}
    </List>
  )
}

export default ListSelector

ListSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.any,
      enabled: PropTypes.bool.isRequired,
      primaryText: PropTypes.string.isRequired,
      avatarUrl: PropTypes.string,
      onClickAction: PropTypes.func,
      actionIcon: PropTypes.element
    }).isRequired
  ),
  listSubheader: PropTypes.string,
  onClickItem: PropTypes.func,
  onClickAction: PropTypes.func,
  actionIcon: PropTypes.element
}
