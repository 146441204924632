import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

import ChatBubble from '../messageContainers/ChatBubble'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    maxWidth: '45%',
    borderRadius: theme.spacing(2),
    textAlign: 'left'
  },
  positionLeft: {
    alignSelf: 'flex-start',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(6)
  },
  positionRight: {
    alignSelf: 'flex-end',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(6)
  }
}))

function capitalize(string) {
  if (typeof string !== 'string') {
    throw new Error(
      'Material-UI: capitalize(string) expects a string argument.'
    )
  }

  return string.charAt(0).toUpperCase() + string.slice(1)
}

export default ({ key, className, message, seenByContactAt }) => {
  const classes = useStyles()
  // const { auth } = useSelector((state) => state.firebase)

  return (
    <ChatBubble message={message} seenByContactAt={seenByContactAt}>
      <div
        key={key}
        className={clsx(
          classes.root,
          // classes[`position${capitalize(auth.uid === message.sender.userId ? 'right' :  'left')}`],
          classes[
            `position${capitalize(
              message.sender.type === 'contact' ? 'left' : 'right'
            )}`
          ],
          className
        )}
      >
        {message.content?.text}
      </div>
    </ChatBubble>
  )
}
