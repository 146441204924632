import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'

import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5),
    height: 68
    // height: 56,
    // width: '100%',
    // paddingLeft: theme.spacing(2),
    // justifyContent: 'space-between'
  },
  content: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}))

export default function ListItemLoading() {
  const classes = useStyles()

  return (
    <ListItem className={classes.root}>
      <Skeleton
        className={classes.avatar}
        variant='circle'
        width={40}
        height={40}
        animation='wave'
      />
      <div className={classes.content}>
        <Skeleton animation='wave' height={19} width='100%' />
        <Skeleton animation='wave' height={17} width='80%' />
      </div>
    </ListItem>
  )
}
