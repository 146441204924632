import React, { memo, useMemo, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'

import { useSelector } from 'react-redux'
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
  isEmpty
} from 'react-redux-firebase'

import ListItemLoading from './ListItemLoading'
import ConversationsListHeader from './Header'
import ConversationsListTeamSelect from './TeamSelect'
import ConversationListItem from './ListItem'

import useOrganization from '../../hooks/useOrganization'

import { IState } from '../../types'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300
  },

  divider: {
    width: '100%'
  }
}))

const loadingElements = [...new Array(5).keys()]

type Props = {
  onSelectConversation(id: string): void
}

function ConversationsList({ onSelectConversation }: Props) {
  const classes = useStyles()
  const firestore = useFirestore()

  const organization = useOrganization()

  const [teamId, setTeamId] = useState<string>('')

  useFirestoreConnect(
    organization
      ? [
          {
            collection: 'conversations',
            // https://firebase.google.com/docs/firestore/query-data/queries#in_and_array-contains-any
            where: [
              [
                'organization',
                '==',
                firestore.doc(`organizations/${organization.id}`)
              ],
              [
                'teams',
                'array-contains-any',
                organization.teams.map((aId: string) =>
                  firestore.doc(`organizations/${organization.id}/teams/${aId}`)
                )
              ]
            ] as any,
            orderBy: ['lastMessageAt', 'desc']
          }
        ]
      : []
  )

  const conversations = useSelector(
    (state: IState) => state.firestore.ordered.conversations
  )

  const allConversations = useMemo(() => {
    if (isLoaded(conversations) && !isEmpty(conversations)) {
      return teamId && conversations
        ? conversations.filter((c) => c.teams.map((a) => a.id).includes(teamId))
        : conversations
    } else {
      return []
    }
  }, [conversations, teamId])

  return organization ? (
    <div className={classes.root}>
      <ConversationsListHeader />
      <ConversationsListTeamSelect teamId={teamId} onChangeTeamId={setTeamId} />
      <Divider className={classes.divider} />
      <List>
        {!isLoaded(conversations) && !!organization.teams.length
          ? loadingElements.map((item) => <ListItemLoading key={item} />)
          : allConversations &&
            allConversations.map((conversation) => (
              <ConversationListItem
                key={conversation.id}
                conversation={conversation}
                onSelectConversation={onSelectConversation}
              />
            ))}
      </List>
    </div>
  ) : null
}

export default memo(ConversationsList)
