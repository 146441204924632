import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'

// import TextField from '@material-ui/core/TextField'
import ListSubheader from '@material-ui/core/ListSubheader'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { DraggableItem } from './DraggableItem'

import builderItems, { INodeBuilder } from './builderItems'

import MaterialIcon from '../../../components/MaterialIcon'

const useStyles = makeStyles((theme) => ({
  root: {
    // position: 'fixed',
    // right: `${theme.spacing(2)}px`,
    // top: `${theme.spacing(2)}px`,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    // height: '100%',
    maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    padding: theme.spacing(1)
  },
  // flowNameInput: {
  //   margin: '0 auto',
  //   marginBottom: theme.spacing(4),
  //   marginTop: theme.spacing(2),
  //   width: '85%'
  // },
  listsContainer: {
    overflowY: 'auto'
  },
  listSubheader: {
    backgroundColor: theme.palette.background.paper
  },
  list: {
    width: '100%'
  }
}))

interface IAutomationFlowSideBar {
  organization?: any
  automationFlow?: any
  open: boolean
  onClose?(): void
}

export default ({ open, onClose }: IAutomationFlowSideBar) => {
  const classes = useStyles()

  // const [automationFlowName, setAutomationFlowName] = useState('')

  // const handleInputChange = (e: any) => {
  //   if (e.target.value.trim()) {
  //     setAutomationFlowName(e.target.value.trim())
  //   }
  // }

  return (
    <Drawer open={open} anchor={'right'} variant='persistent' onClose={onClose}>
      {/* <TextField
        id='standard-basic'
        label='Flow name'
        className={classes.flowNameInput}
        value={automationFlowName}
        onChange={handleInputChange}
      /> */}
      <div className={classes.listsContainer}>
        {((builderItems as unknown) as INodeBuilder[]).map(
          (l: INodeBuilder) => {
            return (
              <List
                key={l.title}
                aria-labelledby={l.title}
                subheader={
                  <ListSubheader
                    component='div'
                    id={l.title}
                    className={classes.listSubheader}
                  >
                    {l.title}
                  </ListSubheader>
                }
                className={classes.list}
              >
                {l.items.map((i) => {
                  return (
                    <DraggableItem
                      key={i?.properties?.key}
                      nodeParams={{
                        type: i.type,
                        ports: i.ports,
                        properties: i.properties
                      }}
                    >
                      <ListItem button>
                        <ListItemIcon>
                          <MaterialIcon icon={i.properties?.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={i.properties?.title}
                          secondary={i.properties?.subHeader}
                        />
                      </ListItem>
                    </DraggableItem>
                  )
                })}
              </List>
            )
          }
        )}
      </div>
    </Drawer>
  )
}
