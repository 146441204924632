import React from 'react'

import MainWrapper from '../../layouts/MainWrapper'
import MainMenu from '../../layouts/components/MainMenu'
import ConversationsList from '../../components/ConversationsList'
import Conversation from '../../components/Conversation'

import { useHistory, useParams } from 'react-router-dom'

const ConversationsPage = () => {
  const history = useHistory()

  const {
    organizationId,
    conversationId
  }: { organizationId: string; conversationId: string } = useParams()

  const handleSelectConversation = (conversationId: string) => {
    history.push(`/org/${organizationId}/conversations/${conversationId}`)
  }

  return (
    <MainWrapper>
      <MainMenu />
      <ConversationsList onSelectConversation={handleSelectConversation} />
      {conversationId ? <Conversation conversationId={conversationId} /> : null}
    </MainWrapper>
  )
}

export default ConversationsPage
