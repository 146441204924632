import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { Unauthenticated as LayoutUnauthenticated } from '../layouts'

import { useFirebase } from 'react-redux-firebase'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}))

const i18nNs = 'pages.logout'

const LogoutPage = () => {
  const classes = useStyles()
  const { profile } = useSelector((state) => state.firebase)

  const firebase = useFirebase()
  const { formatMessage: i18n } = useIntl()

  useEffect(() => {
    firebase.logout()
  }, [firebase])

  return (
    <LayoutUnauthenticated
      sectionTitle={i18n({ id: `${i18nNs}.appbar-title` })}
    >
      <Container maxWidth='xs' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Typography variant='h5' component='h1' align='center'>
              {i18n(
                { id: `${i18nNs}.title` },
                { displayName: profile.displayName.replace(/ .*/, '') }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LayoutUnauthenticated>
  )
}

export default LogoutPage
